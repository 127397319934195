import { Box, Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../EventForm/styles';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';

interface IAvailabilityProps {
  register: React.Ref<HTMLInputElement>;
  isImpactChecked: boolean;
  setIsImpactChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Availability = ({
  register,
  isImpactChecked,
  setIsImpactChecked,
}: IAvailabilityProps) => {
  const classes = useStyles({ isActivePayout: true });

  return (
    <Box className={classes.requirePrepayContainer} mb={2}>
      <Box>
        <Text i18nKey="availability" className={classes.requirePrepayTitle}>
          Availability
        </Text>
        <Text
          i18nKey="No new sessions can be scheduled during event"
          className={classes.requirePrepayDesc}
        >
          No new sessions can be scheduled during event
        </Text>
      </Box>
      <Checkbox
        inputRef={register}
        name="isImpact"
        size="medium"
        defaultChecked={isImpactChecked}
        onChange={() => setIsImpactChecked((s) => !s)}
        checkedIcon={<CheckedIcon />}
        icon={<UncheckedIcon />}
        style={{ backgroundColor: 'transparent' }}
      />
    </Box>
  );
};
