import React from 'react';
import { ReactComponent as DuplicateIcon } from 'assets/templateSettings/duplicateIcon.svg';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { getDayName } from 'utils';
import clsx from 'clsx';
import Input from 'components/ui/Inputs';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as AssignIcon } from 'assets/AssignTemplate.svg';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import { useQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { ReactComponent as FrequencyIcon } from 'assets/Schedule/new/freq.svg';
import Text from 'Text';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/newcheckbox-unchecked.svg';
import FrequencyComponent from 'pages/SchedulePage/AddEventPage/EventForm/FrequencyComponent';
import Switch from 'components/ui/SwitchNew';

const useStyles = makeStyles((theme) => ({
  rootAssign: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '2rem',
  },
  nameText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '5px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    paddingInline: '2rem',
    boxSizing: 'border-box',
    marginTop: '-0.5rem',
  },
  containerAssign: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#182230',
    marginTop: '12px',
  },
  body: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#475467',
  },
  buttonBox: {
    height: '4.25rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: '2rem',
  },
  button: {
    height: theme.typography.pxToRem(44),
    paddingInline: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(8),
    textTransform: 'none',
  },
  cancelButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#344054',
    backgroundColor: '#FFFFFF',
    marginRight: '1rem',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#F7F8FA',
    },
  },
  saveButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    '&:hover': {
      backgroundColor: '#0154CB',
    },
  },
  frequencyContainer: {
    border: '1px solid #ced4da',
    borderRadius: '12px',
    padding: '12px 15px',
  },
  freqTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  freqDesc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    color: '#475467',
  },
}));

function AssignClientModal({
  values,
  setValues,
  currentRow,
  isOne = true,
}: {
  values: any;
  setValues: any;
  currentRow?: { title: string };
  isOne?: boolean;
}) {
  const classes = useStyles();
  const { data, error, loading: isLoading } = useQuery(PATIENTS_RELATED);
  const { register, control, errors, watch } = useFormContext();
  const users = convertObjectsFormat(data?.patientsRelated, 'Clients');
  return (
    <Box className={classes.rootAssign}>
      <AssignIcon />
      <Typography className={classes.title}>
        Assign {currentRow?.title}
      </Typography>
      {isOne && (
        <Box mt={2} mb={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={'row-reverse'}
          >
            <Box display="flex" alignItems="center">
              <Controller
                name={'type'}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <Checkbox
                      size="medium"
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      checkedIcon={<CheckedIcon width={16} height={16} />}
                      icon={<UncheckedIcon width={16} height={16} />}
                      style={{
                        backgroundColor: 'transparent',
                        marginLeft: '-5px',
                        marginTop: '-6px',
                      }}
                    />
                  );
                }}
              />
              <Typography className={classes.nameText}>
                Share with all clients
              </Typography>
            </Box>
            <Typography className={classes.nameText}>Name</Typography>
          </Box>
          <Symptoms
            type=""
            disableCustomFilter={true}
            options={users}
            values={values}
            setValues={setValues}
            hasErrors={false}
            disabled={!!watch()?.type}
          />
        </Box>
      )}
      <Box className={classes.frequencyContainer} mb={2} mt={!isOne && 2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" sx={{ gridGap: '1rem' }}>
            <FrequencyIcon />
            <Text i18nKey="frequency" className={classes.freqTitle}>
              Frequency
            </Text>
          </Box>
          <Controller
            name={'frequencyExpand'}
            control={control}
            render={({ onChange, value, ref }) => {
              return (
                <Switch
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputRef={ref}
                />
              );
            }}
          />
        </Box>
        <Box
          pr="9px"
          pl="2.5rem"
          pb={watch()?.frequencyExpand && '9px'}
          pt={watch()?.frequencyExpand && 3}
          style={{
            height: watch()?.frequencyExpand ? 'unset' : 0,
            visibility: watch()?.frequencyExpand ? 'visible' : 'hidden',
            overflow: watch()?.frequencyExpand ? 'unset' : 'hidden',
            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          }}
        >
          <FrequencyComponent
            register={register}
            control={control}
            errors={errors}
            watch={watch}
            defaultValue={getDayName(new Date().toISOString())}
            setOpenConflict={null}
            data={[]}
            dateRange={{ startDate: new Date() }}
            name={'endOn'}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AssignClientModal;
