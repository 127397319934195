import React from 'react';
import AutoCompleteAddSymptoms, {
  Option,
} from 'components/ui/Inputs/AutoCompleteAddSymptoms';
import { Box } from '@material-ui/core';
import OptionRender from './OptionRender';
import { useTranslation } from 'react-i18next';
interface IMembersAddProps {
  values: Option[];
  options: Option[];
  setValues: (value: any) => void;
  hasErrors?: boolean;
  img?: boolean;
  patient?: boolean;
  disableCustomFilter?: boolean;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  multiple?: boolean;
}

export default function Symptoms({
  values,
  options,
  setValues,
  hasErrors,
  patient,
  disableCustomFilter = false,
  type,
  disabled = false,
  placeholder = '',
  multiple,
}: IMembersAddProps) {
  const { t } = useTranslation();
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <AutoCompleteAddSymptoms
        patient={patient}
        multiple={multiple}
        groupBy={(option: any) => option.type}
        renderPreviewWrapper={(content: any) => (
          <Box pt={2} display="flex" flexDirection="column">
            {content}
          </Box>
        )}
        placeholder={placeholder}
        type={type}
        options={[...options]}
        renderOption={(option, { selected }) => (
          <OptionRender
            patient={patient}
            selected={selected}
            {...option}
            img={true}
          />
        )}
        values={values}
        setValues={setValues}
        hasErrors={hasErrors}
        disableCustomFilter={disableCustomFilter}
        disabled={disabled}
      />
    </Box>
  );
}
