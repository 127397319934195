import { useMutation, gql, ApolloError } from '@apollo/client';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

export const ADD_STAFF_MEMBER = gql`
  mutation addStaffToOrganization($staffInput: StaffMemberInput) {
    addStaffToOrganization(staffInput: $staffInput) {
      email
    }
  }
`;

export default function useAddTeamMember() {
  const [addStaffToOrganization, result] = useMutation(ADD_STAFF_MEMBER, {
    refetchQueries: [
      {
        query: TEAM_MEMBERS,
        variables: { role: '', status: '' },
      },
    ],
  });

  return { addStaffToOrganization, ...result };
}
