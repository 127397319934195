import React from 'react';
import ReactDOM from 'react-dom';
import { Box } from '@material-ui/core';
import TableForm from 'pages/PatientActivitiesPage/CreateEditForm/TableForm';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig { }

interface TimelineConstructorProps {
  data: any;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
}

export default class TableResponse {
  private api: any;
  private readOnly: boolean;
  private data: any;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;
    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
    this.updateResponse = this.updateResponse.bind(this);
  }

  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        <Box width={'100%'} style={{ marginBlock: '1rem' }}>
          <TableForm data={this.data?.content} />
        </Box>,
        this.nodes.holder
      );
    }
  }

  private updateResponse(response) {
    this.data = {
      ...response,
    };
    this.updateDom();
  }
  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    ReactDOM.render(
      <Box width={'100%'} style={{ marginBlock: '1rem' }}>
        <TableForm data={this.data?.content} />
      </Box>,
      rootNode
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
}
