import { gql, useMutation } from '@apollo/client';

const EDIT_TASK_LIST = gql`
  mutation editTasksList($taskListId: ID!, $taskListInput: editTaskInput) {
    editTasksList(taskListId: $taskListId, taskListInput: $taskListInput) {
      _id
      tasks {
        _id
        title
        description
        status
        dueDate
        priority
        attachments {
          createdAt
          fileName
          fileType
          url
          user {
            firstname
            middlename
            lastname
            picture
            role
            _id
          }
        }
      }
      name
    }
  }
`;

export default function useUpdateTaskList() {
  const [editTasksList, result] = useMutation(EDIT_TASK_LIST, {
    refetchQueries: [{ query: TASKS_LIST_QUERY }],
    awaitRefetchQueries: false,
  });
  return { editTasksList, ...result };
}
export const TASKS_LIST_QUERY = gql`
  query getTasksList {
    tasksList {
      _id
      name
      tasks {
        _id
        title
        description
        status
        dueDate
        priority
        assignedTo {
          picture
          firstname
          middlename
          lastname
          _id
        }
        patients {
          picture
          firstname
          middlename
          lastname
          _id
        }
        attachments {
          fileName
          createdAt
          url
          fileType
          user {
            firstname
            middlename
            lastname
            picture
            role
            _id
          }
        }
      }
    }
  }
`;
