import React from 'react';

const Email = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V17C1.5 17.8284 2.17157 18.5 3 18.5H23C23.8284 18.5 24.5 17.8284 24.5 17V3C24.5 2.17157 23.8284 1.5 23 1.5ZM3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H23C24.6569 20 26 18.6569 26 17V3C26 1.34315 24.6569 0 23 0H3Z"
      fill="#98A2B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.644 11.6647L24.4142 0.894531L25.4748 1.95519L14.7047 12.7254C13.6307 13.7993 11.8895 13.7993 10.8156 12.7254L0.0454102 1.95519L1.10607 0.894531L11.8762 11.6647C12.3644 12.1529 13.1559 12.1529 13.644 11.6647Z"
      fill="#98A2B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1991 19.2585L15.7657 10.8252L16.8264 9.7645L25.2598 18.1979L24.1991 19.2585ZM9.90402 10.6759L1.3214 19.2585L0.260742 18.1979L8.84336 9.61523L9.90402 10.6759Z"
      fill="#98A2B3"
    />
  </svg>
);

export default Email;
