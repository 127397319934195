import { gql, useMutation } from '@apollo/client';
import { addTask, addTaskVariables } from './__generated__/addTask';

export const TASKS_LIST_QUERY = gql`
  query getTasksList($doctors: [ID]) {
    tasksList(doctors: $doctors) {
      _id
      name
      tasks {
        _id
        title
        description
        status
        dueDate
        priority
        assignedTo {
          picture
          firstname
          middlename
          lastname
          _id
        }
        patients {
          picture
          firstname
          middlename
          lastname
          _id
        }
        attachments {
          fileName
          createdAt
          url
          fileType
          user {
            firstname
            middlename
            lastname
            picture
            role
            _id
          }
        }
      }
    }
  }
`;
const ADD_TASK = gql`
  mutation addTask($taskInput: taskInput!, $files: [Upload], $listId: ID) {
    addTask(taskInput: $taskInput, files: $files, listId: $listId) {
      _id
      title
      description
      status
      dueDate
      priority
      attachments {
        createdAt
        url
        fileType
        fileName
        user {
          firstname
          middlename
          lastname
          picture
          role
          _id
        }
      }
    }
  }
`;

export default function useAddTask() {
  const [createTask, result] = useMutation<addTask, addTaskVariables>(
    ADD_TASK,
    {
      refetchQueries: [{ query: TASKS_LIST_QUERY }],
      awaitRefetchQueries: false,
    }
  );
  return { createTask, ...result };
}
