import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Divider,
  Theme,
  makeStyles,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import InputBlock from './InputBlock';
import { IRates } from './type';
import { IsDefaultComponent } from './IsDefaultComponent';
import ColorInput from './ColorInput';
import { colors } from '../ManageTags/Constant';
import { convertTagFormat } from 'pages/SchedulePage/AddEventPage/utils';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import CptComponent from './cptComponent';
import clsx from 'clsx';
import Text from 'Text';
import { ReactComponent as OnlineIcon } from 'assets/Schedule/new/online.svg';
import { ReactComponent as InPersonIcon } from 'assets/Schedule/new/in-person.svg';
import { ReactComponent as ConflictIcon } from 'assets/conflicts/conflicts-icon.svg';
import { ReactComponent as EggmedIcon } from 'assets/EggmedLocation.svg';
import { ReactComponent as EggmedIconZoom } from 'assets/EggmedZoom.svg';
import { ReactComponent as ZoomIcon } from 'assets/ZoomLocation.svg';
import { ReactComponent as Zoom } from 'assets/Zoom.svg';
import { ReactComponent as Copy } from 'assets/copyLogo.svg';
import useClipBoard from 'hooks/useClipBoard';
import { ReactComponent as Alert } from 'assets/alert.svg';
import { ReactComponent as Info } from 'assets/info.svg';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

interface ICpt {
  category: string;
  status: string;
  code: string;
  description: string;
}
interface IRateModal {
  currentRow: IRates;
  editMode: boolean;
  tags: any;
  cpts: [ICpt];
}

const useStyles = makeStyles<Theme>(() => ({
  eventType: {
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    // width: '50%',
    // flex: 1,
    height: '100%',
    overflow: 'hidden',
    maxHeight: '44px',
  },
  oneEvent: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#344054',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px 0',
    maxHeight: '44px',
    cursor: 'pointer',
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  onlineEvent: {
    borderRight: '1px solid #D0D5DD',
  },
  activeEvent: {
    backgroundColor: '#147AF3',
    color: '#fff',
    '& svg path': {
      stroke: '#fff',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  equal: {
    flex: 1,
  },
  textTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
  },
  alert: {
    padding: '0 0 0 0px',
    gap: '10px',
    alignItems: 'center',
  },
  alertText: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'italic',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19.36px',
    color: '#B42318',
    textAlign: 'left',
  },
  copyBtn: {
    display: 'flex',
    padding: '2px 8px 2px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EAECF0',
    border: '1px solid #EAECF0',
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '4px',
    textTransform: 'none',
    gap: '4px',
    width: '6.25rem',
    height: '2rem',
    '&:hover': {
      backgroundColor: '#EAECF0',
    },
  },
  copyText: {
    color: '#475467',
    marginLeft: '2px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
    color: '#4A5578',
    backgroundColor: '#F9F9FB',
    border: '1px solid #7D89B0',
    borderRadius: '4px',
    padding: '20px',
    gap: '10px',
  },
  infoboxTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingBottom: '0.2rem',
  },
  infoTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.36px',
  },
}));

export const convertObjectsFormat = (array) => {
  if (array) {
    return array?.map((element) => ({
      ...element,
      name: `${element?.code} - ${element?.description}`,
      id: element?.code,
    }));
  }
  return [];
};

const RatesModal = ({ currentRow, editMode, tags, cpts }: IRateModal) => {
  const { register, control, errors, watch } = useFormContext();
  const commonProps = { register, control, errors };
  const classes = useStyles();
  const { handleCopyToClipboard } = useClipBoard();
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();
  return (
    <Box
      px="2rem"
      py={1}
      sx={{
        width: {
          xs: '50vw',
          lg: '43vw',
          xl: '35vw',
        },
      }}
      height="100%"
    >
      <InputBlock
        label={'Service name'}
        {...commonProps}
        name="session"
        currentRow={currentRow}
        editMode={editMode}
      />
      <CptComponent
        control={control}
        name="cptCode"
        options={convertObjectsFormat(cpts)}
        label="CPT code (optional)"
      />
      <IsDefaultComponent
        title={'Set as default service'}
        description={
          'This service will be preselected when your clients book with you.'
        }
        name="isDefault"
        {...commonProps}
        type={'switch'}
        isDefault={currentRow?.isDefault}
      />
      <ColorInput control={control} values={colors} />
      <Divider style={{ width: '100%', height: '2px', marginBlock: '2rem' }} />
      <Box className={classes.container}>
        <InputBlock
          label={'Duration'}
          type="number"
          {...commonProps}
          name="duration"
          currentRow={currentRow}
          editMode={editMode}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
        />
        <InputBlock
          label={'Currency'}
          {...commonProps}
          name="currency"
          type="select"
          options={['USD', 'EUR']}
          currentRow={currentRow}
          editMode={editMode}
          className={classes.equal}
        />
        <InputBlock
          label={'Pricing'}
          {...commonProps}
          name="rate"
          currentRow={currentRow}
          editMode={editMode}
          type="number"
          className={classes.equal}
        />
      </Box>

      <IsDefaultComponent
        title={'Prepayment Required'}
        description={'Client must pay service fees in order to join'}
        name="isPrepayment"
        {...commonProps}
        type="switch"
      />
      <Divider style={{ width: '100%', height: '2px', marginBlock: '2rem' }} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box width={watch().location === 'Online' ? '100%' : '50%'}>
          <Typography
            className={classes.textTitle}
            style={{ marginBottom: '5px' }}
          >
            Location
          </Typography>
          <Controller
            name="location"
            control={control}
            render={({ onChange, value }) => (
              <Box className={classes.eventType}>
                <Box
                  className={clsx(
                    classes.oneEvent,
                    value === 'In-person' && classes.activeEvent
                  )}
                  onClick={() => onChange('In-person')}
                >
                  <InPersonIcon />
                  <Text i18nKey="inPerson">In-person</Text>
                </Box>
                <Box
                  className={clsx(
                    classes.oneEvent,
                    classes.onlineEvent,
                    value === 'Online' && classes.activeEvent
                  )}
                  onClick={() => {
                    onChange('Online');
                  }}
                >
                  <OnlineIcon />
                  <Text i18nKey="online">Online</Text>
                </Box>
              </Box>
            )}
          />
        </Box>
        {watch().location === 'Online' && (
          <ConflictIcon
            style={{
              marginTop: '25px',
              marginInline: '10px',
            }}
          />
        )}
        {watch().location === 'Online' && (
          <Box width="100%">
            <Typography
              className={classes.textTitle}
              style={{ marginBottom: '5px' }}
            >
              Online session platform
            </Typography>
            <Controller
              name="locationPlace"
              control={control}
              render={({ onChange, value }) => (
                <Box className={classes.eventType}>
                  <Box
                    className={clsx(
                      classes.oneEvent,
                      classes.onlineEvent,
                      value === 'Eggmed' && classes.activeEvent
                    )}
                    onClick={() => onChange('Eggmed')}
                  >
                    {value !== 'Eggmed' ? <EggmedIcon /> : <EggmedIconZoom />}
                    <Typography>Eggmed</Typography>
                  </Box>
                  <Box
                    className={clsx(
                      classes.oneEvent,
                      value === 'Zoom' && classes.activeEvent
                    )}
                    onClick={() => onChange('Zoom')}
                  >
                    {value === 'Zoom' ? <Zoom /> : <ZoomIcon />}
                    <Typography>Zoom</Typography>
                  </Box>
                </Box>
              )}
            />
          </Box>
        )}
      </Box>
      {watch().locationPlace === 'Zoom' && (
        <Box mt={2}>
          <Typography
            className={classes.textTitle}
            style={{ marginBottom: '5px' }}
          >
            Session link
          </Typography>
          <Controller
            control={control}
            name="externalUrl"
            render={({ onChange, value }) => (
              <Input
                type="text"
                defaultValue={value}
                onChange={onChange}
                {...commonProps}
                inputRef={commonProps.register}
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        className={classes.copyBtn}
                        onClick={(e) => {
                          e.preventDefault();
                          handleCopyToClipboard(value);
                          triggerSnack(t('Link copied successfully'));
                        }}
                      >
                        <Copy
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <Typography className={classes.copyText}>
                          {t('copy URL')}
                        </Typography>
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Box display="flex" className={classes.alert} mt={2}>
            <Alert />
            <Typography className={classes.alertText}>
              {t('Anyone with this URL can access the session')}
            </Typography>
          </Box>
          <Box className={classes.infoBox}>
            <Box
              style={{
                gap: '10px',
              }}
            >
              <Box className={classes.infoboxTitle}>
                <Info />
                <Typography className={classes.infoTitle}>
                  {t('Important notice')}
                </Typography>
              </Box>
              <Typography>
                By enabling this third-party video service, you agree to comply
                with HIPAA and protect client information, including Telehealth
                links.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Divider style={{ width: '100%', height: '2px', marginBlock: '2rem' }} />
      <Typography className={classes.textTitle}>Associated tags</Typography>
      <Box width="100%" mt={1}>
        <Controller
          name="tags"
          render={({ onChange, value }) => {
            return (
              <Symptoms
                type=""
                disableCustomFilter={true}
                options={convertTagFormat(tags?.doctorTags)}
                values={value}
                setValues={onChange}
              />
            );
          }}
          {...commonProps}
        />
      </Box>
    </Box>
  );
};

export default RatesModal;
