import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  popupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '36px',
    gap: '36px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #DEE2E6',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
  },
  popUpBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: '12px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  allLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    height: '67px',
    flex: 'none',
    order: 2,
    flexGrow: 0,
  },
  agreementText: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#182230',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  subText: {
    width: '385px',
    height: '34px',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#667085',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  links: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: '8px',
    flex: 'none',
    order: 2,
    flexGrow: 0,
  },
  link: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    textDecoration: 'underline',
    color: '#0265DC',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '4px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  agreeButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 20px',
    gap: '10px',
    width: '100%',
    height: theme.typography.pxToRem(44),
    borderRadius: '8px',
    flex: 'none',
    cursor: 'pointer',
  },
  agreeButtonText: {
    width: '41px',
    height: '17px',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: '#F5F5F5',
    textTransform: 'capitalize',
  },
  agreeText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
    width: '409px',
    height: '34px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
}));

export default useStyles;
