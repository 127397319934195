import React from 'react';
import { useStyles } from '../styles';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as DefaultIcon } from 'assets/Badge.svg';
import { ReactComponent as DropDown } from 'assets/verticalDrop.svg';
import { IDoctorTemplates } from './FormModal';
import { ReactComponent as AssignIcon } from 'assets/AssignSmall.svg';

import Skeleton from 'components/ui/Skeleton';

export enum CardType {
  default = 'default',
  personal = 'personal',
  notes = 'notes',
}

interface IFormCardProps {
  form: IDoctorTemplates;
  handleAssignForm: (form: IDoctorTemplates) => void;
  handleOpenAssignModal?: (target: HTMLElement, form: IDoctorTemplates) => void;
  setMenuType?: (type: CardType) => void;
  cardType: CardType;
}

function FormCard({
  form,
  handleAssignForm,
  handleOpenAssignModal,
  setMenuType,
  cardType,
}: IFormCardProps) {
  const classes = useStyles({ selected: false });

  return (
    <Box className={classes.formCard} key={form._id} mb={1}>
      <Box display="flex" alignItems="center" flex="1" maxWidth="75%">
        <Typography className={classes.personalFormName}>
          {form.title}
        </Typography>
        {form?.isDefault && (
          <DefaultIcon style={{ marginLeft: '10px', marginTop: '1px' }} />
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        style={{ gap: '20px', marginRight: '5px' }}
      >
        <Box
          className={classes.useButton}
          onClick={() => handleAssignForm(form)}
          display="flex"
          alignItems="center"
          style={{ gap: '5px' }}
        >
          <AssignIcon />
          <Typography>Assign</Typography>
        </Box>
        <Box
          style={{ cursor: 'pointer' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={(e) => {
            setMenuType(cardType);
            handleOpenAssignModal(e.currentTarget, form);
          }}
        >
          <DropDown />
        </Box>
      </Box>
    </Box>
  );
}

export default FormCard;

/* --------------------- Loading --------------------- */

export const FormCardLoading = () => {
  const classes = useStyles({ selected: false });
  return (
    <Box className={classes.formCard}>
      <Box display="flex" alignItems="center" flex="1" maxWidth="75%">
        <Skeleton width="100px" height="20px" loading={true}>
          <></>
        </Skeleton>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        style={{ gap: '20px', marginRight: '5px' }}
      >
        <Box>
          <Skeleton width="40px" height="20px" loading={true}>
            <></>
          </Skeleton>
        </Box>
        <Box
          style={{ cursor: 'pointer' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Skeleton width="5px" height="40px" loading={true}>
            <></>
          </Skeleton>
        </Box>
      </Box>
    </Box>
  );
};
