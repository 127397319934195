import React, { useState, useCallback, useEffect } from 'react';
import { Rnd } from 'react-rnd';

// import MeetingRoomContext from '../MeetingRoomPage/state/RoomContext';
// import MeetingRoomPage from '../MeetingRoomPage';
import useWindowSize from 'hooks/useWindowSize';
import JitsiMeetingRoomPage from 'pages/MeetingRoomPage/JitsiMeetingRoomPage';

interface ISizeState {
  width: number | string;
  height: number | string;
  x: number;
  y: number;
}

function DraggableVideoMeet({
  roomId,
  isWide,
  width,
  height,
  endCallAction,
  encryptionKey,
  isMobile = false,
  mediaDevice,
}: {
  roomId: string;
  height: number;
  width: number;
  isWide: boolean;
  encryptionKey: string;
  endCallAction: () => void;
  isMobile?: boolean;
  mediaDevice?: any;
}) {
  const { width: wWidth, height: wHeight } = useWindowSize();

  useEffect(() => {
    setState({
      width,
      height,
      x: 0,
      y: window.innerHeight - (height + 0),
    });
  }, [wWidth, wHeight]);

  const [state, setState] = useState<ISizeState>({
    width,
    height,
    x: 0,
    y: wHeight,
  });

  const handleFullScreen = useCallback(() => {
    if (state.height === '100vh') {
      setState((oldSize) => ({
        ...oldSize,
        x: 0,
        y: wHeight - (height + 0),
        height: 400,
        width: 730,
      }));
      return;
    }
    setState((oldSize) => ({
      ...oldSize,
      x: 0,
      y: 0,
      height: '100vh',
      width: '100vw',
    }));
  }, [state, width, height]);
  return (
    <Rnd
      size={{ width: state.width, height: state.height }}
      onResize={(e, direction, ref, delta, position) => {
        setState({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        });
      }}
      position={{ x: state.x, y: state.y }}
      onDragStop={(e, d) => {
        setState({ x: d.x, y: d.y, height: state.height, width: state.width });
      }}
      style={{
        zIndex: 10000,
      }}
      bounds="window"
    >
      <div style={{ width: 10, height: 10, zIndex: 1000 }}>
        <div style={{ width: state.width, height: state.height }}>
          <JitsiMeetingRoomPage
            endCallAction={endCallAction}
            height={state.height}
            handleFullScreen={handleFullScreen}
            width={state.width}
            isWide={isWide}
            roomId={roomId}
          />
        </div>
      </div>
    </Rnd>
  );
}

export default DraggableVideoMeet;
