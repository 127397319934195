export const calculateVisibleTagsDiagnosis = (
  tagWidths,
  containerWidth,
  chipGap = 3
) => {
  if (!tagWidths || tagWidths.length === 0 || !containerWidth) return 0;

  let accumulatedWidth = 0;
  let visibleCount = 0;

  for (let i = 0; i < tagWidths.length; i++) {
    const tagWidth = tagWidths[i] + chipGap;

    if (visibleCount >= 2) {
      break;
    }

    accumulatedWidth += tagWidth;
    visibleCount++;
  }

  return visibleCount;
};
export const calculateVisibleTags = (
  tagWidths,
  containerWidth,
  tooltipChipWidth = 40,
  chipGap = 3
) => {
  if (!tagWidths || tagWidths.length === 0 || !containerWidth) return 0;

  const availableWidth = containerWidth - tooltipChipWidth;
  let accumulatedWidth = 0;
  let count = 0;

  for (let tagWidth of tagWidths) {
    const widthWithGap = tagWidth + chipGap;

    if (accumulatedWidth + widthWithGap > availableWidth) break;

    accumulatedWidth += widthWithGap;
    count += 1;
  }

  if (
    count < tagWidths.length &&
    accumulatedWidth + tooltipChipWidth > containerWidth
  ) {
    count = Math.max(count - 1, 0);
  }

  return count;
};
