import { gql, useMutation } from '@apollo/client';

const RESET_STAFF_PASSWORD = gql`
  mutation resetStaffPassword($userId: ID, $password: String) {
    resetStaffPassword(userId: $userId, password: $password) {
      email
    }
  }
`;

const useResetStaffPassword = () => {
  const [resetStaffPassword, { loading }] = useMutation(RESET_STAFF_PASSWORD);
  async function handleResetStaffPassword(userId: string, password: string) {
    try {
      return await resetStaffPassword({
        variables: { userId, password },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleResetStaffPassword, loading };
};

export { RESET_STAFF_PASSWORD };
export default useResetStaffPassword;
