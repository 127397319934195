import React, { useCallback, useContext, useState } from 'react';

import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useJoinVideoRoomWithPatient from '@eggmed/graphql-client/operations/videoRoomOperations/useJoinVideoRoomWithPatient';
import DraggableVideoMeet from 'pages/AppointmentPage/DraggableVideoMeet';
import useDoctorWaitingRoomActions from 'pages/DoctorMeetingWaitingPage/state/useDoctorWaitingRoomActions';
import { useIsMobileSubscription } from '@eggmed/graphql-client/operations/videoRoomOperations/usePatientJoinedWaitingRoom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PATIENT } from 'pages/AppointmentPage/AppointmentPage';
import MeetingTesting from 'components/MeetingTesting';
import { Rnd } from 'react-rnd';
import Box from '@material-ui/core/Box';
import useWindowSize from 'hooks/useWindowSize';
import { useLocation } from 'react-router-dom';

export const Context = React.createContext<any | undefined>(undefined);
interface ISizeState {
  width: number | string;
  height: number | string;
  x: number;
  y: number;
}
export default function MeetingContext({
  children,
}: {
  children: JSX.Element;
}) {
  const [callRunning, setCallRunning] = React.useState(false);
  const width = 400;
  const height = 280;
  const { width: wWidth, height: wHeight } = useWindowSize();
  const [state, setState] = useState<ISizeState>({
    width,
    height,
    x: 50,
    y: wHeight - (height + 150),
  });

  const url = new URL(window.location.href);
  const patientId = url.pathname.split('/').pop();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('event');
  const [fetchAppointment, { data: appointment, loading, refetch }] =
    useLazyQuery(GET_PATIENT);
  React.useEffect(() => {
    if (redirectStatus) {
      fetchAppointment({
        variables: {
          patientId,
          appointmentId: redirectStatus,
        },
      });
    }
  }, [redirectStatus]);
  const storedTimers = JSON.parse(localStorage.getItem('timers')) || [];
  const storedTimer = storedTimers.find(
    (timer) => timer.appointmentId === appointment?.appointment?._id
  );
  React.useEffect(() => {
    setState({
      width,
      height,
      x: 50,
      y: window.innerHeight - (height + 25),
    });
  }, [wWidth, wHeight]);

  const [encryptionKey, setEncryptionKey] = useState('');
  const [callAvailable, setCallAvailable] = useState(true);
  React.useEffect(() => {
    if (appointment?.appointment?.isEnded) {
      setCallAvailable(false);
    }
  }, [appointment?.appointment?.isEnded]);
  const [isMobile, setIsMobile] = useState(false);
  const {
    handleJoinVideoRoomWithPatient,
    data: joinVideoRoomWithPatientData,
    loading: joiningVideoRoomLoading,
  } = useJoinVideoRoomWithPatient();

  const { doctor } = useAuth();
  const mediaDevice = React.useRef<any>();

  const endCallAction = useCallback(() => {
    setCallAvailable(false);
    setCallRunning(false);
    refetch();
  }, []);

  const { roomId } = useDoctorWaitingRoomActions({
    doctorId: doctor?._id,
    roomSlug: doctor?.username,
    handleSetWaitingMembers: () => {},
  });

  return (
    <Context.Provider
      value={{
        endCallAction,
        encryptionKey,
        setEncryptionKey,
        setCallAvailable,
        callAvailable,
        handleJoinVideoRoomWithPatient,
        joiningVideoRoomLoading,
        setIsMobile,
        callRunning,
        setCallRunning,
        appointment,
        loading,
        refetch,
      }}
    >
      {callAvailable &&
        // joinVideoRoomWithPatientData?.joinVideoRoomWithPatient &&
        appointment?.appointment?.locationLink &&
        appointment?.appointment?.sessionType?.locationPlace !== 'Zoom' && (
          <div style={{ position: 'absolute', zIndex: 100000000 }}>
            <DraggableVideoMeet
              isMobile={isMobile}
              height={400}
              width={730}
              isWide={false}
              roomId={roomId}
              endCallAction={endCallAction}
              encryptionKey={encryptionKey}
              mediaDevice={mediaDevice}
            />
          </div>
        )}
      {children}
    </Context.Provider>
  );
}

export function useProvider(): any {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useMeetingProvider must be used within a MeetingProvider');
  }

  return context;
}
