import React, { ReactElement, useEffect } from 'react';
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { ReactComponent as DeleteTag } from 'assets/icons/Tag_close_X.svg';
import Autocomplete, {
  AutocompleteCloseReason,
  createFilterOptions,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { FormInput } from './FormInput';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Avatar } from '@material-ui/core';
import { config } from 'config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 221,
      fontSize: 13,
    },
    moreOptions: {
      position: 'relative',
      cursor: 'pointer',
    },
    more: {
      border: '1px solid #D0D5DD',
      borderRadius: '5px',
      fontSize: '18px',
      padding: '5px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    dropdown: {
      position: 'absolute',
      top: '34px',
      left: 0,
      zIndex: 999999999999999,
      backgroundColor: 'white',
      border: '1px solid #ddd',
      borderRadius: '4px',
      // padding: '18px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    dropdownItem: {
      display: 'flex',
      alignItems: 'center',
      width: '200px',
      padding: '4px 18px',
      cursor: 'pointer',
    },
    dropdownItemHover: {
      backgroundColor: '#f0f0f0',
    },
    authorName: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '16px',
      lineHeignt: '18px',
      color: '#374151',
      textAlign: 'center',
      marginLeft: theme.spacing(1),
    },
    button: {
      fontSize: 13,
      width: '100%',
      textAlign: 'left',
      paddingBottom: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: '.15em 4px',
      fontWeight: 600,
      lineHeight: '15px',
      borderRadius: 2,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 'fit-content',
      zIndex: 9999999999999999999999999999999999999,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    chip: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid #D0D5DD',
      marginBlock: '4px',
      borderRadius: '6px',
      fontFamily: 'Roboto',
      fontStyle: 'Regular',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  })
);

export interface Option {
  name: string;
  description?: string;
  color?: string;
  picture?: any;
  id: string | number;
}

export interface IAutoCompteAddProps {
  values: Option[];
  setValues: (values: Option[]) => void;
  options: Option[];
  renderPreview?: (option: Option) => ReactElement;
  renderOption?: (
    option: Option,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  renderPreviewWrapper?: (content: any) => ReactElement;
  groupBy?: any;
  hasErrors?: boolean;
  patient?: boolean;
  disableCustomFilter?: boolean;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  multiple?: boolean;
}
// Get option label
const filter = createFilterOptions<any>();
export default function AutoCompleteAdd({
  values,
  setValues,
  options,
  renderOption,
  disableCustomFilter = false,
  renderPreview,
  renderPreviewWrapper,
  hasErrors,
  patient,
  type,
  placeholder = 'Select client(s) this task might be related to',
  multiple = true,
  ...props
}: IAutoCompteAddProps): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const [pendingValue, setPendingValue] = React.useState<any[]>(values);
  const [key, setValueKey] = React.useState('autocomplete');
  const [showPlaceholder, setShowPlaceholder] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const handleClose = (
    event: React.ChangeEvent<any>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValues(pendingValue);
    if (values?.length > 0) {
      setShowPlaceholder(false);
    }
  };

  useEffect(() => {
    setPendingValue(values);
    setValueKey(pendingValue && pendingValue[0]);
    if (values?.length > 0) {
      setShowPlaceholder(false);
    }
  }, [values, patient]);

  const handleChipDelete = (deletedOption: Option) => {
    const newValues = values.filter((option) => option.id !== deletedOption.id);
    setValues(newValues);
  };
  const customFilterOptions = (argumentsOptions, params) => {
    const filtered = filter(argumentsOptions, params);
    if (params.inputValue !== '') {
      filtered.push({
        label: params.inputValue,
        type: type,
        name: params.inputValue,
        id: Math.random(),
      });
    }
    return filtered;
  };

  const filterOptions = disableCustomFilter ? undefined : customFilterOptions;

  return (
    <>
      <Autocomplete
        id="autocomplete-id"
        disableClearable
        onClose={handleClose}
        multiple={multiple}
        renderTags={(value: Option[]) => {
          const maxTags = 1;
          const visibleTags = value?.slice(0, maxTags);
          const remainingTagsCount = value?.length - maxTags;
          const remainingTags = value?.slice(maxTags);
          return (
            <Box
              display="flex"
              flexWrap="wrap"
              maxHeight="42px"
              alignItems="center"
              overflow="visible"
              position="relative"
            >
              {visibleTags?.map((option, index) => (
                <Chip
                  label={
                    <Box display="flex" alignItems="center" pl={'4px'}>
                      {option?.color && (
                        <Box
                          width={8}
                          height={8}
                          mr={1}
                          style={{
                            backgroundColor: option?.color,
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      {option?.picture && (
                        <Avatar
                          src={
                            !patient
                              ? `${config.S3URL}${option?.picture}`
                              : `${option?.picture}`
                          }
                          style={{
                            borderRadius: '50%',
                            marginRight: '10px',
                            width: '20px',
                            height: '20px',
                            fontSize: '10px',
                          }}
                        >
                          {` ${option?.name
                            ?.split(' ')?.[0]?.[0]
                            ?.toUpperCase()}${option?.name
                            ?.split(' ')?.[2]?.[0]
                            ?.toUpperCase()} `}
                        </Avatar>
                      )}
                      <Typography>
                        {t(
                          option?.name
                            ?.split(' ')
                            ?.map(
                              (word) =>
                                word.charAt(0)?.toUpperCase() +
                                word.slice(1)?.toLowerCase()
                            )
                            ?.join(' ')
                        )}
                      </Typography>
                    </Box>
                  }
                  key={index}
                  onDelete={() => handleChipDelete(option)}
                  className={classes.chip}
                  deleteIcon={
                    <DeleteTag
                      key={option.id}
                      onClick={() => handleChipDelete(option)}
                    />
                  }
                />
              ))}
              {remainingTagsCount > 0 && (
                <div
                  className={classes.moreOptions}
                  onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                >
                  <span className={classes.more}>...</span>
                  {showDropdown && (
                    <div className={classes.dropdown}>
                      {remainingTags?.map((assignee) => (
                        <div key={assignee.id} className={classes.dropdownItem}>
                          {assignee?.picture && (
                            <Avatar
                              style={{ width: '20px', height: '20px' }}
                              src={
                                !patient
                                  ? `${config.S3URL}${assignee?.picture}`
                                  : `${assignee?.picture}`
                              }
                            >
                              <Typography
                                style={{ fontSize: '12px' }}
                              >{` ${assignee?.name
                                ?.split(' ')?.[0]?.[0]
                                ?.toUpperCase()}${assignee?.name
                                ?.split(' ')?.[2]?.[0]
                                ?.toUpperCase()} `}</Typography>
                            </Avatar>
                          )}
                          <Typography className={classes.authorName}>
                            {assignee?.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Box>
          );
        }}
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal,
          popper: classes.popper,
          tag: classes.chip,
        }}
        value={pendingValue}
        key={key}
        onChange={(event, newValue) => {
          setPendingValue(newValue);
          setShowPlaceholder(false);
        }}
        disableCloseOnSelect
        getOptionSelected={(option: Option, value: Option) =>
          option?.id === value?.id
        }
        filterOptions={filterOptions}
        renderOption={renderOption}
        options={options}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <FormInput
            ref={params.InputProps.ref}
            variant="filled"
            placeholder={showPlaceholder ? placeholder : ''}
            error={hasErrors}
            style={{ opacity: props.disabled ? 0.5 : 1 }}
            {...params}
          />
        )}
        {...props}
      />
    </>
  );
}

interface LabelType {
  name: string;
  color: string;
  description?: string;
}
