import { gql, useMutation, useQuery } from '@apollo/client';

const PERMISSIOON_ADD_CHECK = gql`
  query addActionPermission($feature: String) {
    addActionPermission(feature: $feature)
  }
`;

const useAddCheckPermission = (feature) => {
  const { data, loading } = useQuery(PERMISSIOON_ADD_CHECK, {
    variables: {
      feature,
    },
    fetchPolicy: 'cache-first',
  });

  return { isEnabled: data?.addActionPermission, loading };
};

export { PERMISSIOON_ADD_CHECK };
export default useAddCheckPermission;
