import { Typography, Grid, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import MissingInformation from 'components/MissingInformation';

import DataLoader from 'components/ui/DataLoader';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import AddEventPage from 'pages/SchedulePage/AddEventPage/index';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import '@splidejs/react-splide/css/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import { ReactComponent as ArrowLeft } from 'assets/patient-profile/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/patient-profile/arrow-right.svg';
import { ReactComponent as OnlineConsultation } from 'assets/VideoConsultation.svg';
import { ReactComponent as InPersonConsultation } from 'assets/InPersonConsultation.svg';
import Modals from 'pages/InboxPage/modals/index';
import ApptCard from './ApptCardConsultation';
import Tabs from 'components/ui/Tabs';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import { IAppointment, Actions } from './types';
import { formatDates, formatTime } from 'utils';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as HideEye } from 'assets/HideEye.svg';
import { ReactComponent as EditableIcon } from 'assets/EditableIcon.svg';
import { ReactComponent as MessagingIcon } from 'assets/MessagingIcon.svg';
import { ReactComponent as AddIconApp } from 'assets/patientActivities/pluss.svg';
import useGenerateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateAppointment';
import AppointmentPage from 'pages/AppointmentPage/index';
import Button from 'components/ui/Button';
import { useLocation } from 'react-router-dom';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptySession } from 'assets/emptyState/session.svg';
import SessionSkeleton from './SessionSkeleton';
import { transformArrayTimeZone } from 'utils';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import useAddCheckPermission from 'contexts/AddPermissionOntext';

interface IPatientConsultationProps {
  doctorId: string;
  patientId: string;
  data?: any;
}

export default function PatientConsultations({
  doctorId,
  patientId,
  data: patientData,
}: IPatientConsultationProps) {
  const { handleGenerate } = useGenerateAppointment();
  const { t } = useTranslation();

  const {
    handleCloseEditMode,
    handleUpdateAppointment,
    handleAddAppointment,
    modalAddOpen,
    currentEvent,
    editMode,
    loadingAddAppointment,
    loadingEditAppointment,
    handleAddEvent,
    handleUpdateEvent,
    clickableEvent,
    loadingEditEvent,
    handleEditCurrentEvent,
    handleOpenAddModal,
    setPatientAdded,
  } = useProvider();
  const { handleOpenCreateNewThreadModal } = useInobx();
  const { isEnabled } = useAddCheckPermission('Schedule');
  const [selectedRow, setSelectedRow] = useState(null);
  const { firstname, lastname, middlename, _id, email, picture } =
    selectedRow?.patient || {};

  // const [subject, setSubject] = React.useState('');
  // React.useEffect(() => {
  //   setSubject(
  //     `${dayjs(selectedRow?.startDate).format(
  //       'MMMM DD'
  //     )}th appointment with Dr ${capitalizeFirstLetter(firstname)}`
  //   );
  // }, [selectedRow]);
  function handleMessage(row) {
    setSelectedRow(row);
    handleOpenCreateNewThreadModal();
  }
  const [pastConsultationPage, setPastConsultationPage] = useState(1);
  const [upcomingConsultationPage, setUpcomingConsultationPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const classes = useStyles({});
  const { data: upcomingAppointments, loading: nextLoading } = useQuery(
    UPCOMING_APPOINTMENTS_QUERY,
    {
      variables: {
        patientId,
        page: upcomingConsultationPage,
        limit: limit,
      },
    }
  );
  const { data, loading: pastLoading } = useQuery(PAST_APPOINTMENTS_QUERY, {
    variables: {
      patientId,
      page: pastConsultationPage,
      limit: limit,
    },
  });
  const handlePagination = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
    setState: (e: number) => void
  ) => {
    setState(page);
  };
  const columns: IColumn<IAppointment>[] = [
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 70,
      format: (value, { _id }) => {
        return (
          <Box style={{ textDecoration: 'none', color: 'black' }}>
            {formatDates(value)}
          </Box>
        );
      },
    },
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="time">Time</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 70,
      format: (value, { doctor }) => formatTime(value, doctor),
    },
    {
      id: 'sessionDuration',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="duration">Duration</Text>
        </Box>
      ),
      minWidth: 70,
      align: 'left',
      format: (value, { sessionType }) => (
        <Typography>{sessionType?.duration} minutes</Typography>
      ),
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="service">Service</Text>
        </Box>
      ),
      minWidth: 100,
      align: 'left',
      format: (value, { sessionType }) => (
        <Typography>{sessionType?.session}</Typography>
      ),
    },
    {
      id: 'locationLink',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="location">Location</Text>
        </Box>
      ),
      minWidth: 70,
      align: 'left',
      format: (value) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={70}
        >
          {value ? <OnlineConsultation /> : <InPersonConsultation />}
        </Box>
      ),
    },
    {
      id: 'Fees',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="fees">Fees</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 70,
      format: (value, { sessionType }) => (
        <Typography>
          {sessionType?.rate} {sessionType?.currency}
        </Typography>
      ),
    },
    {
      id: 'isPaid',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="paymentStatus">{t('Payment status')}</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) =>
        value === 'paid' ? (
          <Text i18nKey="paid" className={classes.paid}>
            Paid
          </Text>
        ) : value === 'overdue' ? (
          <Text i18nKey="overdue" className={classes.overdue}>
            Overdue
          </Text>
        ) : value === 'waived' ? (
          <Text i18nKey="waived" className={classes.waived}>
            Waived
          </Text>
        ) : value === 'refunded' ? (
          <Text i18nKey="refunded" className={classes.refunded}>
            Refunded
          </Text>
        ) : (
          <Text i18nKey="pending" className={classes.pending}>
            Pending
          </Text>
        ),
    },
    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 70,
      align: 'left',
      format: (value, row) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <HideEye style={{ marginRight: '15px' }} />
            <MessagingIcon
              style={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={() => {
                handleMessage(row);
              }}
            />
            <EditableIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleEditCurrentEvent(row)}
            />
          </Box>
        );
      },
    },
  ];

  const pastColumns = [
    ...columns?.filter((column) => column.id !== 'actions'),
    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 70,
      align: 'left',
      format: (value, row) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Eye
              style={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={() => handleGenerate(row?._id)}
            />
            <MessagingIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleMessage(row)}
            />
          </Box>
        );
      },
    },
  ] as IColumn<IAppointment>[];
  const [count, setCount] = React.useState(0);
  function handleNext(counting) {
    setCount(counting + 1);
  }

  function handlePrevious(counting) {
    if (length === 0) {
      return;
    }
    setCount(counting - 1);
  }
  const {
    data: appointments,
    loading,
    error,
    refetch,
  } = useQuery(APPOINTMENT_Slide, {
    variables: {
      patient: patientId,
      i: count,
    },
  });
  React.useEffect(() => {
    if (appointments) {
      refetch();
    }
  }, [appointments, refetch]);
  const appointmentData = appointments?.appointmentSlide?.appointments;
  const timezone = appointmentData && appointmentData[0]?.doctor?.timeZone;
  const sessions = transformArrayTimeZone(appointmentData, timezone);
  const length = appointments?.appointmentSlide?.count;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('event');
  return (
    <DataLoader
      loading={loading}
      error={error}
      data={appointments?.appointmentSlide}
    >
      <>
        {redirectStatus ? (
          <AppointmentPage />
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Text
                    i18nKey="sessions"
                    style={{
                      fontSize: '30px',
                      fontFamily: 'Inter',
                      fontWeight: 600,
                      color: '#101828',
                      marginRight: '1rem',
                    }}
                  >
                    Sessions
                  </Text>
                  <div className={clsx('splide__arrows', classes.arrows)}>
                    <button
                      className={clsx(
                        'splide__arrow splide__arrow--prev',
                        classes.arrow
                      )}
                      onClick={() => handlePrevious(count)}
                    >
                      <ArrowLeft />
                    </button>
                    <button
                      className={clsx(
                        'splide__arrow splide__arrow--next',
                        classes.arrow
                      )}
                      onClick={() => handleNext(count)}
                    >
                      <ArrowRight />
                    </button>
                  </div>
                </Box>
              </Box>
              <Button
                onClick={() => {
                  if (isEnabled) {
                    setPatientAdded(patientData?.patient);
                    handleOpenAddModal();
                  }
                }}
                disabled={!isEnabled}
                style={{ opacity: isEnabled ? 1 : 0.4, color: 'white' }}
                className={classes.btnAdd}
              >
                <AddIconApp style={{ marginRight: '5px' }} />
                <Text i18nKey="addSession">Add session</Text>
              </Button>
            </Box>
            <Box>
              <Grid container spacing={1}>
                {!sessions && loading && (
                  <Box
                    display="flex"
                    width="100%"
                    style={{ gap: '1rem' }}
                    px="1rem"
                  >
                    {[1, 2, 3, 4, 5].map(() => (
                      <SessionSkeleton />
                    ))}
                  </Box>
                )}
                {appointmentData?.length === 0 && !loading && (
                  <Box py="2rem" width="100%">
                    <EmptyState
                      icon={<EmptySession />}
                      title={t('No sessions yet.')}
                      isText
                      text={t('to schedule your first session.')}
                      onClick={() => {
                        if (isEnabled) {
                          setPatientAdded(patientData?.patient);
                          handleOpenAddModal();
                        }
                      }}
                    />
                  </Box>
                )}
                {sessions?.map((element) => (
                  <Grid item xl={3} lg={3}>
                    <ApptCard event={element} loading={loading} />
                  </Grid>
                ))}
              </Grid>
              <ul
                className={clsx(
                  'splide__pagination splide__pagination--ltr',
                  classes.pagination
                )}
                role="tablist"
              ></ul>
            </Box>
            <Box mt={3}>
              <Tabs
                tabsBar={[t('Upcoming'), t('Past')]}
                tabsContent={[
                  <Table<any>
                    loading={nextLoading}
                    page={upcomingConsultationPage}
                    columns={columns}
                    limit={limit}
                    fullData={false}
                    count={
                      upcomingAppointments?.patientUpcomingAppointments?.count
                    }
                    EmptyState={
                      <Box py="5rem">
                        <EmptyState
                          icon={<EmptySession />}
                          title={t(
                            'Here, you can review all your upcoming sessions and find out all the details.'
                          )}
                          titleMaxWidth="350px"
                          hasAction={false}
                        />
                      </Box>
                    }
                    handlePagination={(event, page) =>
                      handlePagination(event, page, setUpcomingConsultationPage)
                    }
                    data={transformArrayTimeZone(
                      upcomingAppointments?.patientUpcomingAppointments
                        ?.result || [],
                      timezone
                    )}
                    pagination
                  />,
                  <Table<any>
                    loading={pastLoading}
                    page={pastConsultationPage}
                    columns={pastColumns}
                    limit={limit}
                    fullData={false}
                    count={data?.patientPastAppointments.count}
                    EmptyState={
                      <Box py="5rem">
                        <EmptyState
                          icon={<EmptySession />}
                          title="Here, you can review all your previous sessions and any session-related files shared by your provider."
                          titleMaxWidth="420px"
                          hasAction={false}
                        />
                      </Box>
                    }
                    handlePagination={(event, page) =>
                      handlePagination(event, page, setPastConsultationPage)
                    }
                    data={transformArrayTimeZone(
                      data?.patientPastAppointments?.result || [],
                      timezone
                    )}
                    pagination
                  />,
                ]}
                tabsMode="simple"
              />
            </Box>
          </>
        )}
        <Modals
          contact={[{ firstname, lastname, middlename, _id, email, picture }]}
          subject={''}
        />
      </>
    </DataLoader>
  );
}

export const APPOINTMENT_Slide = gql`
  query appointmentSlide($patient: ID, $i: Int) {
    appointmentSlide(patient: $patient, i: $i) {
      appointments {
        _id
        isPaid
        description
        numberOfRepeat
        repeatEvery
        endOn
        repeatOn
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        patientGoing
        doctorGoing
        invitation {
          invitation_id
        }
        sessionType {
          session
          duration
          rate
          currency
        }
        isPrepay
        isEnded
      }
      count
    }
  }
`;
export const PAST_APPOINTMENTS_QUERY = gql`
  query getPatientPastAppointments($patientId: ID!, $page: Int, $limit: Int) {
    patientPastAppointments(patientId: $patientId, page: $page, limit: $limit) {
      result {
        _id
        isPaid
        description
        numberOfRepeat
        repeatEvery
        endOn
        repeatOn
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        patientGoing
        doctorGoing
        invitation {
          invitation_id
        }
        sessionType {
          session
          duration
          rate
          currency
        }
        isPrepay
      }
      count
    }
  }
`;
export const UPCOMING_APPOINTMENTS_QUERY = gql`
  query getPatientUpcomingAppointments(
    $patientId: ID!
    $page: Int
    $limit: Int
  ) {
    patientUpcomingAppointments(
      patientId: $patientId
      page: $page
      limit: $limit
    ) {
      result {
        _id
        isPaid
        description
        numberOfRepeat
        repeatEvery
        endOn
        repeatOn
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        patientGoing
        doctorGoing
        invitation {
          invitation_id
        }
        sessionType {
          session
          duration
          rate
          currency
        }
        isPrepay
        isEnded
      }
      count
    }
  }
`;
