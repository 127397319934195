import React from 'react';
import ReactDOM from 'react-dom';

import './style.css';
import UploadIcon from 'assets/form/blocks/UploadIcon';

interface ImageData {
  imageUrl?: string;
  imageFile?: string;
}

interface ImageConfig {
  edit?: boolean;
}

interface ImageConstructorProps {
  data: ImageData;
  config?: ImageConfig;
  api: any;
  fileInputRef: any;
  readOnly: boolean;
}

export default class Image {
  private api: any;
  private readOnly: boolean;
  private data: ImageData;
  private CSS: { wrapper: string; dropZone: string; image: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private fileInputRef = React.createRef<HTMLInputElement>();

  static get toolbox() {
    return {
      icon: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.5 10.5L8 13.51L11.5 9L16 15H2L5.5 10.5Z" fill="#475467"/>
        </svg>
        `,
      title: 'Image',
    };
  }

  constructor({ data, config, api, readOnly }: ImageConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? true;
    this.data = data || {};
    this.fileInputRef = React.createRef();
    this.CSS = {
      wrapper: 'image-tool',
      dropZone: 'image-tool__drop-zone',
      image: 'image-tool__image',
    };

    this.nodes = {
      holder: null,
    };
  }

  private handleDrop = (event: DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];

    if (file) {
      this.uploadImage(file);
    }
  };

  private uploadImage = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64Data = e.target?.result as string; // Base64 data
      // Create a new object with the file metadata and Base64 string
      const newObject = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModified,
        size: file.size,
        filename: file.name,
        mimetype: file.type,
        base64Data, // Add Base64 string representation of the file
      };

      this.data = {
        ...this.data,
        imageUrl: base64Data,
        imageFile: JSON.stringify(newObject),
      };
      this.updateDom();
    };

    reader.readAsDataURL(file);
  };

  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        this.data.imageUrl || this.data.imageFile ? (
          <img
            src={this.data.imageUrl || this.data.imageFile}
            className="image"
            alt="Uploaded"
          />
        ) : (
          <div
            className="wrapper"
            onClick={() => this.fileInputRef.current?.click()}
          >
            <div className="container">
              <div className="image-upload">
                <UploadIcon />
              </div>
              <p className="upload-text">
                <span className="click-text">Click to upload</span> or drag and
                drop the image
              </p>
              <p className="image-tool__file-size-info">(Max. 20MB)</p>

              <input
                type="file"
                ref={this.fileInputRef}
                style={{ display: 'none' }}
                onChange={this.handleFileSelect}
                accept="image/jpeg, image/png, image/bmp"
              />
            </div>
          </div>
        ),
        this.nodes.holder
      );
    }
  }

  private handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

      if (file.size > MAX_FILE_SIZE) {
        alert(
          'File size exceeds the 20MB limit. Please select a smaller file.'
        );
        return;
      }
      this.uploadImage(file);
    }
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    rootNode.ondrop = this.handleDrop;
    rootNode.ondragover = (e) => e.preventDefault();
    this.nodes.holder = rootNode;
    this.updateDom();
    return this.nodes.holder;
  }

  save(): ImageData {
    return this.data;
  }
}
