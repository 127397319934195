
import dayjs from 'dayjs';
import i18next from 'i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

enum NotificationType {
  AddAppointment = "notification-addAppointment",
  EditAppointment = "notification-editAppointment",
  DeleteAppointment = "notification-deleteAppointment",
  AddActivity = 'notification-addActivity',
  EditActivity = 'notification-editActivity',
  DeleteActivity ='notification-deleteActivity',
  AddTask ='addTask',
  CreateThread ='notification-createThread',
  SendMessage ='notification-sendMessage',
  AddActivityResponse ='notification-addActivityResponse',
  EditActivityResponse ='notification-editActivityResponse',
  DeleteActivityResponse ='notification-deleteActivityResponse',
}

export function splitText(text: string) {
  const { language } = i18next;
  let newText = text.replace(
    /(sleep|mood|reflection|message|task|session|séance)/g,
    (match, p1) => {
      const translations = {
        sleep: language === 'fr' ? 'évaluation du sommeil' : 'sleep check-in',
        mood: language === 'fr' ? "évaluation d'humeur" : 'mood check-in',
        reflection: language === 'fr' ? 'réflexion' : 'reflection',
        message: 'message',
        task: language === 'fr' ? 'tâche' : 'task',
        session: language === 'fr' ? 'séance' : 'session',
        séance: language === 'fr' ? 'séance' : 'session',
      };

      return `<span style="color: #0265DC; font-weight: bold; text-decoration: underline; cursor: pointer;" data-type="${p1}">${translations[p1]}</span>`;
    }
  );

    if (newText.includes('reflection')) {
    newText = newText.replace(/completed/g, 'submitted');
  }

  newText = newText.replace(/Checkin/g, '');
  return newText;
}



export function getTypeFromText(text: string) {
  return (
    text?.split('completed a')[1]?.trim() ||
    text?.split('logged a')[1]?.trim() ||
    text?.split('a enregistré un')[1]?.trim() ||
    text?.split('a enregistré une')[1]?.trim()
  );
}

export function dateFormat(date, language) {
  const translatedDate = dayjs(date).locale(language || 'en');
  const timeFormat = language === 'fr' ? 'HH:mm' : 'hh:mm A';
  const day = `${translatedDate.format('dddd D MMMM, YYYY')} ${i18next.t('at', {
     language,
     defaultValue: 'at' 
  })} ${translatedDate.format(timeFormat)}`;
  return day;
}


export const getNotificationFeedMessageDoctor = (notificationType, params) => {
  const { t, language } = i18next;
  const {
    notificationDate,
    notifOldDate,
  } = params;
  switch (notificationType) {
    case NotificationType.AddAppointment:
      return `${t('You scheduled a session for', { language, defaultValue: 'You scheduled a session for' })} ${dateFormat(notificationDate, language)}`;
      
    case NotificationType.EditAppointment:
      return notifOldDate === notificationDate
        ? t('You rescheduled a session', { language, defaultValue: 'You rescheduled a session' })
        : `${t('You rescheduled a session from', { language, defaultValue: 'You rescheduled a session from' })} ${dateFormat(notifOldDate, language)} ${t('to ', { language, defaultValue: 'to ' })} ${dateFormat(notificationDate, language)}.`;
    
    case NotificationType.DeleteAppointment:
      return `${t('You canceled a session scheduled for', { language, defaultValue: 'You canceled a session scheduled for' })} ${dateFormat(notificationDate, language)}`;
    
    case NotificationType.AddActivity:
      return `${t('You assigned a new activity', { language, defaultValue: 'You assigned a new activity' })}`;
    
    case NotificationType.EditActivity:
      return `${t('You rescheduled an activity', { language, defaultValue: 'You rescheduled an activity' })}`;
    
    case NotificationType.DeleteActivity:
      return `${t('You deleted an activity', { language, defaultValue: 'You deleted an activity' })}`;
    
    case NotificationType.CreateThread :
    case NotificationType.SendMessage:
      return `${t('You sent a message', { language, defaultValue: 'You sent a message' })}`;
    case NotificationType.AddTask:
      return `${t('You added a task', { language, defaultValue: 'You added a task' })}`;
    
    default:
      return `${t('Notification type not recognized', { language, defaultValue: 'Notification type not recognized' })}`;
  }
};

export const getActivityType = (actType: string) => {
  switch (actType) {
    case 'moodCheckin':
      return 'Mood Check-in';
    case 'sleepCheckin':
      return 'Sleep Check-in';
    default:
      return 'Reflection';
  }
};
export const  getNotificationFeed = (notificationType, data) => {

 const { t, language } = i18next;
  const {
    notificationDate,
    notifOldDate,
    patient,
    patientownerFirstname,
    messageSenderFirstName,
    activityType,
  } = data;

  switch (notificationType) {
    case NotificationType.AddAppointment:
      return `${capitalizeFirstLetter(patient?.firstname)} ${t(
        'scheduled a new session with you for',
        {  language, defaultValue: 'scheduled a new session with you for' }
      )} ${dateFormat(notificationDate, language)}`;

    case  NotificationType.EditAppointment :
      return notifOldDate === notificationDate
        ? `${capitalizeFirstLetter(patient?.firstname)} ${t(
            'edited session detail on',
            {  language, defaultValue: 'edited session detail on' }
          )} ${dateFormat(notificationDate, language)}`
        : `${capitalizeFirstLetter(patient?.firstname)} ${t(
            'rescheduled a session from',
            {  language, defaultValue: 'rescheduled a session from' }
          )} ${dateFormat(notifOldDate, language)} ${t('to ', {
             language, defaultValue: 'to '
          })} ${dateFormat(notificationDate, language)}`;

    case NotificationType.DeleteAppointment:
      return `${capitalizeFirstLetter(patient?.firstname)} ${t(
        'canceled a session for',
        {  language, defaultValue: 'canceled a session for' }
      )} ${dateFormat(notificationDate, language)}`;

    case  NotificationType.AddActivityResponse:
      return `${capitalizeFirstLetter(patientownerFirstname)} ${t('completed a', {  language, defaultValue: 'completed a' })} ${activityType}`;

    case NotificationType.EditActivityResponse:
      return `${capitalizeFirstLetter(patientownerFirstname)} ${t('rescheduled an activity response', {  language , defaultValue: 'rescheduled an activity response' })}`;

    case NotificationType.DeleteActivityResponse:
      return `${capitalizeFirstLetter(patientownerFirstname)} ${t('deleted an activity response', {  language , defaultValue: 'deleted an activity response'})}`;

    case NotificationType.CreateThread:
    case NotificationType.SendMessage:
      return `${capitalizeFirstLetter(messageSenderFirstName)} ${t(
        'sent a message',
        {  language, defaultValue: 'sent a message' }
      )}`;

    default:
      return `${t('Unknown notification type', {  language, defaultValue: 'Unknown notification type'  })}`;
  }
}



