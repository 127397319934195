import { Chip, ChipProps, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export const color = {
  high: 'red',
  completed: 'rgb(45, 199, 109)',
  Done: 'rgb(45, 199, 109)',
  overdue: 'rgb(254, 68, 63)',
  'To do': 'rgb(254, 68, 63)',
  low: '#FEC25A',
  default: 'rgb(247, 147, 30)',
};
const useStyles = makeStyles(() => ({
  chip: {
    color: 'black',
    backgroundColor: 'transparent',
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "Inter, sans-serif;"  }
}));

interface IDateChipProps extends ChipProps {
  label:string
}
export default function DateChip({
  label,
  className,
  ...props
}: IDateChipProps): JSX.Element {
  const classes = useStyles({ label });
  const { t } = useTranslation();
  return (
    <Chip
      className={clsx(className, classes.chip)}
      label={
          label
      }
      {...props}
    />
  );
}