import React, { ReactElement } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';
import { useToast } from 'hooks/useToast';
import useStyles from './styles';
import clsx from 'clsx';

interface IProps {
  children: React.ReactElement;
  handleClose?: () => void;
  title?: string;
  titlePadding?: string;
  isIcon?: boolean;
  className?: string;
  titleClass?: string;
  divider?: boolean;
}

export default function SlideRegularModal({
  children,
  handleClose,
  title,
  titlePadding,
  isIcon,
  className,
  titleClass,
  divider,
}: IProps): ReactElement {
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess, disablePadding: false });
  return (
    <Box className={clsx(className, classes.slideBox)}>
      <Box
        display="flex"
        flexDirection="row"
        style={{
          borderBottom: divider && '1px solid #D0D5DD',
        }}
        width="100%"
      >
        {title && (
          <Box width="100%">
            <Typography
              className={clsx(titleClass, classes.slideTitle)}
              style={{
                paddingInline: titlePadding,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {title && title[0].toUpperCase() + title.slice(1, title.length)}
              {isIcon && (
                <CancelIcon
                  className={classes.cancelIconSlide}
                  onClick={handleClose}
                />
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Box flex={1} py={!title && titlePadding}>
        {children}
      </Box>
    </Box>
  );
}
