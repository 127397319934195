/* eslint-disable */
import { useMutation, gql } from '@apollo/client';
import { ADD_APPOINTMENT } from '../../queries/appointment';
import { GET_APPOINTMENTS_BY_PATIENT } from './useGetAppointmentByPatient';

export default function useAddAppointment() {
  const [createAppointment, result] = useMutation(ADD_APPOINTMENT, {
    update: (cache, { data: { addAppointment } }) => {
      cache.modify({
        fields: {
          patientPastAppointments(pastAppointment) {
            return [...pastAppointment?.result, addAppointment];
          },
          patientUpcomingAppointments(upcomingAppointment) {
            return [...upcomingAppointment?.result, addAppointment];
          },
          appointmentsByDate(appointments) {
            if (appointments?.result) {
              return [...appointments?.result, addAppointment];
            }
            return [...appointments, addAppointment];
          },
          doctorUpcomingAppointments(upcomingAppointment) {
            return [...upcomingAppointment?.result, addAppointment];
          },
          patientsRelated(patients) {
            return [...patients, addAppointment?.patient?._id];
          },
          availableTimes(times) {
            const startDate = new Date(addAppointment?.startDate);
            const endDate = new Date(addAppointment?.endDate);

            const doctorFormatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              timeZone: addAppointment?.doctor?.timeZone,
            });

            const doctorFormattedDateStart = doctorFormatter.format(startDate);
            const doctorFormattedDateEnd = doctorFormatter.format(endDate);

            const existsBeforeStart = times?.some(
              (el) => el < doctorFormattedDateStart
            );
            const existsAfterEnd = times?.some(
              (el) => el > doctorFormattedDateEnd
            );

            if (!existsBeforeStart) {
              return times?.filter((el) => el > doctorFormattedDateEnd);
            } else if (!existsAfterEnd) {
              return times?.filter((el) => el < doctorFormattedDateStart);
            } else {
              return times?.filter(
                (el) =>
                  el < doctorFormattedDateStart || el > doctorFormattedDateEnd
              );
            }
          },
          // conflictsAppointments(appointments) {
          //   return [...appointments, addAppointment];
          // },
          appointmentSlide(appointments) {
            return [...appointments?.appointments, addAppointment];
          },
        },
      });
    },
  });
  async function addAppointment(data: any, sideEffectCallback: any) {
    try {
      const result = await createAppointment({
        variables: {
          appointmentInput: data,
        },
      });
      return result;
    } catch (e) {
      return sideEffectCallback(e);
    }
  }
  return { addAppointment, ...result };
}
export const PATIENTS_RELATED = gql`
  query patientsRelated($doctors: [ID]) {
    patientsRelated(doctors: $doctors) {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
    }
  }
`;
export const DOCTOR_FREE_TIMES = gql`
  query doctorFreeTime($doctorId: ID!, $startDate: String, $endDate: String) {
    availableTimes(
      doctorId: $doctorId
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
