import { Avatar, Typography, Box, Chip, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ChipPreview = ({ value }: any) => {
  const maxTags = 1;
  const visibleTags = value?.slice(0, maxTags);
  const remainingTagsCount = value?.length - maxTags;
  const remainingTags = value?.slice(maxTags);
  const classes = useStyles();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      position="relative"
      style={{ width: '100%' }}
    >
      {visibleTags?.map((option, index) => (
        <Chip
          label={
            <Box display="flex" alignItems="center" pl={'4px'}>
              {option?.color && (
                <Box
                  width={8}
                  height={8}
                  mr={1}
                  style={{
                    backgroundColor: option?.color,
                    borderRadius: '50%',
                  }}
                />
              )}
              <Avatar
                src={`${option?.picture}`}
                style={{
                  borderRadius: '50%',
                  marginRight: '10px',
                  width: '20px',
                  height: '20px',
                  fontSize: '10px',
                }}
              >
                {` ${option?.name
                  ?.split(' ')?.[0]?.[0]
                  ?.toUpperCase()}${option?.name
                  ?.split(' ')?.[2]?.[0]
                  ?.toUpperCase()} `}
              </Avatar>
              <Typography>
                {t(
                  option?.name
                    ?.split(' ')
                    ?.map(
                      (word) =>
                        word.charAt(0)?.toUpperCase() +
                        word.slice(1)?.toLowerCase()
                    )
                    ?.join(' ')
                )}
              </Typography>
            </Box>
          }
          key={index}
          className={classes.chip}
        />
      ))}
      {remainingTagsCount > 0 && (
        <div
          className={classes.moreOptions}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
          style={{ marginLeft: '10px' }}
        >
          <span className={classes.more}>...</span>
          {showDropdown && (
            <div className={classes.dropdown}>
              {remainingTags?.map((assignee) => (
                <Chip
                  className={classes.chip}
                  label={
                    <div key={assignee.id} className={classes.dropdownItem}>
                      <Avatar
                        style={{ width: '20px', height: '20px' }}
                        src={`${
                          assignee?.picture === null
                            ? undefined
                            : assignee?.picture
                        }`}
                      >
                        <Typography
                          style={{
                            fontSize: '12px',
                            textTransform: 'uppercase',
                          }}
                        >
                          {assignee?.firstname && assignee?.firstname[0]}
                          {assignee?.lastname && assignee?.lastname[0]}
                        </Typography>
                      </Avatar>
                      <Typography className={classes.authorName}>
                        {assignee?.name}
                      </Typography>
                    </div>
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default ChipPreview;
const useStyles = makeStyles((theme: any) => ({
  root: {
    width: 221,
    fontSize: 13,
  },
  moreOptions: {
    position: 'relative',
    cursor: 'pointer',
  },
  more: {
    border: '1px solid #D0D5DD',
    borderRadius: '5px',
    fontSize: '18px',
    height: '30px',
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  dropdown: {
    position: 'absolute',
    top: '34px',
    right: 0,
    zIndex: 999999999999999,
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: '4px',
    // padding: '18px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    paddingInline: '10px',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    width: '150px',
    padding: '4px 0px',
    cursor: 'pointer',
  },
  dropdownItemHover: {
    backgroundColor: '#f0f0f0',
  },
  authorName: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeignt: '18px',
    color: '#374151',
    textAlign: 'center',
    marginLeft: theme.spacing(1),
  },
  button: {
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 'fit-content',
    zIndex: 9999999999999999999999999999999999999,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  chip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #D0D5DD',
    marginBlock: '4px',
    borderRadius: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'Regular',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    // marginRight: theme.spacing(1),
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));
