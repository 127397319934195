import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import ScrollBar from 'components/ui/ScrollBar';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';
import { ReactComponent as EditIconMoldal } from 'assets/EditIconMoldal.svg';

import { useFormContext } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import EditButtons from 'components/EditButtons';
import useStyles from './styles';
interface IProps {
  children: React.ReactElement;
  handleClose?: () => void;
  onSubmit?: (e: any) => void;
  divider?: boolean;
  loading?: boolean;
  className?: string;
  title?: string;
  titlePadding?: string;
  disablePadding?: boolean;
  isPatient?: boolean;
  submitText?: string;
  deleteText?: string;
  cancelText?: string;
  editMode?: boolean;
  handleDelete?: () => void;
  btnsPadding?: string;
  hasTitle?: boolean;
  handleEditStep?: () => void;
}

export default function SlideModalForm({
  children,
  handleClose,
  onSubmit,
  className,
  divider,
  loading,
  title,
  titlePadding,
  disablePadding,
  isPatient,
  submitText,
  deleteText,
  cancelText,
  editMode,
  handleDelete,
  btnsPadding = '2rem',
  hasTitle = true,
  handleEditStep = null,
}: IProps): ReactElement {
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess, disablePadding });
  const methods = useFormContext();
  return (
    <Box className={classes.slideBox} data-cy="form-modal">
      <Box display="flex" alignItems="center" style={{ borderBottom: '1px solid #D0D5DD', paddingRight: "2rem" }} >
        {hasTitle && (
          <Box width="100%"  >
            <Typography
              className={classes.slideTitle}
              style={{ paddingLeft: titlePadding, display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              {title && title[0].toUpperCase() + title.slice(1, title.length)}
              <CancelIcon className={classes.cancelIconSlide} onClick={handleClose} />

            </Typography>

          </Box>
        )}
        {handleEditStep && (
          <EditIconMoldal
            className={classes.editIconSlide}
            onClick={handleEditStep}
          />
        )}




      </Box>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={classes.slideForm}
      >
        <ScrollBar
          className={className}
          isSlide={true}
          sx={{ flex: 1, paddingTop: '1rem' }}
        >
          {children}
        </ScrollBar>
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          borderTop="1px solid #EAECF0"
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <EditButtons
            editable
            isPatient={isPatient}
            type="submit"
            loading={loading}
            handleOpen={(e) => {
              e.preventDefault();
              handleClose();
            }}
            submitText={submitText}
            deleteText={deleteText}
            cancelText={cancelText}
            editMode={editMode}
            handleDelete={handleDelete}
            px={btnsPadding}
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
}
