import React, { useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/paragraph';
import { useEditorContext } from './EditorContextProvider';
import './editor.css';
import { CustomToolbar } from './Toolbar';
import { CustomSidebar } from './Sidebar';
import { Box, Grid, makeStyles } from '@material-ui/core';
import QuestionPreview from 'pages/FormsPage/QuestionPreview';

interface IEditorjsTypeProps {
  handleSave: () => void;
  handleClose: () => void;
  handleShare: () => void;
  handleOpenSave: () => void;
  handlePreview: () => void;
}
const useStyles = makeStyles((theme) => ({
  editorJS: {
    backgroundColor: '#FFFFFF',
    padding: '2.5rem 0.75rem',
    width: '70%',
    height: '90%',
    overflowY: 'auto',
    overflowX: 'auto',
    boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A',
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#FFFFFF',
      borderRadius: '20px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#d7e0eb white',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
}));

const Editorjs = ({
  handleClose,
  handleSave,
  handleShare,
  handleOpenSave,
  handlePreview,
}: IEditorjsTypeProps) => {
  const classes = useStyles();
  const [focusedQuestionIndex, setFocusedQuestionIndex] = React.useState(0);

  const { initEditor, blocks, isPreview } = useEditorContext();
  const questions =
    blocks &&
    JSON.parse(blocks)?.map((el) => {
      if (el?.data?.questions?.length > 0) {
        return { ...el?.data?.questions[0] };
      } else {
        if (el.type === 'paragraph') {
          return {
            question: el?.data?.text,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el.type === 'header') {
          return {
            question: el?.data?.text,
            range: [1, 2],
            type: el?.type,
            level: el?.data?.level,
          };
        }
        if (el?.type === 'table') {
          return {
            question: el?.data?.content,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Image') {
          return {
            question: el?.data?.imageUrl || el?.data?.imageFile,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'list') {
          return {
            question: el?.data?.items,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Orderedlist') {
          return {
            question: el?.data?.items,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Link') {
          return {
            question: el?.data?.link,
            range: [1, 2],
            type: el?.type,
          };
        }
      }
    });
  const ref = useRef<any>();
  React.useEffect(() => {
    if (!ref.current) {
      initEditor();
    }

    return () => {
      if (ref.current) {
        ref.current.destroy();
        ref.current = null;
      }
    };
  }, [blocks, isPreview]);

  return (
    <Grid
      container
      spacing={1}
      style={{
        backgroundColor: 'rgb(244, 242, 238)',
        position: 'absolute',
        marginBottom: '10rem',
      }}
    >
      <Grid item md={12} lg={12}>
        <CustomToolbar
          handleClose={handleClose}
          handleSave={handleSave}
          handleShare={handleShare}
          handleOpenSave={handleOpenSave}
          handlePreview={handlePreview}
          isPreview={isPreview}
        />
      </Grid>
      {isPreview ? (
        <div id="editorjs">
          <Grid
            item
            md={12}
            lg={12}
            xl={12}
            style={{
              backgroundColor: '#F9FAFB',
              borderRadius: '16px',
              padding: '1.625rem 30.625rem',
              height: 'calc(100vh - 64px)',
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'center',
              alignItems: 'center',
              marginInline: '1rem',
              position: 'relative',
              overflow: 'hidden',
              boxSizing: 'border-box',
              width: '100%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              height="90vh"
              // px="2rem"
            >
              <Box
                style={{
                  backgroundColor: 'white',
                  overflowY: 'auto',
                  height: '90%',
                }}
                p="2rem"
              >
                {questions?.map((question) => (
                  <QuestionPreview
                    isFormGenerator
                    question={question}
                    index={0}
                    setFocusedQuestionIndex={setFocusedQuestionIndex}
                    isFastPreview
                    ignoreRequired={false}
                    editMode={true}
                    isView
                    noHover
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </div>
      ) : (
        <>
          <Grid
            item
            md={3}
            lg={3}
            xl={3}
            style={{
              paddingBottom: '10rem',
              overflow: 'hidden',
            }}
          >
            <CustomSidebar />
          </Grid>
          <Grid item md={9} lg={9} xl={9}>
            <div
              style={{
                backgroundColor: '#F9FAFB',
                borderRadius: '16px',
                padding: '1.625rem 0.625rem',
                height: 'calc(100vh - 64px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '1rem',
                position: 'relative',
                overflow: 'hidden',
                boxSizing: 'border-box',
              }}
            >
              <div id="editorjs" className={classes.editorJS} />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Editorjs;
