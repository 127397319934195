import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './styles';
import Response from './Response';
import { IQuestion } from './FormsPage';
import Switch from 'components/ui/Switch';
import { ReactComponent as EditIcon } from 'assets/editIcon.svg';
import HeaderForm from 'pages/PatientActivitiesPage/CreateEditForm/HeaderForm';
import HorizantaleLine from './HorizantaleLine';
import ParagraphForm from 'pages/PatientActivitiesPage/CreateEditForm/ParagraphForm';
import { QuestionTypeEnum } from './Constants';

function QuestionPreview({
  question,
  index,
  isFormGenerator = false,
  setFocusedQuestionIndex,
  isFastPreview = false,
  isNotes = false,
  ignoreRequired = false,
  handleRequired,
  handleInputChange,
  handleAddress,
  handleCheckboxChange,
  viewMode,
  editMode = true,
  noMargin = false,
  getBlockIndex,
  isView = false,
  noHover = false,
}: {
  question: IQuestion;
  index?: number;
  setFocusedQuestionIndex?: React.Dispatch<React.SetStateAction<number>>;
  isFastPreview?: boolean;
  isNotes?: boolean;
  ignoreRequired?: boolean;
  getBlockIndex?: () => number;
  handleRequired?: (required: boolean) => void;
  handleInputChange?: (index: number, value: string) => void;
  handleCheckboxChange?: (index: number, value: string) => void;
  handleAddress?: (index: number, value: string, field: string) => void;
  viewMode?: boolean;
  editMode: boolean;
  noMargin?: boolean;
  isFormGenerator?: boolean;
  isView?: boolean;
  noHover?: boolean;
}): React.ReactElement {
  const classes = useStyles({ editMode, noMargin });
  const handleClickCard = () => {
    setFocusedQuestionIndex && setFocusedQuestionIndex(index);
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      width="100%"
      className={classes.PreviewCardBox}
      border={
        isFastPreview && !isFormGenerator
          ? '1px solid rgba(59, 130,246, 0.4)'
          : isHovered && isFormGenerator
          ? '1px solid rgba(59, 130,246, 0.4)'
          : 'none'
      }
      style={{
        borderRadius: isFormGenerator ? '8px' : '16px',
      }}
      onMouseEnter={!noHover && handleMouseEnter}
      onMouseLeave={!noHover && handleMouseLeave}
      onClick={handleClickCard || null}
    >
      {!isFormGenerator && isFastPreview && (
        <Box
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <EditIcon />
        </Box>
      )}
      {typeof question?.question === 'string' &&
        question?.type === 'paragraph' && (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            maxWidth="95%"
            mb={isNotes ? 2 : 0}
          >
            <ParagraphForm
              data={
                question?.question[0]?.toUpperCase() +
                question?.question
                  ?.slice(1, question.question.length)
                  .toLowerCase()
              }
            />
          </Box>
        )}
      {typeof question?.question === 'string' && question?.type === 'header' && (
        <Box width="100%" display="flex" maxWidth="95%" mb={isNotes ? 2 : 0}>
          <HeaderForm data={question?.question} level={question?.level} />
        </Box>
      )}
      {typeof question?.question === 'string' &&
        question?.type === 'HorizantaleLine' && (
          <Box width="100%" display="flex" maxWidth="95%" mb={isNotes ? 2 : 0}>
            <HorizantaleLine />
          </Box>
        )}

      {viewMode ? (
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          style={{ gap: '5px' }}
        >
          {question?.responses?.map((res, index) => (
            <>
              <Typography className={classes.Response}>
                {index < question.responses.length - 1 ? ` ${res}, ` : res}
              </Typography>
            </>
          ))}
        </Box>
      ) : (
        <Response
          isFormGenerator
          question={question}
          isFastPreview={isFastPreview}
          isNotes={isNotes}
          index={index}
          handleInputChange={handleInputChange}
          handleCheckboxChange={handleCheckboxChange}
          handleAddress={handleAddress}
          noMargin={noMargin}
          isView={isView}
        />
      )}
    </Box>
  );
}

export default QuestionPreview;
