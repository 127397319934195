export enum pages {
  DASHBOARD = '/doctor/dashboard',
  SCHEDULE = '/doctor/schedule',
  TASKS = '/doctor/task',
  CLIENTS = '/doctor/patient',
  INBOX = '/doctor/inbox',
  FINANCE = '/doctor/finance',
}

export enum patientPages {
  HOME = '/patient',
  APPOINTMENT = '/patient/appointment',
  ACTIVITIES = '/patient/activities',
  INBOX = '/patient/inbox',
  FINANCE = '/patient/finance',
}

export enum Roles {
  PATIENT = 'patient',
  DOCTOR = 'owner',
  STAFF = 'staff',
}
