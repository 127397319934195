import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as RoleIcon } from 'assets/user-left-01.svg';
import { Box, Typography } from '@material-ui/core';
import { ITeam } from './TeamSettingsTable';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import ProgressLoader from 'components/ui/ProgressLoader';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  user: ITeam;
  newRole: string;
  onSubmit: () => void;
  loading: boolean;
}

function RoleModal({
  open,
  onClose,
  user,
  newRole,
  onSubmit,
  loading,
}: IEditProps) {
  const classes = useStyles();

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <Box className={classes.PasswordModal}>
        <RoleIcon />
        <Typography className={classes.modalTitle}>
          {`Change ${user?.firstname} ${user?.lastname}’s role from ${user?.role} to ${newRole}?`}
        </Typography>
        <Typography className={classes.modalText}>
          This action will update their permissions and access throughout
          Eggmed.
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
          mt="2rem"
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            type="submit"
            className={classes.saveButton}
            onClick={onSubmit}
          >
            {loading ? <ProgressLoader width={18} height={18} /> : 'Confirm'}
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}

export default RoleModal;
