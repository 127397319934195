import React from 'react';
import useCreateRate from '@eggmed/graphql-client/operations/ratesOperations/useAddRate';
import useUpdateRate from '@eggmed/graphql-client/operations/ratesOperations/useUpdateRate';
import useDeleteRate from '@eggmed/graphql-client/operations/ratesOperations/useDeleteRate';
import { gql, useQuery } from '@apollo/client';

export function useRate() {
  const [editMode, setEditMode] = React.useState(false);
  const { createRate, loading: createLoading } = useCreateRate();
  const { updateRate, loading: updateLoading } = useUpdateRate();
  const { deleteRate, loading: deleteLoading } = useDeleteRate();
  const [dropDownEL, setDropDownEl] = React.useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = React.useState();
  const [openDelete, setopenDelete] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { data, loading: loadingQuery } = useQuery(Rates_DOCTOR);
  const { data: cpt, loading: loadingCpt } = useQuery(CPT_CODES);
  const handleClickDrop = (event: any, row: any) => {
    setDropDownEl(event.currentTarget);
    setCurrentRow(row);
  };
  function handleClick() {
    setOpen(true);
    setEditMode(false);
  }
  function handleClose() {
    setOpen(false);
  }
  const handleEdit = () => {
    setOpen(true);
    setEditMode(true);
    setDropDownEl(null);
  };
  async function handleDelete() {
    await deleteRate({
      variables: {
        rateId: (currentRow as { _id: string })?._id,
      },
    });
    setDropDownEl(null);
    return setopenDelete(false);
  }

  return {
    createRate,
    createLoading,
    updateRate,
    updateLoading,
    handleDelete,
    dropDownEL,
    setDropDownEl,
    currentRow,
    openDelete,
    setopenDelete,
    data,
    handleClickDrop,
    editMode,
    open,
    handleClick,
    handleClose,
    handleEdit,
    deleteLoading,
    loadingQuery,
    cpt,
  };
}

export const Rates_DOCTOR = gql`
  query ratesDoctor {
    rates {
      _id
      session
      currency
      rate
      duration
      selected
      isDefault
      color
      cptCode {
        category
        status
        code
        description
      }
      isPrepayment
      location
      locationPlace
      externalUrl
      tags {
        tag
        color
        _id
      }
    }
  }
`;

export const CPT_CODES = gql`
  query cptCodes {
    cptCodes {
      category
      status
      code
      description
    }
  }
`;
