import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
interface IMenuItem {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}
interface IMenuItems {
  menu: IMenuItem[];
}
const useStyles = makeStyles({
  dropdownMenuContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  menuTrigger: {
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
    padding: '8px',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '30px',
    right: 0,
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '8px 0',
    width: '150px',
    zIndex: 1,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  menuIcon: {
    marginRight: '8px',
    color: '#333',
  },
  menuLabel: {
    fontSize: '14px',
    color: '#333',
  },
  deleteLabel: {
    fontSize: '14px',
    color: '#D92D20',
  },
});
const DropdownMenu = (menu: IMenuItems) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <div
      className={classes.dropdownMenuContainer}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <MoreVertOutlined className={classes.menuTrigger} />
      {isOpen && (
        <div className={classes.dropdownMenu}>
          {menu?.menu?.map((item, index) => (
            <div
              key={index}
              className={classes.menuItem}
              onClick={item.onClick}
            >
              <span className={classes.menuIcon}>{item.icon}</span>
              <span
                className={
                  item.label === 'Delete'
                    ? classes.deleteLabel
                    : classes.menuLabel
                }
              >
                {item.label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
