import { gql, useMutation } from '@apollo/client';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

const UPDATE_STAFF_ROLE = gql`
  mutation updateUserRole($userId: String, $role: String) {
    updateUserRole(userId: $userId, role: $role)
  }
`;

const useUpdateStaffRole = () => {
  const [updateUserRole, { loading }] = useMutation(UPDATE_STAFF_ROLE);
  async function handleUpdateStaffRole(userId: string, role: string) {
    try {
      return await updateUserRole({
        variables: { userId, role },
        refetchQueries: [
          {
            query: TEAM_MEMBERS,
            variables: { role: '', status: '' },
          },
        ],
      });
    } catch (e) {
      return e;
    }
  }
  return { handleUpdateStaffRole, loading };
};

export { UPDATE_STAFF_ROLE };
export default useUpdateStaffRole;
