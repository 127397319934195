import React from 'react';
import clsx from 'clsx';
import { Avatar, Box, Button, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { config } from 'config';
interface IOptionRender {
  selected: boolean;
  name: string;
  img?: boolean;
  picture?: string;
  patient?: boolean;
}

export default function OptionRender({
  selected,
  name,
  picture,
  img,
  patient,
}: IOptionRender) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box width="100%" display="flex" alignItems="center">
      <div
        style={{ display: 'inline-flex', alignItems: 'center' }}
        className={clsx(classes.text)}
      >
        {img && picture && (
          <Avatar
            src={!patient ? `${config.S3URL}${picture}` : `${picture}`}
            className={classes.image}
            style={{ width: '20px', height: '20px', fontSize: '10px' }}
          >
            {` ${name?.split(' ')?.[0]?.[0]?.toUpperCase()}${name
              ?.split(' ')?.[2]?.[0]
              ?.toUpperCase()} `}
          </Avatar>
        )}
        <Typography>
          {t(
            name
              ?.split(' ')
              ?.map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              ?.join(' ')
          )}{' '}
        </Typography>
      </div>
      <Box ml="auto">
        <Button
          size="small"
          style={{
            backgroundColor: selected ? '#E9F9F0' : '#EFF6FF',
            color: selected ? '#2DC76D' : '#1F61DC',
          }}
        >
          {selected ? (
            <>
              <DoneIcon className={classes.iconSelected} />
              Added
            </>
          ) : (
            <>
              <AddIcon className={classes.iconSelected} />
              Add
            </>
          )}
        </Button>
      </Box>
    </Box>
  );
}
