import React, { useState } from 'react';
import Table from 'components/ui/Table';
import { Box, makeStyles } from '@material-ui/core';
import Menu from 'components/ui/Menu';
import MenuElements from './MenuElements';
import Columns, { TemplateType } from './Columns';
import BasicModal from 'components/ui/Modal';
import DuplicateModal from './DuplicateModal';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import useDeleteDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeleteDoctorTemplate';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import { useSnackbar } from 'hooks/useSnackbar';
import useMakeDefaultTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useMakeDefaultTemplate';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import AssignClientModal from './AssignClient';
import { FormProvider, useForm } from 'react-hook-form';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import useAssignQuestionaire from 'pages/PatientActivities/Questionaires/useAssignQuestionaire';
import FormPreview from 'pages/FormsPage/FormPreview';
import ScrollBar from 'components/ui/ScrollBar';
import { Itag } from './TagEdit';
import useEditDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditDoctorTemplate';
import { AUTO_SEND_TEMPLATES } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGetAutoSendTemplates';
import { SEARCH_TEMPLATES } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGetSearchTemplates';
export interface Itemplate {
  _id: string;
  id: string;
  name: string;
  type: string;
  isDefault: boolean;
  isAutoSent: boolean;
  isScored: boolean;
  tags: Itag[];
  lastUsed: string;
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

function AllTemplates({
  type,
  data,
  loading = false,
  handleOpenModal,
  page,
  setPage,
  limit,
  count,
}: {
  type: TemplateType;
  data: Itemplate[];
  loading?: boolean;
  handleOpenModal?: () => void;
  page: number;
  setPage: (page: number) => void;
  limit: number;
  count: number;
}) {
  const classes = useStyles();
  const [handleEditDoctorTemplate] = useEditDoctorTemplate();

  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<string | null>(null);
  const [currentRow, setCurrentRow] = useState<any | null>(null);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  function handlePagination(e: unknown, pageNumber: number) {
    setPage(pageNumber);
  }
  const [openAssign, setOpenAssign] = React.useState(false);
  const { triggerSnack } = useSnackbar();
  const [handleMakeDefault] = useMakeDefaultTemplate();

  const [handleDeleteDoctorTemplate, { loading: loadingDelete }] =
    useDeleteDoctorTemplate();
  function handleClosePatientModal() {
    setOpenAssign(false);
    setValues([]);
  }
  const handleAssign = () => {
    setAnchor(null);
    setOpenAssign(true);
  };
  async function handleDelete() {
    await handleDeleteDoctorTemplate({
      variables: { id: currentRow._id },
      refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
    });
    triggerSnack('Template successfully deleted');
  }
  const handleDefaultTemplate = async () => {
    await handleMakeDefault({
      variables: { id: currentRow._id },
      refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
    });
    triggerSnack('Default template successfully changed');
    setAnchor(null);
  };

  const handleAutoSent = async () => {
    await handleEditDoctorTemplate({
      variables: {
        id: currentRow?._id,
        doctorTemplateInput: {
          isAutoSent: !currentRow?.isAutoSent,
        },
      },
      refetchQueries: [
        DOCTOR_TEMPLATES,
        DOCTOR_TEMPLATES_BY_FORM,
        AUTO_SEND_TEMPLATES,
        SEARCH_TEMPLATES,
      ],
    });
    setAnchor(null);
    triggerSnack(
      `Template has been successfully ${
        currentRow?.isAutoSent ? 'disabled' : 'enabled'
      } for auto-sending`
    );
  };
  const handleViewTemplate = () => {
    setAnchor(null);
    setOpen(true);
  };
  const handleEditTemplate = () => {
    handleEditFields(currentRow);
  };
  const handleManageTags = () => {
    setAnchor(null);
    setTagOpen(true);
  };
  const handleDuplicateTemplate = () => {
    setOpenDuplicateModal(true);
    setAnchor(null);
  };
  const handleDeleteTemplate = async () => {
    setAnchor(null);
    setOpenDeleteModal(true);
  };
  const handleOpenDropDown = (e: React.MouseEvent<HTMLElement>, row: any) => {
    e.stopPropagation();
    setCurrentRow(row);
    setAnchor(e.currentTarget);
  };
  const { handleChangeText, handleSelectedTemplate, setBlocks } =
    useEditorContext();
  const handleEditFields = (row: any) => {
    setBlocks(row?.blocks, true, row?._id, true);
    handleChangeText(row?.title);
    handleSelectedTemplate(row?.formType);
    handleOpenModal();
  };
  const handleViewFields = (row: any) => {
    setCurrentRow(row);
    setOpen(true);
  };

  const menuProps = {
    handleDefaultTemplate,
    handleViewTemplate,
    handleEditTemplate,
    handleManageTags,
    handleDuplicateTemplate,
    handleDeleteTemplate,
    handleAutoSent,
    isDefault: currentRow?.isDefault,
    isAutoSent: currentRow?.isAutoSent,
    TemplateType: currentRow?.formType,
    handleAssign,
  };
  const columnProps = {
    hoveredRowIndex,
    handleOpenDropDown,
    handleEditFields,
    handleViewFields,
    type,
    tagOpen,
    setTagOpen,
    currentRow,
    data,
  };
  const columns = Columns(columnProps);

  const menuElements = MenuElements(menuProps);
  const methods = useForm({
    defaultValues: {
      type: false,
      frequencyExpand: false,
      endOn: new Date().setMonth(new Date().getMonth() + 3),
    },
  });
  const [values, setValues] = React.useState<any[]>(
    convertObjectsFormat([], '')
  );
  const { handleAssignQuestionaire, loading: loadingAssign } =
    useAssignQuestionaire(false);

  async function onAssign(data) {
    if (values.length === 0 && !data.type) return;
    data.patients = values.map(({ id }) => id);
    const { numberOfRepeat, occurence, repeatEvery, repeatOn, endOn } =
      data || {};
    const recurrence = data?.frequencyExpand
      ? {
          numberOfRepeat: Number(numberOfRepeat),
          occurence:
            data.frequencyType === 'occurrence' ? Number(occurence) : undefined,
          repeatEvery,
          repeatOn,
          endOn:
            !data.occurence && data.frequencyType === 'on' ? endOn : undefined,
        }
      : undefined;
    await handleAssignQuestionaire(
      currentRow?.doctor?._id,
      undefined,
      currentRow?.title,
      currentRow?.description,
      currentRow?.formType,
      currentRow?.blocks,
      currentRow?._id,
      data.patients,
      data.type,
      recurrence
    );
    triggerSnack('Template successfully assigned to clients');
    handleClosePatientModal();
  }

  const questions =
    currentRow?.blocks &&
    JSON.parse(currentRow?.blocks)?.map((el) => {
      if (el?.data?.questions?.length > 0) {
        return { ...el?.data?.questions[0] };
      } else {
        if (el.type === 'paragraph') {
          return {
            question: el?.data?.text,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el.type === 'header') {
          return {
            question: el?.data?.text,
            range: [1, 2],
            type: el?.type,
            level: el?.data?.level,
          };
        }
        if (el?.type === 'table') {
          return {
            question: el?.data?.content,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Image') {
          return {
            question: el?.data?.imageUrl || el?.data?.imageFile,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'list') {
          return {
            question: el?.data?.items,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Orderedlist') {
          return {
            question: el?.data?.items,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Link') {
          return {
            question: el?.data?.link,
            range: [1, 2],
            type: el?.type,
          };
        }
      }
    });
  return (
    <Box mt="2rem">
      <Table
        loading={loading}
        page={page}
        columns={columns as any}
        limit={limit}
        fullData={false}
        handlePagination={handlePagination}
        data={data || []}
        count={count}
        pagination
        sorting
        onMouseEnterRow={(row: { _id: string }) => setHoveredRowIndex(row?._id)}
        onMouseLeaveRow={() => setHoveredRowIndex(null)}
        onRowClick={(row) => handleViewFields(row)}
      />
      <Menu<any>
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        handleMenuClose={() => setAnchor(null)}
        MenuElements={menuElements}
      />
      <BasicModal
        open={openDuplicateModal}
        onClose={() => setOpenDuplicateModal(false)}
        handleClose={() => setOpenDuplicateModal(false)}
        isRegularModal
        isNew
      >
        <DuplicateModal
          row={currentRow}
          handleClose={() => setOpenDuplicateModal(false)}
        />
      </BasicModal>
      <FormProvider {...methods}>
        <BasicModal
          open={openAssign}
          onClose={handleClosePatientModal}
          handleClose={handleClosePatientModal}
          isFromModal
          onSubmit={onAssign}
          loading={loadingAssign}
          btnsPadding={'2.5rem'}
          submitText="Assign"
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '30vw',
                xl: '30vw',
              },
            }}
          >
            <AssignClientModal
              values={values}
              setValues={setValues}
              currentRow={currentRow}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <BasicModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleClose={() => setOpenDeleteModal(false)}
        className={classes.deleteModal}
        isRegularModal
      >
        <DeleteModalContent
          text="template"
          handleClose={() => setOpenDeleteModal(false)}
          handleDelete={() => {
            handleDelete();
            setOpenDeleteModal(false);
          }}
          padding={3}
        />
      </BasicModal>
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        isRegularModal
        isSlide
        divider
        // title={formToAssign?.title}
      >
        <ScrollBar
          maxHeight="99vh"
          width={'40vw'}
          paddingX={'1rem'}
          paddingBottom={'1rem'}
        >
          <FormPreview
            questions={questions}
            handleBack={() => {}}
            title={currentRow?.title}
            source={null}
            isSigned={currentRow?.isPatientSigned}
            patientId={currentRow?.patientId}
            description={currentRow?.desc}
            viewMode={false}
            editMode={false}
            isView={true}
          />
        </ScrollBar>
      </BasicModal>
    </Box>
  );
}

export default AllTemplates;
