import { Box, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { MoreVertOutlined } from '@material-ui/icons';
import { ReactComponent as PdfIcon } from "assets/icons/PdfIconFile.svg"
import { ReactComponent as ViewIcon } from "assets/eye-view.svg"
import { ReactComponent as DownloadIcon } from "assets/download.svg"
import { ReactComponent as Show } from "assets/eyeIcon.svg"
import { ReactComponent as Download } from "assets/download.svg"
import { ReactComponent as Delete } from "assets/icons/trash-01.svg"
import VideoWithPlayIcon from "components/ui/VideoWithPlayIcon"
import DropdownMenu from "components/ui/DropDownMenu";
import { humanReadableSize } from "utils/fileUtils";

interface AttachementPreviewProps {
    url: string,
    fileSize?: number,
    fileType: string,
    file: File,
    fileName: string,
    isEdit?: boolean;
    onDelete: any
}
const onDownload = (url: string, name: string) => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.target = '_blank';
    link.click();
};
const useStyles = makeStyles({
    row: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 12px 12px 0px',
        borderBottom: '1px solid #ddd',
    },
    fileInfo: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    fileIcon: {
        fontSize: '24px',
        marginRight: '12px',
    },
    fileName: {
        fontWeight: 500,
        color: '#333',
    },
    fileSize: {
        fontSize: '12px',
        color: '#888',
    },
    menuButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: '#888',
    },
});
const AttachmentItemPreview = ({ url, fileSize, fileName, file, fileType, onDelete, isEdit }: AttachementPreviewProps) => {
    const menuItems: {
        url: string, icon: React.ReactNode;
        label: string;
        onClick: () => void,
    }[] = [
            {
                url: url,
                icon: <Show />,
                label: 'View',
                onClick: () => onDownload(url, "file.pdf"),
            },
            {
                url: url,
                icon: <Delete />,
                label: 'Delete',
                onClick: () => onDelete(),
            },
        ];
    const classes = useStyles()
    const iconMedia = () => {
        switch (isEdit ? fileType : file.type) {
            case "image/png":
            case "image/jpg":
            case "image/jpeg":
                return <img src={url} width="40" height="40" alt="image user" style={{ borderRadius: "3px" }} />

            case "application/pdf":
                return <PdfIcon />
            case "video/mp4":
                return <VideoWithPlayIcon src={url} />
        }
    }
    return (
        <Box className={classes.row}>
            <Box className={classes.fileInfo}>

                {iconMedia()}
                <Box ml={1}>
                    <Box className={classes.fileName}>{fileName}</Box>
                    {fileSize && <Box className={classes.fileSize}>{humanReadableSize(fileSize)}</Box>}
                </Box>
            </Box>
            <DropdownMenu menu={menuItems} />
        </Box>


    )
}
export default AttachmentItemPreview;