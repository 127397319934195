
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // BlockPicker:
  iconSideBar:{
      display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  checkIcon: {
    bottom: "0px",
    right: "-0.5px",
    position: "absolute",
   
  },
 bpRoot: {
    borderRadius: '16px',
    backgroundColor: '#FFF',
    border: '1px solid #FFF',
    paddingBlock: '1rem',
    marginBottom: '1rem',
    flex: 1,
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    minWidth: '100%',
    overflow: 'hidden', 
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    padding: '5px 15px',
    borderRadius: '10px',
    backgroundColor: '#F7F8FA',
    border: '1px solid #CBD5E1',
    color: '#94A3B8',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    '&::placeholder': {
      color: '#94A3B8',
      opacity: 1,
    },
    '&:hover': {
      borderColor: '#A0AEC0',
    },
    '&:focus': {
      borderColor: '#3182CE',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 0px 3px rgba(66, 153, 225, 0.6)',
    },
  },
  sections: {
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto', 
    overflowX: 'hidden', 
    flex: 1,
    gap: '0.5rem',
    paddingInline: '1.25rem',
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#FFFFFF',
      borderRadius: '20px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#d7e0eb white',
    '&': {
      overflow: 'scroll',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '100%',
  },
  bpTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: '#98A2B3',
    margin: '10px 0 10px',
  },
  elements: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '7px',
  },
  element: {
    backgroundColor: '#F8F9FC',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '0.5rem 0.7rem 0rem',
    flex: 1,
    borderRadius: '4px',
    minWidth: '32%',
    border: '2px solid transparent',
    height: theme.typography.pxToRem(53),
    maxHeight:theme.typography.pxToRem(53),
    '&:hover': {
      backgroundColor: '#F8F9FC',
      border: '2px solid #147AF3',
    },
    '&:active': {
      backgroundColor: '#CAE8FF', 
      border: '2px solid #96CEFD'
    },
  },
  elementName: {
    textAlign: 'left',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#475467',
    textTransform: 'none',
  },
    

  // SaveTemplateAs:
  stpRoot: {
    borderRadius: '16px',
    backgroundColor: '#FFF',
    border: '1.5px solid #FFF'  ,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    gap:'33px',
    marginBottom: '1rem',
  },
  stpTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: '#98A2B3',
  },
  templates: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.625rem',
    marginTop: '10px',
  },
  templateItem: {
    display: 'flex',
    gap: '4px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '35%',
    height: theme.typography.pxToRem(30),
    maxHeight:theme.typography.pxToRem(30),
    flex: 1,
    padding: '8px',
    position: "relative",
    '&:hover': {
      border: '1px solid #0265DC',
    },
  },
  selected: {
    backgroundColor: '#E0F2FE',
    border: '1px solid #0265DC',
  },
  templateName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    color: '#0265DC',
  },
  inputBase:{
    borderRadius: '8px',
    color: '#98A2B3',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    border: '1px solid #D0D5DD',
    width: '100%',
    padding: '5px 15px',
    backgroundColor: '#F9FAFB',
    '&::placeholder': {
      color: '#94A3B8',
      opacity: 1,
    },
    '&:hover': {
      borderColor: '#A0AEC0',
    },
    '&:focus': {
      borderColor: '#3182CE',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 0px 3px rgba(66, 153, 225, 0.6)',
    },
  }
}));

export default useStyles;
