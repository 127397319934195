import {
  BoxProps,
  FormLabel,
  Box,
  FilledTextFieldProps,
  FormHelperText,
} from '@material-ui/core';
import Input from 'components/ui/Inputs';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { Control, Ref, FieldErrors } from 'react-hook-form';
import clsx from 'clsx';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface ITaskInputProps extends Omit<FilledTextFieldProps, 'variant'> {
  name: string;
  register?: (ref: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  label?: string;
  iconClassName?: string;
  LabelIcon?: any;
  type: string;
  options?: any[];
  renderOption?: any;
  renderTags?: any;
  multiple?: boolean;
  variant?: any;
  SelectProps?: any;
  onChange?: any;
  readOnly?: boolean;
  customInput?: any;
  minDate?: Date;
}

export default function TaskInput({
  name,
  register,
  control,
  errors,
  LabelIcon,
  iconClassName,
  label,
  minDate,
  type,
  ...props
}: ITaskInputProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      {label && (
        <FormLabel className={clsx(classes.label, classes.labelSpacing)}>
          {LabelIcon && <LabelIcon className={iconClassName} />} {label || ''}
        </FormLabel>
      )}
      <Input
        type={type}
        inputRef={register}
        control={control}
        name={name}
        className={classes.input}
        variant="filled"
        minDate={minDate}
        helperText={
          errors &&
          errors[name] && (
            <FormHelperText className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {t((errors as any)[name].message)}
            </FormHelperText>
          )
        }
        error={errors && !!errors[name]}
        {...props}
      />
    </Box>
  );
}

TaskInput.defaultProps = {};
