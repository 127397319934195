import { Chip, ChipProps, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ToDo } from "assets/icons/ToDo.svg"
import { ReactComponent as InProgress } from "assets/icons/InProgress.svg"
import { ReactComponent as Done } from "assets/icons/Done.svg"
export const color = {
  high: 'red',
  completed: 'rgb(7, 148, 85)',
  Done: 'rgb(7, 148, 85)',
  overdue: 'rgb(247, 144, 9)',
  'To do': 'rgb(56, 146, 243)',
  low: '#FEC25A',
  default: 'rgb(247, 144, 9)',
};
const useStyles = makeStyles(() => ({
  chip: {
    color: 'white',
    fontSize: '14px',
    borderRadius: "5px",
    backgroundColor: ({ priority }: IStatusChipProps) =>
      color[priority] ?? color.default,
    fontWeight: 500,
    '&.MuiChip-label': {
      paddingLeft: "2px"
    }
  },
}));

interface IStatusChipProps extends ChipProps {
  priority: string;
}
export default function StatusChip({
  priority,
  defaultValue,
  className,
  ...props
}: IStatusChipProps): JSX.Element {
  const classes = useStyles({ priority });
  const { t } = useTranslation();
  console.log("priority", priority)
  const icon = priority === 'To do' ?
    <ToDo />
    : priority === 'In progress' ?
      <InProgress />
      : <Done />

  return (
    <Chip
      className={clsx(className, classes.chip)}
      label={
        <span style={{
          display: 'flex', alignItems: 'center',
          fontWeight: "500", fontSize: "16px", lineHeight: "18px", fontFamily: "'Inter', sans-serif"
        }}>
          {icon}
          <p style={{ marginLeft: "5px" }}>{priority}</p>
        </span>
      }
      {...props}
    />
  );
}
