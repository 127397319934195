import React from 'react';
import BasicModal from 'components/ui/Modal';
import Steps from 'pages/PatientActivities/commun/Steps';
import SubscriptionModal from './SubscriptionModal';
import AddPaymentModal from './AddPaymentModal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as SuccessPaymentLogo } from 'assets/illustrations/SuccessPaymentLogo.svg';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

export enum SubscriptionType {
  FREE = 'none',
  REGULAR = 'basic',
  PREMIUM = 'premium',
}
enum RedirectStatus {
  SUCCEEDED = 'succeeded',
}
function SubscriptionPage() {
  const [open, setOpen] = React.useState(false);
  function handleCloseModal() {
    setOpen(false);
  }
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [type, setType] = React.useState('' as string);
  const [amount, setAmount] = React.useState(0);
  const { doctor } = useAuth();
  const classes = useStyles();

  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('redirect_status');
  const history = useHistory();
  React.useEffect(() => {
    if (redirectStatus === RedirectStatus.SUCCEEDED) {
      setOpen(false);
      setOpenSuccess(true);
      history.replace(history.location.pathname);
    }
  }, [redirectStatus, openSuccess]);
  React.useEffect(() => {
    let timeout;
    if (openSuccess) {
      timeout = setTimeout(() => {
        setOpenSuccess(false);
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [openSuccess]);
  return (
    <div>
      <BasicModal open={open} onClose={() => {}}>
        <Steps
          step={step}
          handleClose={() => {}}
          handleSubmit={() => {}}
          steps={[
            <SubscriptionModal
              setType={setType}
              setStep={setStep}
              setAmount={setAmount}
              doctorId={doctor._id}
              handleCloseModal={handleCloseModal}
            />,
            <AddPaymentModal
              amount={amount}
              type={type}
              handleBack={() => setStep((step) => step - 1)}
              handleClose={() => setOpen(false)}
              doctorId={doctor._id}
            />,
          ]}
        />
      </BasicModal>
      <BasicModal
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={() => setOpenSuccess(false)}
        isIcon={false}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          px={8}
          pb={4}
        >
          <SuccessPaymentLogo />
          <Box>
            <Typography className={classes.paymentTitle}>
              Your Payment is Successfull
            </Typography>
            <Typography className={classes.paymentText}>
              Thank you for your payment! A payment receipt
            </Typography>
            <Typography className={classes.paymentText}>
              will be sent to your registered email.
            </Typography>
          </Box>
        </Box>
      </BasicModal>
    </div>
  );
}

export default SubscriptionPage;
