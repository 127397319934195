import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import Input from 'components/ui/Inputs';
import { ReactComponent as SearchIcon } from 'assets/searchIcon.svg';
import ClearIcon from '@material-ui/icons/Clear';
import { useStyles } from '../styles';
import DropDownSelect from '../DropdownSelect';
import CustomButton from 'components/ui/Button';
import Text from 'Text';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';

interface IHeaderProps {
  query: string;
  handleSearch: (query: string) => void;
  handleClear: () => void;
  setRole: (role: string) => void;
  setInviteStatus: (status: string) => void;
  handleOpenModal: () => void;
  roles: [string];
}

function TeamSettingsHeader({
  query,
  handleSearch,
  handleClear,
  setRole,
  setInviteStatus,
  handleOpenModal,
  roles,
}: IHeaderProps) {
  const classes = useStyles();

  return (
    <Box
      my="1rem"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex" alignItems="center" flex={1}>
        <Input
          type="text"
          placeholder="Search team members"
          variant="filled"
          style={{
            maxHeight: '40px',
            maxWidth: '18rem',
            minWidth: '18rem',
          }}
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: query && (
              <IconButton
                className={classes.clearButton}
                onClick={handleClear}
                size="small"
              >
                <ClearIcon />
              </IconButton>
            ),
            startAdornment: <SearchIcon />,
          }}
        />
        <Box width="100%" maxWidth="158px" ml="10px" mt="5px">
          <DropDownSelect
            defaultMenuName="Role"
            setValue={setRole}
            options={roles ?? []}
            hasDefaultAll
            height="44px"
          />
        </Box>
        <Box width="100%" maxWidth="158px" ml="10px" mt="5px">
          <DropDownSelect
            defaultMenuName="Invite status"
            setValue={setInviteStatus}
            options={['Accepted', 'Pending', 'Expired']}
            hasDefaultAll
            height="44px"
          />
        </Box>
      </Box>
      <CustomButton
        className={classes.addBtn}
        buttonType="reset"
        onClick={handleOpenModal}
      >
        <AddIcon />
        <Text i18nKey="Add team member" className={classes.addBtnText}>
          Add team member
        </Text>
      </CustomButton>
    </Box>
  );
}

export default TeamSettingsHeader;
