export const SET_BLOCKS = 'setBlocks';
export const TOGGLE_SIGNATURE = 'toggleSignature';
export const SET_TITLE = 'setTitle';
export const EDIT_MODE = 'editMode';
export const RESET_BLOCK = 'resetBlock';
export const SELECTED_TEMPLATE = 'selectedTemplate';
export const TOGGLE_PREVIEW = 'togglePreview';
export const SET_TEMPLATE_ID='setTemplateId'
export const SET_ACTIVE_STYLE='setActiveStyle'
export const SET_SELECTED_ELEMENT='setSelectedElement'
export const SET_BLOCK_ID='setBlockId'
export const SET_BLOCK_NUMBER='setBlockNumber'

