import React, { useEffect, useState } from 'react';
import BasicModal from 'components/ui/Modal';
import ScrollBar from 'components/ui/ScrollBar';
import { ITeam } from './TeamSettingsTable';
import TeamMemberForm from './TeamMemberForm';
import { FormProvider, useForm } from 'react-hook-form';
import useAddTeamMember from '@eggmed/graphql-client/operations/doctorOperations/useAddTeamMember';
import { useSnackbar } from 'hooks/useSnackbar';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  values: ITeam;
  editMode: boolean;
  roles: [string];
  teamsMember: any;
}

const schema = yup.object().shape({
  firstname: yup.string().required('Firstname is a required field'),
  lastname: yup.string().required('Last name is a required field'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is a required field'),
  role: yup.string().required('Role is a required field'),
});

function EditModal({
  open,
  onClose,
  values,
  editMode,
  roles,
  teamsMember,
}: IEditProps) {
  const { triggerSnack } = useSnackbar();
  const { addStaffToOrganization, loading } = useAddTeamMember();
  const [selectedPatients, setSelectedPatients] = React.useState<any[]>([]);
  const [selectedSupervisors, setSelectedSupervisors] = React.useState<any>([]);
  const [selectedColor, setSelectedColor] = React.useState(null);
  React.useEffect(() => {
    setSelectedSupervisors(null);
    setSelectedColor(null);
    setSelectedPatients([]);
  }, [onClose]);
  const methods = useForm({ resolver: yupResolver(schema) });

  async function handeAddTeamMember(data) {
    const { npi, licensenumber, ...rest } = data || {};
    const relatedTo = selectedPatients?.map((el) => el?._id);
    await addStaffToOrganization({
      variables: {
        staffInput: {
          ...rest,
          username: `${rest?.firstname} ${rest?.lastname}`,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          providerSettings: {
            licenseNumber: licensenumber,
            npi,
          },
          relatedTo,
          colorCalendar: selectedColor,
          supervisor: selectedSupervisors?._id,
        },
      },
    });
    onClose();
    triggerSnack('Team member added successfully');
  }
  return (
    <FormProvider {...methods}>
      <BasicModal
        open={open}
        title={editMode ? 'Edit info' : 'Add a team member'}
        titlePadding="2rem"
        onClose={onClose}
        handleClose={onClose}
        isFromModal
        isSlide
        divider
        onSubmit={handeAddTeamMember}
        loading={loading}
      >
        <ScrollBar maxHeight="99vh" width={'40vw'} paddingBottom={'1rem'}>
          <TeamMemberForm
            editMode={editMode}
            selectedPatients={selectedPatients}
            setSelectedPatients={setSelectedPatients}
            roles={roles}
            teamsMember={teamsMember}
            selectedSupervisors={selectedSupervisors}
            setSelectedSupervisors={setSelectedSupervisors}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </ScrollBar>
      </BasicModal>
    </FormProvider>
  );
}

export default EditModal;
