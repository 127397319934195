export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const VIEW_TASK = 'VIEW_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const ADD_TASK = 'ADD_TASK';
export const MOVE_TASK = 'MOVE_TASK';
export const NO_USED_FEATURE = 'NO_USED_FEATURE';
export const ADD_ATTACHMENT_TASK = 'ADD_ATTACHMENT_TASK';

