import React, { ReactElement } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import Button from 'components/ui/Button';
import BasicModal from 'components/ui/Modal';
import { useToast } from 'hooks/useToast';
import DataLoader from 'components/ui/DataLoader';
import { ReactComponent as PaymentCardLogo } from 'assets/emptyState/payment.svg';
import { yupResolver } from '@hookform/resolvers';
import { FormProvider, useForm } from 'react-hook-form';
import { SchemaBilling } from '../Billing/SchemaValidation';
import EmptyComponent from '../Payment/EmptyComponent';
// TODO: To remove later
import BillingModal from '../Billing/BillingModal';
import BillingRows from '../Billing/BillingRows';
import useBilling from '../Billing/useBilling';
import { IuserCreditCard } from '../Billing/types';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import EditButtons from 'components/EditButtons';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import Input from 'components/ui/Inputs';
import { ReactComponent as StripeIcon } from 'assets/StripeIcon.svg';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  stripeFormLabel: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    color: '#253521',
  },
  stripeInfo: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#000000',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.44px',
    color: '#425364',
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));
const BillingInformation = ({
  isPatient,
}: {
  isPatient?: boolean;
}): ReactElement => {
  const { openSuccess, triggerToast } = useToast();
  const { t } = useTranslation();
  const classes = useStyles({ openSuccess, isPatient });
  const {
    open,
    openDelete,
    editMode,
    dropDownEL,
    setDropDownEl,
    currentRow,
    createCreditCard,
    user,
    creditCards,
    loading,
    error,
    handleClick,
    handleClose,
    handleEdit,
    handleOpenDelete,
    handleCloseDelete,
    handleClickDrop,
    handleChange,
    handleDelete,
    loadingAdd,
    loadingEdit,
  } = useBilling();
  const [cardId, setCardId] = React.useState('');
  const [edit, setEditMode] = React.useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const methods = useForm({
    // resolver: yupResolver(SchemaBilling),
    defaultValues: editMode ? currentRow : {},
  });
  const { register, control, errors, watch } = methods;
  const commonsProps = { register, control, errors };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${user?.firstname} ${user?.lastname}`,
      },
    });
    await createCreditCard({
      variables: {
        creditCardInput: {
          paymentMethodToken: paymentMethod.id,
        },
      },
    });
    setOpenForm(false);
  };
  const [openForm, setOpenForm] = React.useState(false);
  const elementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: 'white',
        },
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid red',
        padding: '40px 12px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const elementContainerStyle = {
    padding: '12px 16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    marginTop: '8px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  };
  return (
    <DataLoader loading={loading} error={null} data={creditCards}>
      <Box className="cyTest">
        {/* <Box
          mt={!isPatient && 3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            i18nKey="Well use the selected card to process your monthly or yearly
            recurring payments"
            className={classes.description}
          >
            We'll use the selected card to process your monthly or yearly
            recurring payments
          </Text>
          <Button
            onClick={handleClick}
            className={classes.btn}
            data-cy="AddCreditCard"
          >
            <Text className={classes.btn} i18nKey="add">
              Add
            </Text>
          </Button>
        </Box> */}

        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            style={{ marginTop: isPatient && '1rem' }}
          >
            <Text
              i18nKey="Manage your payment methods"
              style={{
                fontFamily: 'Inter, sans-serif',
                fontStyle: 'normal',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '20px',
                color: '#344054',
              }}
            >
              Manage your payment methods
            </Text>
          </Grid>
          <Box display="flex" flexDirection="column" mt={3}>
            <StripeIcon />
            <Box mt={2} width="60%">
              <Typography className={classes.stripeInfo}>
                {t('securedStripeText')}
              </Typography>
            </Box>
            {creditCards?.userCreditCard?.length > 0 && (
              <Box mt={2}>
                <BillingRows
                  creditCards={creditCards}
                  handleChange={handleChange}
                  handleClickDrop={handleClickDrop}
                  dropDownEL={dropDownEL}
                  setDropDownEl={setDropDownEl}
                  handleOpenDelete={handleOpenDelete}
                  handleEdit={handleEdit}
                  currentRow={currentRow}
                  setCardId={setCardId}
                  setEditMode={setEditMode}
                  editMode={edit}
                  openForm={openForm}
                />
              </Box>
            )}
            {openForm ? (
              <div style={{ marginTop: '2rem', width: '60%' }}>
                <form onSubmit={onSubmit}>
                  <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="name" className={classes.stripeFormLabel}>
                      {t('Name on card')}
                    </label>
                    <div style={{ marginTop: '8px' }}>
                      <Input
                        {...commonsProps}
                        name="nameOnCard"
                        variant="filled"
                      />{' '}
                    </div>
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <label className={classes.stripeFormLabel}>
                      {t('Card Number')}
                    </label>
                    <div style={elementContainerStyle}>
                      <CardNumberElement options={elementOptions} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '20px',
                    }}
                  >
                    <div style={{ width: '48%' }}>
                      <label className={classes.stripeFormLabel}>
                        {t('Expiration Date')}
                      </label>
                      <div style={elementContainerStyle}>
                        <CardExpiryElement options={elementOptions} />
                      </div>
                    </div>
                    <div style={{ width: '48%' }}>
                      <label className={classes.stripeFormLabel}>CVC</label>
                      <div style={elementContainerStyle}>
                        <CardCvcElement options={elementOptions} />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: '48%' }}>
                      <label
                        className={classes.stripeFormLabel}
                        htmlFor="country"
                      >
                        {t('Country')}
                      </label>
                      <div style={{ marginTop: '8px' }}>
                        <Input
                          {...commonsProps}
                          name="country"
                          variant="filled"
                          type="country"
                        />{' '}
                      </div>
                    </div>
                    <div style={{ width: '48%' }}>
                      <label className={classes.stripeFormLabel} htmlFor="zip">
                        {t('Zip code')}
                      </label>
                      <div style={{ marginTop: '8px' }}>
                        <Input
                          {...commonsProps}
                          name="zipCode"
                          variant="filled"
                          type="number"
                        />{' '}
                      </div>
                    </div>
                  </div>

                  <Box mt={3}>
                    <EditButtons
                      editable
                      isPatient={isPatient}
                      type="submit"
                      loading={loadingAdd}
                      handleOpen={(e) => {
                        e.preventDefault();
                        setOpenForm(false);
                      }}
                      px={'0rem'}
                      // isSlide
                    />{' '}
                  </Box>
                </form>
              </div>
            ) : (
              <div style={{ width: '50%', marginTop: '1.5rem' }}>
                <Button
                  className={classes.addBtn}
                  buttonType="reset"
                  onClick={() => setOpenForm(true)}
                >
                  <AddIcon />
                  <Text
                    style={{
                      color: 'white',
                      textTransform: 'unset',
                      display: 'flex',
                    }}
                    i18nKey="paymentMethod"
                  >
                    Add new payment method
                  </Text>
                </Button>
              </div>
            )}
          </Box>
        </Grid>
        {/* <FormProvider {...methods}>
          <BasicModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            isRegularModal
            // isSlide
            divider
            handleClose={handleClose}
            title={
              editMode ? t('Credit card information') : t('New payment method')
            }
            titlePadding="2rem"
            onSubmit={onSubmit}
            loading={loadingAdd || loadingEdit}
          >
            <Box style={{ width: '20vw' }} px={4}>
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box mt={3}>
                  <CardElement
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: '16px',
                          color: 'black',
                          '::placeholder': {
                            color: 'grey',
                          },
                        },
                        invalid: {
                          color: 'red',
                        },
                      },
                    }}
                  />
                </Box>
                <Box mt={4}>
                  <Box
                    height="6vh"
                    display="flex"
                    alignItems="center"
                    width="100%"
                    borderTop="1px solid #EAECF0"
                    style={{
                      borderBottomLeftRadius: '16px',
                      borderBottomRightRadius: '16px',
                    }}
                    // mx="5rem"
                    // mt={5}
                    // mb={2}
                    py={1}
                  >
                    <EditButtons
                      editable
                      isPatient={isPatient}
                      type="submit"
                      loading={loading}
                      handleOpen={(e) => {
                        e.preventDefault();
                        setOpenForm(false);
                      }}
                      editMode={false}
                      px={'0rem'}
                      // isSlide
                    />
                  </Box>
                </Box>
              </form>
            </Box>
          </BasicModal>
        </FormProvider> */}
        <BasicModal
          open={openDelete}
          onClose={handleCloseDelete}
          className={classes.deleteModal}
          isRegularModal
          handleClose={handleCloseDelete}
        >
          <DeleteModalContent
            text={t('credit card')}
            handleClose={handleCloseDelete}
            handleDelete={() => {
              handleDelete(cardId);
              setEditMode(false);
            }}
            p={4}
          />
        </BasicModal>
      </Box>
    </DataLoader>
  );
};
export default BillingInformation;
