import { gql, useLazyQuery, useQuery } from '@apollo/client';

import {
  GetAllThreadConversationByUser,
  GetAllThreadConversationByUserVariables,
} from './__generated__/GetAllThreadConversationByUser';
import { typeConversationEnum } from '../../__generated__/globalTypes';

const GET_ALL_THREAD_CONVERSATION_BY_USER = gql`
  query GetAllThreadConversationByUser(
    $isArchived: Boolean
    $type: typeConversationEnum
    $limit: Int
    $skip: Int
    $userPatient: ID
  ) {
    getAllThreadConvertationByUser(
      isArchived: $isArchived
      type: $type
      limit: $limit
      skip: $skip
      userPatient: $userPatient
    ) {
      _id
      subject
      type
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      lastMessage {
        createdAt
        updatedAt
        user {
          _id
          firstname
          middlename
          username
        }
        content {
          text
          files {
            _id
            url
            fileName
            mimeType
          }
        }
      }
      participants {
        user {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        isAdmin
        createdAt
        updatedAt
      }
      unReadItMessage {
        id
        threadConversation
        user
        lastMessageReadIt
      }
      lastUpdateTime
      isArchived
      createdAt
      updatedAt
    }
  }
`;

const useGetAllThreadConversationByUser = (
  isArchived: boolean,
  type: typeConversationEnum,
  limit?: number,
  skip?: number,
  userPatient?: string
) =>
  useQuery<
    GetAllThreadConversationByUser,
    GetAllThreadConversationByUserVariables
  >(GET_ALL_THREAD_CONVERSATION_BY_USER, {
    variables: {
      isArchived,
      type: type,
      limit,
      skip,
      userPatient,
    },
    // fetchPolicy: 'no-cache',
  });

export const useLazyGetAllThreadConversationByUser = () =>
  useLazyQuery<
    GetAllThreadConversationByUser,
    GetAllThreadConversationByUserVariables
  >(GET_ALL_THREAD_CONVERSATION_BY_USER);

export { GET_ALL_THREAD_CONVERSATION_BY_USER };
export default useGetAllThreadConversationByUser;
