/* eslint-disable*/
import { useMutation, gql } from '@apollo/client';
import { deleteTaskVariables, deleteTask } from './__generated__/deleteTask';

const DELETE_TASK = gql`
  mutation deleteTask($taskId: ID!) {
    deleteTask(taskId: $taskId) {
      _id
    }
  }
`;

export default function useDeleteAppointment() {
  const [deleteTask, result] = useMutation<deleteTask, deleteTaskVariables>(
    DELETE_TASK,
    {
      refetchQueries: [{ query: TASKS_LIST_QUERY }],
      awaitRefetchQueries: false,
    }
  );

  return { deleteTask, ...result };
}
export const TASKS_LIST_QUERY = gql`
  query getTasksList($doctors: [ID]) {
    tasksList(doctors: $doctors) {
      _id
      name
      tasks {
        _id
        title
        description
        status
        dueDate
        priority
        assignedTo {
          picture
          firstname
          middlename
          lastname
          _id
        }
        patients {
          picture
          firstname
          middlename
          lastname
          _id
        }
        attachments {
          fileName
          createdAt
          url
          fileType
          user {
            firstname
            middlename
            lastname
            picture
            role
            _id
          }
        }
      }
    }
  }
`;
