import { createContext } from 'react';
import { ITaskPageInitialState, ITaskPageContext, IAction } from 'shared';

import {
  ADD_TASK,
  EDIT_TASK,
  DELETE_TASK,
  VIEW_TASK,
  OPEN_MODAL,
  CLOSE_MODAL,
  MOVE_TASK,
  NO_USED_FEATURE,
  ADD_ATTACHMENT_TASK,
} from './actions';

export const initialState: ITaskPageInitialState = {
  modalType: null,
  selectedTask: null,
  modalIsOpen: false,
};
export const Context = createContext<ITaskPageContext | undefined>(undefined);

export default function Reducer(
  state: ITaskPageInitialState,
  action: IAction
): ITaskPageInitialState {
  switch (action.type) {
    case NO_USED_FEATURE: {
      return {
        ...state,
        modalType: 'NO_USED_FEATURE',
        modalIsOpen: true,
      };
    }
    case ADD_TASK: {
      return {
        ...state,
        modalType: 'ADD_TASK',
        modalIsOpen: true,
        selectedTask: action.payload,
      };
    }
    case EDIT_TASK: {
      return {
        ...state,
        modalType: 'EDIT_TASK',
        modalIsOpen: true,
        selectedTask: action.payload,
      };
    }
    case DELETE_TASK: {
      return {
        ...state,
        modalType: 'DELETE_TASK',
        modalIsOpen: true,
        selectedTask: action.payload,
      };
    }
    case VIEW_TASK: {
      return {
        ...state,
        modalType: 'VIEW_TASK',
        modalIsOpen: true,
        selectedTask: action.payload,
      };
    }
    case ADD_ATTACHMENT_TASK: {
      return {
        ...state,
        selectedTask: action.payload,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        modalIsOpen: false,
      };
    }
    default:
      throw new Error('No action found');
  }
}
