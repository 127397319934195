import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useStyles } from './Insurance';
import dayjs from 'dayjs';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { ReactComponent as EligibilityStatus } from 'assets/EligibilityStatus.svg';
import useCheckPermission from 'contexts/PermissionContext';

interface IProps {
  hoveredRowIndex: string | null;
  handleOpenDropDown: (e: React.MouseEvent<HTMLElement>, row: any) => void;
  handleEditFields: (row: any) => void;
  patientId?: string;
}
function InsuranceColumn({
  hoveredRowIndex,
  handleEditFields,
  handleOpenDropDown,
  patientId,
}: IProps) {
  const { isEnabled, loading: loadingAccess } = useCheckPermission(
    'Clients',
    'edit',
    patientId
  );
  const { isEnabled: isEnabledDelete } = useCheckPermission(
    'Clients',
    'delete',
    patientId
  );

  const classes = useStyles();
  const columns: any = [
    {
      id: 'payer',
      label: (
        <Box fontSize="1rem">
          <Typography>Insurance</Typography>
        </Box>
      ),
      align: 'left',
      minWidth: 190,
      format: (value, row) => {
        return (
          <Box display="flex" alignItems="center">
            <Typography>{value}</Typography>
            <Typography
              variant="caption"
              style={{
                backgroundColor: '#F9FAFB',
                borderRadius: '16px',
                whiteSpace: 'nowrap',
                border: '1px solid #EAECF0',
                padding: '2px 8px',
                marginLeft: '4px',
                fontWeight: 500,
                fontSize: '12px',
                fontFamily: 'Inter',
              }}
            >
              {row?.insuranceType}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'eligibilityStatus',
      label: (
        <Box fontSize="1rem">
          <Typography>Eligibility status</Typography>
        </Box>
      ),
      align: 'center',
      minWidth: 130,
      format: (value, { doctor }) => {
        return <EligibilityStatus />;
      },
    },
    {
      id: 'memberID',
      label: (
        <Box fontSize="1rem">
          <Typography>Member ID</Typography>
        </Box>
      ),
      minWidth: 190,
      align: 'left',
      format: (value, row) => {
        return <Typography>{value}</Typography>;
      },
    },
    {
      id: 'copay',
      label: <Box fontSize="1rem">Copay/Coinsurance</Box>,
      minWidth: 190,
      align: 'left',
      format: (value, row) => {
        return <Typography>${value}</Typography>;
      },
    },
    {
      id: 'effectivePeriod',
      label: (
        <Box fontSize="1rem">
          <Typography>Effective period</Typography>
        </Box>
      ),
      minWidth: 200,
      align: 'left',
      format: (value, row) => {
        return (
          <Typography>
            {dayjs(row?.startDate).format('D/MM/YYYY')} -{' '}
            {dayjs(row?.endDate).format('D/MM/YYYY')}
          </Typography>
        );
      },
    },
  ];
  if (isEnabled || isEnabledDelete) {
    columns.push({
      id: 'actions',
      label: '',
      align: 'right',
      format: (value, row) => (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {isEnabled && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              className={`${classes.actions} ${
                hoveredRowIndex === row?._id ? classes.actionsVisible : ''
              }`}
            >
              <EditIcon
                data-cy="edit-action"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditFields(row);
                }}
                style={{ cursor: 'pointer', marginRight: '1rem' }}
              />
            </Box>
          )}
          <Box
            onClick={(e) => handleOpenDropDown(e, row)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ cursor: 'pointer', width: '18px' }}
          >
            <MoreIcon data-cy="more-action" />
          </Box>
        </Box>
      ),
    });
  }

  return columns;
}

export default InsuranceColumn;
