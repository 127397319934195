import { gql, useMutation, useQuery } from '@apollo/client';

const PERMISSIOON_CHECK = gql`
  query actionPermission($feature: String, $action: String, $clientID: ID) {
    actionPermission(feature: $feature, action: $action, clientID: $clientID)
  }
`;

const useCheckPermission = (feature, action, clientID) => {
  const { data, loading } = useQuery(PERMISSIOON_CHECK, {
    variables: {
      feature,
      action,
      clientID,
    },
    fetchPolicy: 'cache-first',
  });

  return { isEnabled: data?.actionPermission, loading };
};

export { PERMISSIOON_CHECK };
export default useCheckPermission;
