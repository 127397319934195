import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { ReactComponent as RatesLogo } from 'assets/emptyState/payment.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';

import Button from 'components/ui/Button';
import BasicModal from 'components/ui/Modal';
import RatesModal from './RatesModal';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import Table from 'components/ui/Table';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DropDownMenu from './DropDownMenu';
import Checked from './Checked';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useToast } from 'hooks/useToast';
import { IRates } from './type';
import { IColumn } from 'components/ui/Table/types';
import { useRate } from './useRate';
import EmptyComponent from './EmptyComponent';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTag } from 'pages/AdminPage/ManageTags/useTag';
import { ReactComponent as InPersonAppt } from 'assets/InPersonAppt.svg';
import { ReactComponent as VideoAppt } from 'assets/VideoAppt.svg';
import { colorsMapping, colorsInfos } from '../ManageTags/Constant';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { sanitizeData } from 'utils';

export const schema = yup.object().shape({
  session: yup.string().required('Session is a required field'),
  duration: yup
    .number()
    .typeError('Duration must be a number')
    .required('Duration is a required field'),
  currency: yup.string().required('Currency is a required field'),
  rate: yup
    .number()
    .typeError('Session rates must be a number')
    .required('Session rates is a required field'),
});

export const useStyles = makeStyles<Theme>((theme) => ({
  textTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '18px',
    color: '#425364',
  },
  type: {
    backgroundColor: '#EFF8FF',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #B2DDFF',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
    color: '#175CD3',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20px',
    color: '#425364',
  },
  description: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    color: '#425364',
  },
  btnAdd: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  btn: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    marginTop: theme.spacing(2),
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#147AF3',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '17px',
    lineHeight: '36px',
    padding: '16px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
}));

const Rates = () => {
  const locationIcon = {
    'In-person': <InPersonAppt />,
    Online: <VideoAppt />,
  };

  const columns: IColumn<IRates>[] = [
    {
      id: 'session',
      label: (
        <Box fontSize="1rem">
          <Typography>Service</Typography>
        </Box>
      ),
      align: 'left',
      minWidth: 230,
      format: (value, row) => (
        <>
          {capitalizeFirstLetter(value)}
          {row?.isDefault && (
            <Tooltip title="Default service">
              <Typography
                variant="caption"
                className={classes.type}
                style={{ marginLeft: '5px' }}
              >
                Default
              </Typography>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      id: 'cptCode',
      label: (
        <Box fontSize="1rem">
          <Typography>CPT Code</Typography>
        </Box>
      ),
      align: 'left',
      minWidth: 150,
      format: (value) => value?.code || '-',
    },
    {
      id: 'color',
      label: (
        <Box fontSize="1rem">
          <Typography>Color</Typography>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => (
        <Box display="flex" alignItems="center">
          <Box
            width={10}
            height={10}
            style={{
              borderRadius: '100%',
              backgroundColor: colorsMapping[value],
              marginRight: '5px',
            }}
          />
          {value}
        </Box>
      ),
    },
    {
      id: 'duration',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="duration">Duration</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => value,
    },
    {
      id: 'currency',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="currency">Currency</Text>
        </Box>
      ),
      minWidth: 100,
      align: 'left',
      format: (value) => value,
    },
    {
      id: 'rate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="pricing">Pricing</Text>
        </Box>
      ),
      minWidth: 100,
      align: 'left',
      format: (value) => value,
    },
    {
      id: 'isPrepayment',
      label: (
        <Box fontSize="1rem">
          <Typography>Prepayment</Typography>
        </Box>
      ),
      minWidth: 10,
      align: 'left',
      format: (value) => (
        <Box
          style={{
            backgroundColor: value ? '#FEF3F2' : '#ECFDF3',
            border: `1px solid ${value ? '#FECDCA' : '#ABEFC6'}`,
            borderRadius: '16px',
            padding: '3px 8px',
          }}
          width="60%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: '12px',
              color: value ? '#B42318' : '#067647',
            }}
          >
            {value ? 'Required' : 'None'}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'location',
      label: (
        <Box fontSize="1rem">
          <Typography>Location</Typography>
        </Box>
      ),
      minWidth: 150,
      align: 'left',
      format: (value) => (
        <Box display="flex" alignItems="center">
          {locationIcon[value]}
          <Typography style={{ marginLeft: '5px' }}>{value || '-'}</Typography>
        </Box>
      ),
    },
    {
      id: 'tags',
      label: (
        <Box fontSize="1rem">
          <Typography>Tags</Typography>
        </Box>
      ),
      minWidth: 250,
      align: 'left',
      format: (value) => (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {value?.map((tag) => (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mr={1}
              mb={1}
              style={{
                border: colorsInfos[tag.color]?.border,
                borderRadius: '6px',
                padding: '1px 8px',
                backgroundColor: colorsInfos[tag?.color]?.backgroundColor,
              }}
            >
              <Typography style={{ color: colorsInfos[tag?.color]?.text }}>
                {tag.tag}
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },

    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 10,
      align: 'left',
      format: (value, row) => (
        <MoreIcon
          data-cy="more-action"
          style={{
            cursor: 'pointer',
            fontSize: '24px',
            color: '#425364',
            padding: '10px',
          }}
          onClick={(e) => handleClickDrop(e, row)}
        />
      ),
    },
  ];
  const classes = useStyles();
  const { t } = useTranslation();
  const { doctor } = useAuth();
  const {
    createRate,
    createLoading,
    updateRate,
    updateLoading,
    handleDelete,
    dropDownEL,
    setDropDownEl,
    currentRow,
    openDelete,
    setopenDelete,
    data,
    handleClickDrop,
    editMode,
    open,
    handleClick,
    handleClose,
    handleEdit,
    deleteLoading,
    loadingQuery,
    cpt,
  } = useRate();
  const { data: tags } = useTag();
  const cpts = cpt?.cptCodes;
  const { triggerToast } = useToast();
  const { triggerSnack } = useSnackbar();
  const [page, setPage] = React.useState(1);
  function handlePagination(e, pg) {
    setPage(pg);
  }
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: editMode
      ? {
          ...(currentRow as any),
          tags: (currentRow as any)?.tags?.map((el) => {
            return { ...el, name: el.tag, id: el?._id };
          }),
          cptCode: {
            ...(currentRow as any)?.cptCode,
            name: (currentRow as any)?.cptCode
              ? `${(currentRow as any)?.cptCode?.code} - ${
                  (currentRow as any)?.cptCode?.description
                }`
              : '',
            id: (currentRow as any)?.cptCode?.code,
          },
        }
      : {
          isDefault: true,
          isPrepayment: true,
          location: 'In-person',
          locationPlace: 'Eggmed',
        },
  });
  React.useEffect(() => {
    if (editMode && currentRow) {
      methods.reset({
        ...(currentRow as any),
        tags: (currentRow as any)?.tags?.map((el) => {
          return { ...el, name: el.tag, id: el?._id };
        }),
        cptCode: {
          ...(currentRow as any)?.cptCode,
          name: (currentRow as any)?.cptCode
            ? `${(currentRow as any)?.cptCode?.code} - ${
                (currentRow as any)?.cptCode?.description
              }`
            : '',
          id: (currentRow as any)?.cptCode?.code,
        },
      });
    }
  }, [editMode, currentRow, methods.reset]);
  async function onSubmit(data: IRates) {
    const sanitizedData = sanitizeData(data);
    const { duration, rate, ...rest } = sanitizedData || {};
    const { __typename, name, id, ...other } = data?.cptCode || {};
    if (editMode) {
      await updateRate({
        variables: {
          rateId: (currentRow as { _id: string })?._id,
          updateRate: {
            ...rest,
            duration: parseInt(duration, 10),
            rate: parseInt(rate, 10),
            cptCode: other,
            tags:
              data?.tags?.length > 0 ? data?.tags?.map((tag) => tag?._id) : [],
          },
          isSelect: false,
        },
      });
    } else {
      await createRate({
        variables: {
          rateInput: {
            ...rest,
            doctor: doctor?._id,
            duration: parseInt(duration, 10),
            rate: parseInt(rate, 10),
            cptCode: other,
            tags:
              data?.tags?.length > 0 ? data?.tags?.map((tag) => tag?._id) : [],
          },
        },
      });
    }
    handleClose();
    triggerSnack(
      editMode ? 'Service updated successfully' : 'Service created successfully'
    );
  }
  if (data?.rates?.length === 0) {
    return (
      <EmptyComponent
        logo={<RatesLogo />}
        labelText="No pay rates"
        labelDescription="to add your pay rates"
        btnLabel="Start now"
        handleClick={() => {
          methods.reset({
            isDefault: true,
            isPrepayment: true,
            location: doctor?.location || 'In-person',
            locationPlace: doctor?.locationPlace || 'Eggmed',
            externalUrl: doctor?.externalUrl || undefined,
          });
          handleClick();
        }}
        methods={methods}
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmit}
        modal={
          <RatesModal
            currentRow={currentRow}
            editMode={editMode}
            tags={tags}
            cpts={cpts}
          />
        }
        modalTitle={'New Pay Rate'}
      />
    );
  }
  return (
    <Box pb={6}>
      <Text i18nKey="Services" className={classes.title}>
        Services
      </Text>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text
          i18nKey="Manage your services and associated rates and duration"
          className={classes.description}
        >
          Manage your services and associated rates and duration
        </Text>
        <Button
          className={classes.addBtn}
          onClick={() => {
            methods.reset({
              isDefault: true,
              isPrepayment: true,
              location: doctor?.location || 'In-person',
              locationPlace: doctor?.locationPlace || 'Eggmed',
              externalUrl: doctor?.externalUrl || undefined,
            });
            handleClick();
          }}
        >
          <AddTaskIcon
            style={{
              paddingRight: '6px',
            }}
          />
          <Text i18nKey="add">Add</Text>
        </Button>
      </Box>

      <Box mt={2}>
        <Table
          loading={loadingQuery}
          page={page}
          columns={columns}
          limit={5}
          data={data?.rates || []}
          pagination
          handlePagination={handlePagination}
        />
      </Box>

      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal
          isSlide
          divider
          title={editMode ? t('Edit service') : t('Add new service')}
          titlePadding="2rem"
          onSubmit={onSubmit}
          loading={createLoading || updateLoading}
        >
          <Grid container style={{ marginBottom: '5vh' }}>
            <RatesModal
              currentRow={currentRow}
              editMode={editMode}
              tags={tags}
              cpts={cpts}
            />
          </Grid>
        </BasicModal>
      </FormProvider>
      <DropDownMenu
        handleEdit={() => handleEdit()}
        anchorEl={dropDownEL}
        handleClose={() => setDropDownEl(null)}
        handleDelete={() => setopenDelete(true)}
        row={currentRow}
      />
      <BasicModal
        open={openDelete}
        onClose={() => setopenDelete(false)}
        className={classes.deleteModal}
        isRegularModal
        handleClose={() => setopenDelete(false)}
      >
        <DeleteModalContent
          text="session"
          handleClose={() => setopenDelete(false)}
          handleDelete={() => handleDelete()}
          p={4}
          loading={deleteLoading}
        />
      </BasicModal>
    </Box>
  );
};

export default Rates;
