import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
export const MAX_SIZE = 20971520;

interface IAnimatedProgressBar {
  progress: number,
  fileSize: number,
  setErrorUpload: (val: boolean) => void
  setCurrentFile: (file: any) => void
}
function ProgressBar(props: IAnimatedProgressBar) {
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const useStyles = makeStyles((theme) => ({
    progressBar: {
      width: '100%',
      height: '10px',
      backgroundColor: '#eee',
      borderRadius: '5px',
      overflow: 'hidden'
    },
    progressBarFill: {
      height: '100%',
      backgroundColor: "#007bff"
    },
    successMessage: {
      color: "green"
    },
    errorMessage: {
      color: "#B42318",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px"
    },
    progressBarLoading: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    }
  }));
  const classes = useStyles()


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progressBarWidth < props.progress) {
        setProgressBarWidth(progressBarWidth + 1);
        props.setErrorUpload(false)

      } else {
        clearInterval(intervalId);
        if (progressBarWidth === 100) {
          if (props.fileSize > MAX_SIZE)
            props.setErrorUpload(true)

          else {
            props.setErrorUpload(false)
            setTimeout(() => { props.setCurrentFile(null) }, 1000)
          }
        }
      }
    }, 10);
    return () => clearInterval(intervalId);
  }, [progressBarWidth, props.fileSize]);

  return (
    progressBarWidth < 100 ? (<div className={classes.progressBarLoading}>
      <div className={classes.progressBar}>
        <div className={classes.progressBarFill} style={{ width: `${progressBarWidth}%` }} />

      </div>
      <div style={{ marginLeft: "20px" }}>{progressBarWidth}%</div>

    </div>) :
      props.fileSize < MAX_SIZE ? <b className={classes.successMessage}>File uploaded successfully</b> : <b className={classes.errorMessage}>Upload failed, size cannot exceed 20MB.</b>


  );
}
export default ProgressBar;