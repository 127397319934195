export default {
  debug: false,
  ENV: process.env.REACT_APP_ENV,
  ALLOW_CONSOLE_LOGS: process.env.REACT_APP_ALLOW_CONSOLE_LOGS,
  EGGMED_CALL_LINK: process.env.REACT_APP_EGGMED_CALL_LINK,
  EGGMED_DOCTOR_ROOM_LINK: process.env.REACT_APP_EGGMED_DOCTOR_ROOM,
  URLS_STUN: process.env.REACT_APP_URL_STUN,
  URLS_TURN: process.env.REACT_APP_URL_TURN,
  USERNAME: process.env.REACT_APP_USERNAME,
  CREDENTIAL: process.env.REACT_APP_CREDENTIAL,
  SERVER: process.env.REACT_APP_SERVER,
  CLIENT: process.env.REACT_APP_CLIENT,
  S3URL: process.env.REACT_APP_S3_URL,
  PUBLICHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLICHABLE_KEY,
  MEETING_DOMAIN: process.env.REACT_APP_MEETING_DOMAIN,
};
