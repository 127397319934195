import * as React from 'react';
import { appointmentPageContext, IAction } from 'shared';
import {
  SET_BLOCKS,
  TOGGLE_SIGNATURE,
  SET_TITLE,
  EDIT_MODE,
  RESET_BLOCK,
  SELECTED_TEMPLATE,
  SET_TEMPLATE_ID,
  TOGGLE_PREVIEW,
  SET_ACTIVE_STYLE,
  SET_SELECTED_ELEMENT,
  SET_BLOCK_ID,
  SET_BLOCK_NUMBER,
} from './actions';

export function reducer(state, action): any {
  switch (action.type) {
    case SET_BLOCKS: {
      return {
        ...state,
        blocks: action.payload,
      };
    }
    case TOGGLE_SIGNATURE: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case SET_TITLE: {
      return {
        ...state,
        formTitle: action.payload,
      };
    }
    case EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload,
      };
    }
    case RESET_BLOCK: {
      return {
        ...state,
        blocks: null,
      };
    }
    case SELECTED_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    }
    case SET_TEMPLATE_ID: {
      return {
        ...state,
        templateId: action.payload,
      };
    }
    case TOGGLE_PREVIEW: {
      return {
        ...state,
        isPreview: action.payload,
      };
    }
    case SET_SELECTED_ELEMENT: {
      return {
        ...state,
        selectedElement: action.payload,
      };
    }
    case SET_BLOCK_ID: {
      return {
        ...state,
        blockId: action.payload,
      };
    }
    case SET_BLOCK_NUMBER: {
      return {
        ...state,
        blockNumber: action.payload,
      };
    }
    case SET_ACTIVE_STYLE: {
      return {
        ...state,
        activeStyle: {
          ...state.activeStyle,
          bold: action.payload.bold,
          italic: action.payload.italic,
          underline: action.payload.underline,
          fontSize: action.payload.fontSize,
        },
      };
    }

    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}
