import { gql, useQuery } from '@apollo/client';


export default function useCalculatePercentageFinance({
    time
}) {
  const { data, error, loading } = useQuery(GET_CALCULATE_FINANCE, {
    variables: {
      time,
    }
  });
  return { data, error, loading };
}
const GET_CALCULATE_FINANCE = gql`
 query calculateFinance($time: String!) {
  calculateFinance(time: $time) {
    pending
    paid
    overdue
    refunded
    waived
    pendingPourcentage
    paidPourcentage
    overduePourcentage
    refundedPourcentage
    waivedPourcentage
  }
}
`;
export { GET_CALCULATE_FINANCE };

