import { makeStyles } from "@material-ui/core";
import React, { useRef } from "react";


interface ISrcVideo {
    src: string;
}
const VideoWithPlayIcon = (props: ISrcVideo) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const useStyles = makeStyles((theme) => ({
        btnVideoPlay: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        topVideoContainer: {
            position: 'relative',
            display: "flex",
            width: '40px',
        }
    }));

    const classes = useStyles()

    return (
        <div className={classes.topVideoContainer} >
            <video
                ref={videoRef}
                muted
                style={{ width: "40px", height: "auto" }
                }
                playsInline
            >
                <source src={props.src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            < div
                onClick={handlePlay}
                className={classes.btnVideoPlay}
            >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="white" >
                    <path d="M8 5v14l11-7z" />
                </svg>
            </div>
        </div>
    );
};
export default VideoWithPlayIcon