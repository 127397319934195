import React from 'react';
import useAssignQuestionaire from './useAssignQuestionaire';
import { useProvider as useFormProvider } from './ActivityProvider';
import BasicModal from 'components/ui/Modal';
import FormModal from './FormModal';
import AssignModal from './AssignModal';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import { Box, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import AssignClientModal from 'pages/AdminPage/TabsContent/templatesTabs/AssignClient';

function FormModals({ patient }: { patient: IPatientData }) {
  const methods = useForm({
    defaultValues: {
      type: false,
      frequencyExpand: false,
      endOn: new Date().setMonth(new Date().getMonth() + 3),
    },
  });
  const { handleAssignQuestionaire, loadingOne: loadingAssign } =
    useAssignQuestionaire(true);
  const { t } = useTranslation();
  const [formTitle, setFormTitle] = React.useState<string>(t('Form'));
  const {
    openForm,
    triggerToast,
    formToAssign,
    setAssignOpen,
    assignOpen,
    doctor,
    handleClose,
    data,
    openAssignModal,
    handleDeleteModal,
    openDeleteModal,
    setOpenDeleteModal,
    handleDelete,
    loading,
  } = useFormProvider();
  const { triggerSnack } = useSnackbar();

  const handleSubmit = async (data) => {
    const { numberOfRepeat, occurence, repeatEvery, repeatOn, endOn } =
      data || {};
    const recurrence = data?.frequencyExpand
      ? {
          numberOfRepeat: Number(numberOfRepeat),
          occurence:
            data.frequencyType === 'occurrence' ? Number(occurence) : undefined,
          repeatEvery,
          repeatOn,
          endOn:
            !data.occurence && data.frequencyType === 'on' ? endOn : undefined,
        }
      : undefined;
    await handleAssignQuestionaire(
      formToAssign?.doctor?._id || doctor?._id,
      patient?._id,
      formToAssign?.title,
      formToAssign?.description,
      formToAssign?.formType,
      formToAssign?.blocks,
      formToAssign?._id,
      [''],
      false,
      recurrence
    );
    triggerSnack('Template assigned successfully to client');
    setTimeout(() => setAssignOpen(false), 500);
  };
  return (
    <>
      <BasicModal
        isRegularModal
        isSlide
        title={formTitle}
        open={openForm}
        onClose={handleClose}
        handleClose={handleClose}
      >
        <FormModal
          setFormTitle={setFormTitle}
          handleClose={handleClose}
          title={`Assign form to ${patient?.firstname}`}
          patientId={patient?._id}
          patient={patient}
          assignText={`Assign this form to ${patient?.firstname} ${patient?.lastname}?`}
          doctorTemplates={data}
          openAssignModal={openAssignModal}
          openDeleteModal={handleDeleteModal}
          loading={loading}
        />
      </BasicModal>
      <FormProvider {...methods}>
        <BasicModal
          open={assignOpen}
          onClose={() => setAssignOpen(false)}
          handleClose={() => setAssignOpen(false)}
          isFromModal
          onSubmit={handleSubmit}
          loading={loadingAssign}
          btnsPadding={'2.5rem'}
          submitText="Assign"
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '30vw',
                xl: '30vw',
              },
            }}
          >
            <AssignClientModal
              values={null}
              setValues={null}
              currentRow={{ title: formToAssign?.title }}
              isOne={false}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <ModalDelete
        text="Template"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default FormModals;
