import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from './AnimatedProgressBar/AnimatedProgressBar';
interface UploadStatusProps {
  file: File,
  size?: number;
  status?: 'uploading' | 'failed' | 'success';
  progress?: number;
  icon: any;
  fileName?: string;
  fileType?: string;
  taskId?: any
  show: boolean;
  url?: string;
  isEdit: boolean;
  onClose?: () => void;
  handleDeleteTaskAttachment?: (
    taskId: string,
    attachmentUrl: string,
    closeModal?: () => void
  ) => void;
  setCurrentFile: (file: any) => void

}
interface StyleProps {
  show: boolean,
  isErrorUpload: boolean
}
const useStyles = makeStyles((theme) => ({
  topContainer: ({ show, isErrorUpload }: StyleProps) => ({
    width: "100%",
    border: isErrorUpload ? "1px solid #D92D20" : "1px solid #ccc",
    borderRadius: "10px",
    paddingBootom: "4%",
    marginBottom: "16px",
    position: 'relative',
    paddingTop: "1%",
    display: show ? 'inline-flex' : 'none',
    height: "auto",
    justifyContent: "center"
  }),
  closeIcon: {
    position: 'absolute',
    right: '10px',
    padding: '5px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  uploadStatus: {
    padding: '10px',
    marginBottom: '10px',
    display: "flex",
    alignItems: "flex-start",
    gap: "15px",
    width: '100%'
  },
  progressBar: {
    width: 'auto',
    height: '20px',
    backgroundColor: '#eee',
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
  },
  progressBarFill: {
    height: '100%',
    backgroundColor: '#007bff',
    animation: '$progressBarFillAnimation 2s linear',
  },
  progressBarPercentage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
  },
  '@keyframes progressBarFillAnimation': {
    from: {
      width: '0%',
    },
    to: {
      width: '100%',
    },
  },
  fileInfo: {
    display: 'block',
  },
  progress: {
    height: '10px',
    backgroundColor: '#eee',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  statusMessage: {
    color: '#007bff', // Default for success

  },
  detailsButton: {
    cursor: 'pointer',
  },
  details: {
    marginTop: '5px',
  },
  filename: {
    color: "#344054",
    fontWeight: 600
  },
  size: {
    marginBottom: '15px',
    color: "#475467",
    fontWeight: 400
  },
  btnVideoPlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topVideoContainer: {
    position: 'relative',
    display: "flex",
    width: 'auto',
  }
}));
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg"
import { makeStyles } from '@material-ui/core';
import { generateURLFromFile, humanReadableSize } from 'utils/fileUtils';
const UploadFilePreview = ({
  progress,
  icon,
  show,
  isEdit,
  onClose,
  fileName,
  fileType,
  url,
  file,
  taskId,
  handleDeleteTaskAttachment,
  setCurrentFile
}: UploadStatusProps) => {

  const [isErrorUpload, setErrorUplaod] = useState(false)

  const classes = useStyles({ show, isErrorUpload })
  const VideoWithPlayIcon = ({ src }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    return (
      <div className={classes.topVideoContainer} >
        <video
          ref={videoRef}
          muted
          style={{ width: "70px", height: "auto" }}
          playsInline
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          onClick={handlePlay}
          className={classes.btnVideoPlay}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="white">
            <path d="M8 5v14l11-7z" />
          </svg>
        </div>
      </div>
    );
  };


  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  const getContentMediaType = () => {
    switch (isEdit ? fileType : file.type) {
      case "application/pdf":
        return icon
      case "image/png":
      case 'image/jpg':
      case 'image/jpeg':
        return <img style={{ borderRadius: "10px", width: "70px", height: "auto" }} src={isEdit ? url : generateURLFromFile(file)} />
      case 'video/mp4':
        return <VideoWithPlayIcon src={isEdit ? url : generateURLFromFile(file)} />
    }
  }

  return (
    <div className={classes.topContainer}>


      <div className={classes.uploadStatus}>


        {getContentMediaType()}
        <div style={{ display: "block", width: "100%" }}>

          <div className={classes.fileInfo}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={isEdit ? () => { handleDeleteTaskAttachment(taskId, url, handleClose); } : handleClose}
              />
              <div className={classes.filename}>{isEdit ? fileName : file.name}</div>
            </div>
            {!isEdit && <div className={classes.size}>{humanReadableSize(file.size)}</div>}
          </div>

          {!isEdit && <ProgressBar setCurrentFile={setCurrentFile} setErrorUpload={setErrorUplaod} fileSize={file.size} progress={progress} />}
        </div>



      </div>
    </div>

  );
};

export default UploadFilePreview;