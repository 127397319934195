import { Box } from '@material-ui/core';
import React from 'react';
import { Steps } from './Steps';

export const CreateInvoiceFlow = ({
  setInvoiceTitle,
  handleClose,
  page,
  filter,
  patient,
  refetch,
}) => {
  return (
    <Box width="40vw">
      <Steps
        setInvoiceTitle={setInvoiceTitle}
        handleClose={handleClose}
        page={page}
        filter={filter}
        patient={patient}
        refetch={refetch}
      />
    </Box>
  );
};
