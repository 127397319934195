import React, { ReactElement } from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as AddPatientIcon } from 'assets/add-patient.svg';
import Button from 'components/ui/Button';
import { LimitComponent } from './LimitComponent';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { convertObjectsFormatStaffs } from 'pages/SchedulePage/AddEventPage/utils';
import { useQuery } from '@apollo/client';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import Skeleton from 'components/ui/Skeleton';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    textTransform: 'unset',
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1rem',
    lineHeight: '36px',
    padding: '16px',
    height: '44px',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#f7f7f5',
    },
  },
}));
interface IPatientListProps {
  handleOpen: () => void;
  limit: number;
  setLimit: (number: number) => void;
  disableEntries?: boolean;
  btnText?: string;
  values?: any;
  setValues?: any;
  isEnabled?: boolean;
}

export default function PatientListHeader({
  handleOpen,
  limit,
  setLimit,
  disableEntries = false,
  btnText,
  values,
  setValues,
  isEnabled,
}: IPatientListProps): ReactElement {
  const { t } = useTranslation();

  function handleLimitChange(event: React.ChangeEvent<any>) {
    setLimit(parseInt(event.target.value, 10));
  }
  const classes = useStyles({ disableEntries });
  const { data: datas, loading } = useQuery(TEAM_MEMBERS, {
    variables: { role: '', status: '' },
  });
  const users = convertObjectsFormatStaffs(datas?.teamSettings, '');

  const { user } = useAuth();
  return (
    <Box className={classes.root}>
      <Typography variant="h1" component="h3" className={classes.title}>
        <Text i18nKey="clients">Clients</Text>
      </Typography>
      <Box display="flex" alignItems="center">
        {['Admin', 'owner']?.includes(user?.role) && users?.length > 0 && (
          <Box ml={1} width="350px" mr={2}>
            <Skeleton loading={loading}>
              <Symptoms
                img={true}
                type=""
                disableCustomFilter={true}
                options={users}
                values={values}
                setValues={setValues}
                hasErrors={false}
                placeholder="Select providers"
              />
            </Skeleton>
          </Box>
        )}
        <Button
          className={classes.addBtn}
          onClick={handleOpen}
          data-cy="add-button"
          disabled={!isEnabled}
          style={{ opacity: isEnabled ? 1 : 0.4 }}
        >
          <AddPatientIcon
            style={{
              paddingRight: '8px',
            }}
          />
          {btnText ?? t('Add client')}
        </Button>
      </Box>
    </Box>
  );
}
