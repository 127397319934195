import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import ScrollBar from 'components/ui/ScrollBar';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyForm } from 'assets/emptyState/form.svg';
import FormCard, { CardType, FormCardLoading } from './FormCard';

export const FormTemplates = ({
  templates,
  loading,
  handleNext,
  isNotes,
  setMenuType,
  handleAssignForm,
  handleOpenAssignModal,
}) => {
  const classes = useStyles({ selected: false });

  return (
    <Box
      width="100%"
      height="calc(100% - 78px)"
      className={classes.ScrollBar}
      mt={4}
    >
      <Box className={classes.myFormSection}>
        {/* <ScrollBar
          height="100%"
          maxHeight="30vh"
          mt="30px"
          
        > */}
        <Box
          width="100%"
          // display="flex"
          // flexWrap="wrap"
          // boxSizing="border-box"
          style={{ gap: '8px' }}
          // justifyContent="space-between"
          // alignItems="start"
          className={classes.myFormsBox}
        >
          {loading && (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
                return <FormCardLoading />;
              })}
            </>
          )}
          {!loading && templates && templates.length === 0 && (
            <Box
              py="5rem"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <EmptyState
                icon={<EmptyForm />}
                title="No forms yet"
                text={`to add a new form`}
                onClick={handleNext}
                isText
              />
            </Box>
          )}
          {templates &&
            templates.map((form) => {
              return (
                <FormCard
                  form={form}
                  handleAssignForm={handleAssignForm}
                  handleOpenAssignModal={handleOpenAssignModal}
                  cardType={isNotes ? CardType.notes : CardType.personal}
                  setMenuType={setMenuType}
                />
              );
            })}
        </Box>
        {/* </ScrollBar> */}
      </Box>
    </Box>
  );
};
