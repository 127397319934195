import React, { useMemo, useState } from 'react';
import { Avatar, Box, FormHelperText, Typography } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import AutoCompleteAddNew from 'components/ui/Inputs/AutoCompleteAddnew';
import useStyles from './styles';
import Button from 'components/ui/Button';
import { ReactComponent as AddClientIcon } from 'assets/Schedule/new/add-client.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { capitalizedText } from 'utils/FormatText';
import useAddCheckPermission from 'contexts/AddPermissionOntext';

interface IPatientSelectProps {
  register: any;
  control: any;
  errors: any;
  label?: string;
  placeholder?: string;
  setMatchingPatient?: (matchingPatient: boolean) => void;
  loading?: boolean;
  options: any[];
  handleInputChange?: (event, inputValue) => void;
  inputValue?: string;
  filterOptions?: () => void;
  editMode?: boolean;
  handleOpenAddPatient?: () => void;
  hasAddBtn?: boolean;
}

export default function PatientSelect({
  errors,
  setMatchingPatient,
  handleInputChange,
  options,
  inputValue,
  filterOptions,
  label = 'Client',
  editMode = false,
  handleOpenAddPatient,
  hasAddBtn = true,
  ...props
}: IPatientSelectProps) {
  const { isEnabled } = useAddCheckPermission('Clients');
  const classes = useStyles();
  const { t } = useTranslation();

  const filterOption = useMemo(
    () =>
      (options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }
        const filteredOptions = options.filter((option) => {
          const fullName = `${option.firstname} ${option.lastname}`;
          return fullName
            .toLocaleLowerCase()
            .includes(inputValue.toLocaleLowerCase());
        });

        return filteredOptions;
      },
    [options.length, inputValue]
  );

  return (
    <Box mb={1.5}>
      <Typography className={classes.label}>{label}</Typography>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ gridGap: '1rem' }}
        >
          <Controller
            {...props}
            flex={1}
            name="patient"
            render={({ onChange, onBlur, value, ...controllerProps }) => (
              <>
                <AutoCompleteAddNew
                  style={{ flex: 3 }}
                  errors={errors?.patient}
                  disabled={editMode}
                  noOptionsText="No matching client"
                  data-cy="auto-complete-add"
                  options={options}
                  inputValue={
                    editMode
                      ? `${capitalizedText(value?.firstname)} ${capitalizedText(
                          value?.lastname
                        )}`
                      : inputValue
                  }
                  filterOptions={filterOption}
                  renderOption={(option: any) => (
                    <Box
                      data-cy="auto-complete-add-option"
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar className={classes.avatar} src={option.picture} />
                      <Box ml={2} className={classes.text}>
                        <Typography>
                          {capitalizedText(option.firstname)}{' '}
                          {capitalizedText(option.lastname)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  getOptionSelected={(option: any, value: any) => {
                    return option?._id === value?._id;
                  }}
                  onInputChange={handleInputChange}
                  defaultValue={value}
                  onBlur={onBlur}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  getOptionLabel={(option: any) => value?.name || option?.name}
                  {...controllerProps}
                  {...props}
                />
              </>
            )}
          />
          {hasAddBtn && (
            <Button
              className={classes.addBtn}
              onClick={handleOpenAddPatient}
              buttonType="reset"
              disabled={editMode || !isEnabled}
              style={{ opacity: isEnabled ? 1 : 0.4 }}
            >
              <AddClientIcon style={{ opacity: editMode ? 0.5 : 1 }} />
              <Text i18nKey="addClient">Add client</Text>
            </Button>
          )}
        </Box>
        <Box>
          {errors?.patient && (
            <FormHelperText className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {t(errors.patient?.message)}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </Box>
  );
}
