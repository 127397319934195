export const colors = [
  {
    color: '#175CD3',
    background: '#EFF8FF',
    border: '1px solid #B2DDFF',
  },
  {
    color: '#C11574',
    background: '#FDF2FA',
    border: '1px solid #FCCEEE',
  },
  {
    color: '#067647',
    background: '#ECFDF3',
    border: '1px solid #AAEFC6',
  },
  {
    color: '#B93815',
    background: '#FEF6EE',
    border: '#F9DBAF',
  },
  {
    color: '#5925DC',
    background: '#F4F3FF',
    border: '#D9D6FE',
  },
];
