/*eslint-disable*/
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { log } from 'config/log';
import { IS_LOGGED_IN } from './queries/auth';
import { generateKeyPair } from 'utils/tweetnacl';

export const errorCodes = {
  INACTIVE: 'INACTIVE_USER',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

const randomNumber = Math.random().toString();
const keyPairs = generateKeyPair();

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('auth');
  localStorage.removeItem('connected');
}

const userId = sessionStorage.getItem('meetId') || randomNumber;
const token = localStorage.getItem('token');
log('Env:', process.env.NODE_ENV);
log('[Eggmed Dashboard TEST]');
const webSocketUrl =
  process.env.REACT_APP_SUBSCRIPTION_URL || 'ws://localhost:8000/graphql';
const graphqlEndpoint =
  process.env.REACT_APP_API_URL || 'http://localhost:8000/graphql';

const wsLink = new GraphQLWsLink(
  createClient({
    url: webSocketUrl,
    connectionParams: {
      meetId: userId,
      token,
      publicKey: JSON.stringify(keyPairs.publicKey),
    },
    keepAlive: 30000,
  })
);

// Use BatchHttpLink for queries to reduce network requests
const batchLink = new BatchHttpLink({
  uri: graphqlEndpoint,
});

const uploadLink = createUploadLink({
  uri: graphqlEndpoint,
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (
          extensions?.code === errorCodes.UNAUTHENTICATED ||
          message === 'No Doctor with that id'
        ) {
          logout();
          window.location.href = '/login';
          return;
        }

        if (extensions?.code === errorCodes.INACTIVE) {
          logout();
          window.location.href = '/login?inactif=true';
          return;
        }

        log(
          'error',
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError) {
      log('error', `[Network error]: ${networkError}`);
      // Retry on network errors
      return forward(operation);
    }
  }
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Eggmed: 'eggmed-rules',
      authorization: token ? `Bearer ${token}` : undefined,
      publicKey: JSON.stringify(keyPairs.publicKey),
    },
  };
});

// Enhanced cache configuration
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token: {
          read() {
            return localStorage.getItem('token');
          },
        },
        patients: {
          merge(existing, incoming) {
            return incoming;
          },
          keyArgs: false,
        },
        appointments: {
          merge: false,
          keyArgs: false,
        },
      },
    },
  },
  dataIdFromObject: (object) => {
    // Customize unique identifier generation for cache
    if (object.__typename && object.id) {
      return `${object.__typename}:${object.id}`;
    }
    return null;
  },
});

// Improved link splitting logic
const httpLink = authLink.concat(batchLink);
const uploadHttpLink = authLink.concat(uploadLink as any);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  split(
    ({ query }) => {
      // const definition = getMainDefinition(query);
      return true;
    },
    uploadHttpLink,
    httpLink
  )
);
const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  connectToDevTools: true,
  cache,
  queryDeduplication: true,
});
cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: !!localStorage.getItem('token'),
  },
});
export { cache, keyPairs };
export default client;
