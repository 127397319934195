import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme) => ({
  Bottonscontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '2rem',
    gap: '1rem',
  },
  DescriptionBlock: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
  },
  placeholder: {
    '& .MuiFilledInput-input': {
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
  },
  signText: {
    fontWeight: 600,
    fontSize: '16px',
    color: ({ isFormGenerator }: { isFormGenerator: boolean }) =>
      isFormGenerator ? '#0054B6' : '#667085',
    textDecoration: 'underline',
  },
  signDesc: {
    fontWeight: 400,
    fontSize: '14px',
    color: ({ isFormGenerator }: { isFormGenerator: boolean }) =>
      isFormGenerator ? '#0054B6' : '#667085',
  },
  draftjs: {
    maxHeight: '330px',
    height: '330px',
  },
  source: {
    fontStyle: 'Italic',
    fontSize: '18px',
    color: '#344054',
  },
  description: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#344054',
  },
  selectInput: {
    height: '40px',
    '& .MuiFilledInput-root': {
      height: '40px',
      overflow: 'hidden',
    },
  },
  dateInput: {
    width: '50%',
    height: '40px',
    '& .MuiFilledInput-root': {
      height: '40px',
      overflow: 'hidden',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
  },
  icon: {
    backgroundColor: 'inherit',
    color: '#98A2B3',
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
      color: '#98A2B3',
      transition: 'none',
    },
  },
  selectWidth: {
    width: '30%',
    [theme.breakpoints.only('md')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  select: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  question: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  options: {
    // width: '100%',
    gap: '0.3rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  AddOption: {
    height: '40px',
    maxHeight: '40px',
    backgroundColor: 'rgba(31, 97, 220, 0.06)',
    border: '1px solid #1F61DC',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '1rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    zIndex: 99,
  },
  AddOptionDisabled: {
    height: '40px',
    maxHeight: '40px',
    backgroundColor: 'rgba(31, 31, 31, 0.06)',
    border: '1px solid grey',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '1rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    zIndex: 99,
  },
  notesTopSection: {
    backgroundColor: '#F2F2F2',
    width: '100%',
    paddingInline: '9rem',
    paddingBlock: '14px',
    maxHeight: '66px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('md')]: {
      paddingInline: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingInline: '2rem',
    },
  },
  topSection: {
    // position: 'absolute',
    backgroundColor: '#F5F5F5',
    width: '100%',
    // top: '0',
    // left: '0',
    paddingInline: '9rem',
    paddingBlock: '14px',
    maxHeight: '66px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.only('md')]: {
      paddingInline: 'calc(2vw + 4rem)',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      paddingInline: 'calc(2vw + 3rem)',
    },
  },
  title: {
    color: '#1F61DC',
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  notesTitle: {
    color: '#273142',
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  root: {
    paddingTop: '0.1rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '7rem',
    },
  },
  emailRoot: {
    paddingTop: '0.8rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    [theme.breakpoints.down('sm')]: {
      marginTop: '7rem',
    },
  },
  backText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 700,
    color: 'rgba(45, 199, 109, 1)',
    marginLeft: '8px',
  },
  padding: {
    // paddingInline: '2rem',
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      paddingRight: '2rem',
      paddingLeft: '0',
      marginTop: '1rem',
    },
  },
  previewPadding: {
    paddingInline: '9rem',
    boxSizing: 'border-box',
    [theme.breakpoints.only('md')]: {
      paddingInline: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingInline: '3rem',
    },
  },
  previewButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    minWidth: '80px',
    backgroundColor: 'rgba(239, 246, 255, 1)',
    border: '1px solid rgba(57, 195, 141, 1)',
    paddingInline: '1rem',
    '&:hover': {
      backgroundColor: 'rgba(200, 216, 215, 0.8)',
    },
  },
  cancelButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    minWidth: '80px',
    padding: '10px 14px',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  cancelText: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#344054',
    textTransform: 'none',
  },
  assignText: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
  },
  previewText: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#2F80ED',
    textTransform: 'capitalize',
  },
  assignbutton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    minWidth: '80px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    padding: '10px 14px',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    padding: '10px 14px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },

  FormDesctiption: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '1.3rem',
    boxSizing: 'border-box',
    width: '100%',
    paddingBlock: '1.2rem',
    minHeight: '100px',
    borderRadius: '16px',
    border: '1px solid #D0D5DD',
    marginBottom: '2rem',
    gap: '1.5rem',
  },
  PreviewCardBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: ({ editMode }: { editMode: boolean }) =>
      editMode ? '1.3rem' : '1.1rem',
    boxSizing: 'border-box',
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
    minHeight: '100px',
    borderRadius: '16px',
    position: 'relative',
  },
  notesPreviewCardBox: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    paddingBlock: '1.2rem',
    gap: '1.5rem',
  },
  CardBox: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    padding: '1.25rem',
    minHeight: '100px',
    borderRadius: '8px',
    border: '1px solid #147AF3',
    gap: '1.5rem',
  },
  TextFiled: {
    width: '100%',
    borderRadius: '5px',
    backgroundColor: '#f8fafb',
    border: '1px solid #a1b0c4',
    '&:focus': {
      border: '1px solid #3f50b5',
    },
  },
  answers: {
    width: '98%',
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    '&:focus': {
      border: '1px solid #D0D5DD',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
  },
  link: {
    color: '#147AF3 !important',
    cursor: 'pointer',
    fontSize: '16px',
    minWidth: '80px',
    textAlign: 'right',
    fontFamily: 'Inter',
    fontWeight: 500,
    textDecoration: 'underline',
    '& .MuiInputBase-input': {
      color: '#147AF3 !important',
      cursor: 'pointer',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },

    '& .Mui-focused': {
      border: 'none !important',
      boxShadow: 'none !important',
    },
  },
  questionAnswer: {
    width: '78%',
    borderRadius: '8px',
    padding: '8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    '&:focus': {
      border: '1px solid #D0D5DD',
    },
  },
  notchedOutline: {
    border: 'none !important',
  },
  resize: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(35),
    letterSpacing: theme.typography.pxToRem(0.23),
    PaddingBlock: theme.typography.pxToRem(10),
  },
  iconBtn: {
    backgroundColor: '#F8FAFB',
    width: '55px',
    height: 'calc(100% - 2rem)',
    borderRadius: '5px',
    border: '2px solid #39C38D',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.14)',
    '&:hover': {
      backgroundColor: '#F3F8FA',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40px',
    },
  },
  InputLabel: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: theme.typography.pxToRem(21),
    marginRight: '0.5rem',
  },
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    padding: '16px',
    height: '30px',
    marginTop: '1.2rem',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  pointer: {
    cursor: 'pointer',
    borderRadius: '50px',

    height: theme.typography.pxToRem(32),
    width: theme.typography.pxToRem(32),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E9F5FF',
    },
    '&:active': {
      backgroundColor: '#CAE8FF',
    },
  },
  requiredText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  PreviewTitle: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '35px',
    color: 'rgba(39, 49, 66, 1)',
  },
  PreviewDesc: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '17px',
    color: 'rgba(66, 83, 100, 1)',
    letterSpacing: '0.23px',
    marginTop: '-0.5rem',
  },
  QuestionTitle: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.23px',
    marginLeft: ({ noMargin }: { noMargin: boolean }) => !noMargin && '5px',
    color: '#344054',
  },
  QuestionBlock: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#4F4F4F',
  },
  notesQuestionTitle: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    marginLeft: '5px',
  },
  optionsPreview: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#4F4F4F',
  },
  linearPreview: {
    fontSize: '16px',
    lineHeight: '19.36px',
    fontWeight: 500,
    letterSpacing: '0.234px',
    textAlign: 'left',
    marginTop: '25px',
  },
  saveRadio: {
    color: 'var(--2sd-Blue, #425364)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  savetitle: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21px',
    color: 'var(--2sd-Blue, #425364)',
    marginTop: '2.2rem',
    marginBottom: '1.5rem',
  },
  saveModal: {
    [theme.breakpoints.only('xl')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '45vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '60vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
    },
  },
  radio: {
    '&$checked': {
      color: '#2DC76D',
    },
  },
  checked: {},
  modalBox: {
    width: '40vw',
    paddingInline: '2rem',
    paddingBlock: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  modalTitle: {},
  Response: {
    color: '#425364',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.234px',
  },
  addressWrapper: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '8px',
  },
  titleStyle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#344054',
    marginBottom: '0.5rem',
  },
  wrapperBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  wrapperBoxCountry: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
  lowerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
  },
  sideBotton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.typography.pxToRem(23),
    height: '5rem',
    padding: '20px 10px',
    marginLeft: '0.8rem',
    gap: '20px',
    borderRadius: '100px',
    borderWidth: '1px solid #D0D5DD',
    boxShadow: '0px 2px 4px 2px #1018280F, 0px 4px 8px 2px #1018281A',
  },
}));
