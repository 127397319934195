import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  Button,
  Typography,
  Tooltip,
  Badge,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import useConditions from 'graphql/operations/patientOperations/useConditions';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import 'react-circular-progressbar/dist/styles.css';
import Skeleton from 'components/ui/Skeleton';
import BasicModal from 'components/ui/Modal';
import {
  DIAGNOSIS_CODES,
  diagnosisSchema,
  fieldsSchema,
} from 'pages/PatientConditions';
import { ReactComponent as MsgBlueIcon } from 'assets/MsgBlueIcon.svg';
import { ReactComponent as CalendarBlueIcon } from 'assets/CalendarBlueIcon.svg';
import Modals from 'pages/InboxPage/modals/index';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import ConditionModal from 'pages/PatientConditions/ConditionModal';
import { yupResolver } from '@hookform/resolvers';
import { useSnackbar } from 'hooks/useSnackbar';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import TagsCell from 'pages/AdminPage/TabsContent/templatesTabs/TagsCell';
import { colors } from 'pages/PatientListPage/colors';
import useAddCheckPermission from 'contexts/AddPermissionOntext';

const useStyles = makeStyles((theme) => ({
  large: {
    [theme.breakpoints.only('xl')]: {
      width: '85px',
      height: '85px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '85px',
      height: '85px',
    },
    [theme.breakpoints.only('md')]: {
      width: '85px',
      height: '85px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '68px',
      height: '68px',
    },
    [theme.breakpoints.only('xs')]: {
      width: '68px',
      height: '68px',
    },
  },
  conditions: {
    borderRadius: '16px',
    border: '1px solid #FECDCA',
    backgroundColor: '#FEF3F2',
    padding: '5px 15px',
  },
  conditionText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    color: '#B42318',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '12px',
    color: '#425364',
    fontWeight: 500,
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
      marginTop: theme.spacing(-0.5),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem',
      marginTop: theme.spacing(-0.5),
    },
  },
  fullname: {
    color: '#101828',
    fontWeight: 700,
    fontSize: '22px',
    marginLeft: '6px',
  },
  boxContainer: {
    display: 'flex',
    height: '74px',
    alignItems: 'center',
    // boxSizing: 'border-box',
    // paddingTop: '1rem',
    // paddingBottom: '1rem',
    justifyContent: 'space-between',
    width: '100%',
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    border: '1px solid #147AF3',
    borderRadius: '8px',
    padding: '8px 26px',
    color: '#147AF3',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'none',
  },
  margin: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  },
  phoneIcon: {
    color: '#2DC76D',
    fontSize: '20px',
    marginLeft: '-1px',
  },
  ScrollContainer: {
    overflowX: 'hidden',
    paddingBlock: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: '70vh',
    },
  },
  conditionstModal: {
    width: '50vw',
    [theme.breakpoints.up('lg')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '37vw',
    },
  },
}));
interface IPatientAvatarAndInfoProps {
  patientId: string;
  loading: boolean;
  editable: boolean;
  picture: string;
  firstname: string;
  lastname: string;
  middlename?: string;
  email: string;
  phone: string;
  gender: string;
  age: string | number;
  completed: string[];
  patient?: any;
}
export default function PatientAvatarAndInfo({
  patientId,
  loading,
  editable,
  picture,
  firstname,
  lastname,
  middlename,
  email,
  patient,
}: IPatientAvatarAndInfoProps): ReactElement {
  const { isEnabled } = useAddCheckPermission('Schedule');
  const { triggerSnack } = useSnackbar();
  const [fetchDiagnosis, { data: diagnosisCode }] =
    useLazyQuery(DIAGNOSIS_CODES);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      fetchDiagnosis();
    }
  }, [open, fetchDiagnosis]);

  const classes = useStyles();
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      isDisplayed: false,
      isPrimary: false,
      type: true,
    },
    resolver: yupResolver(diagnosisSchema),
  });
  async function onSubmit(data) {
    const { treatment, description, ...rest } = data || {};

    await handleAddCondition(
      {
        ...rest,
        type: rest?.type ? 'Current' : 'Past',
        diagnosisCode: {
          code: rest?.diagnosisCode?.code,
          description: data?.description,
        },
        dateEnded: rest?.type ? '' : rest?.dateEnded,
      },
      data?.treatment
    );

    closeEditModal();
    triggerSnack(`Diagnosis added successfully `);
  }
  const avatarPicture = picture?.split('/')[3];
  const {
    handleAddCondition,
    data: patientConditionsData,
    loadingAddMutation,
  } = useConditions({ patientId });

  function closeEditModal() {
    setOpen(false);
  }

  const { handleOpenAddModal, setPatientAdded } = useProvider();
  const { handleOpenCreateNewThreadModal } = useInobx();

  return (
    <Box className={classes.boxContainer} py={2}>
      <Box display="flex" alignItems="center">
        <Skeleton width={85} height={85} type="circle" loading={loading}>
          <Box position="relative">
            {editable && (
              <Button
                onClick={() => {}}
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  zIndex: 500,
                }}
              >
                <EditIcon />
              </Button>
            )}
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              // badgeContent={<VerifiedIcon style={{ marginTop: '-15px' }} />}
            >
              <Avatar
                data-cy="patient-avatar"
                src={avatarPicture !== 'undefined' ? picture : ''}
                className={classes.large}
                alt="Patient picture"
              >
                <Typography variant="h3">
                  <Skeleton loading={loading}>{`${
                    firstname && firstname[0].toUpperCase()
                  }${lastname && lastname[0].toUpperCase()}`}</Skeleton>
                </Typography>
              </Avatar>
            </Badge>
          </Box>
        </Skeleton>
        <Box ml="10px" display="flex" flexDirection="column">
          <Skeleton loading={loading}>
            <span
              data-cy="patient-fullname"
              className={classes.fullname}
              style={{ textTransform: 'capitalize' }}
            >{`${firstname} ${middlename || ''} ${lastname}`}</span>
          </Skeleton>
          <Skeleton loading={loading}>
            <Box display="flex" alignItems="center" mt={1}>
              <TagsCell
                tags={patientConditionsData?.conditions
                  ?.filter((el) => el?.isDisplayed)
                  ?.map((cond, index) => ({
                    _id: cond?.diagnosisCode?.code,
                    color: colors[index]?.color,
                    backgroundColor: colors[index]?.background,
                    border: colors[index]?.border,
                    tag: cond?.diagnosisCode?.description,
                  }))}
                editMode={false}
                setEditMode={(value) => {}}
                isSelected={false}
                diagnosis={true}
                profile={true}
                templateId={''}
              />

              <Box
                display="flex"
                alignItems="center"
                onClick={() => setOpen(true)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  border: '1px solid #78BBFA',
                  borderRadius: '16px',
                  alignItems: 'center',
                  height: '24px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <Text
                  i18nKey="Add"
                  style={{
                    color: '#0054B6',
                    fontSize: '12px',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    height: 'auto',
                    alignItem: 'center',
                    fontFamily: 'Inter, sans-serif',
                    width: '100%',
                  }}
                >
                  <PlusIcon style={{ width: '15px', height: '15px' }} />
                  Add{' '}
                  {patientConditionsData?.conditions?.length == 0 &&
                    'diagnosis'}
                </Text>
              </Box>
            </Box>
          </Skeleton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
        <Button
          className={classes.btn}
          onClick={() => handleOpenCreateNewThreadModal()}
        >
          <MsgBlueIcon style={{ marginRight: '5px' }} />
          <Text
            style={{
              color: '#147AF3',
              fontWeight: 600,
              fontFamily: 'Inter, sans-serif',
              fontSize: '16px',
              textTransform: 'none',
            }}
            i18nKey="newMessage"
          >
            New message
          </Text>
        </Button>
        <Button
          className={classes.btn}
          style={{ opacity: isEnabled ? 1 : 0.4 }}
          onClick={() => {
            if (isEnabled) {
              setPatientAdded(patient?.patient);
              handleOpenAddModal();
            }
          }}
          disabled={!isEnabled}
        >
          <CalendarBlueIcon style={{ marginRight: '5px' }} />
          <Text
            style={{
              color: '#147AF3',
              fontWeight: 600,
              fontFamily: 'Inter, sans-serif',
              fontSize: '16px',
              textTransform: 'none',
            }}
            i18nKey="bookSession"
          >
            Book session
          </Text>
        </Button>
      </Box>
      <FormProvider {...methods}>
        <BasicModal
          isSlide
          open={open}
          onClose={closeEditModal}
          isFromModal
          title={t('Add a diagnosis')}
          titlePadding="2rem"
          handleClose={closeEditModal}
          className={classes.conditionstModal}
          onSubmit={onSubmit}
          loading={loadingAddMutation}
        >
          <ConditionModal diagnosisCode={diagnosisCode?.diagnosisCodes} />
        </BasicModal>
      </FormProvider>
      <Modals
        contact={[
          { firstname, lastname, middlename, _id: patientId, email, picture },
        ]}
        subject=""
      />
    </Box>
  );
}
