import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '1rem',
  },
  TemplateSection: {
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flexGrow: 1,
    },
  },
  tab: {
    marginBottom: '16px',
  },
  headerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  myFormSection: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionTitle: {
    color: '#0054B6',
    fontSize: ' 18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    fontFamily: 'Inter',
  },
  myTelmplateSection: {
    flexGrow: 1,
    flex: '1 1 0px',
    [theme.breakpoints.down('sm')]: {},
  },
  templateCard: {
    flexGrow: 1,
    width: '100%',
    height: '35px',
    padding: '4px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    backgroundColor: '#F3F3F3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '10px',
  },
  formCard: {
    // flex: 'calc(50% - 5px)',
    // maxWidth: 'calc(50% - 5px)',
    height: '40px',
    padding: '4px 8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    backgroundColor: 'white',
    border: '1px solid #EAECF0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flex: '1',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
  useButton: {
    padding: '0',
    color: '#2F80ED',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#transparent',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBlock: theme.typography.pxToRem(50),
  },
  iconBox: {
    width: theme.typography.pxToRem(195),
    height: theme.typography.pxToRem(195),
  },
  emptyText: {
    marginTop: theme.typography.pxToRem(10),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: theme.typography.pxToRem(35),
  },
  button: {
    fontStyle: 'inter',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: theme.typography.pxToRem(8),
    minWidth: theme.typography.pxToRem(160),
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(31, 97, 220, 1)',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  formModal: {
    // height: '100%',
    [theme.breakpoints.only('xl')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '70vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  templatesBox: {
    borderLeft: '0.5px solid rgba(164, 164, 164, 1)',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderLeft: 'none',
    },
  },
  myFormsBox: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  formModalBox: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  templateTitle: {
    paddingLeft: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
    },
  },
  ScrollBar: {
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  defaultForms: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.typography.pxToRem(20),
  },
  modalSubTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: 'rgba(39, 49, 66, 1)',
    lineHeight: theme.typography.pxToRem(46),
  },
  formBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    overFlowX: 'scroll',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    cursor: 'pointer',
  },
  personalFormName: {
    color: '#4F4F4F',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  modalFormIcon: {
    width: '170px',
    height: '170px',
  },
  reflectionModal: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingTop: '2rem',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    color: 'rgba(31, 97, 220, 1)',
    lineHeight: theme.typography.pxToRem(46),
  },
  modalTitleForm: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828',
    lineHeight: '28px',
    zIndex: 2,
  },
  activityHeaderBg: {
    position: 'absolute',
    top: '12px',
    left: '12px',
    zIndex: 1,
  },

  guided: {
    position: 'relative',
    padding: '20px',
    border: ({ selected }: { selected?: boolean }) =>
      selected
        ? '2px solid rgba(45, 199, 109, 1)'
        : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '16px 16px 0px 0px',
    cursor: 'pointer',
  },
  guidedA: {
    position: 'relative',
    padding: '20px',
    // border: ({ selected }: { selected?: boolean }) =>
    //   selected
    //     ? '2px solid rgba(45, 199, 109, 1)'
    //     : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '16px 16px 0px 0px',
    cursor: 'pointer',
  },
  free: {
    position: 'relative',
    padding: '20px',
    border: ({ selected }: { selected?: boolean }) =>
      !selected
        ? '2px solid rgba(45, 199, 109, 1)'
        : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '0px 0px 16px 16px',
    cursor: 'pointer',
  },
  freeA: {
    position: 'relative',
    padding: '20px',
    // border: ({ selected }: { selected?: boolean }) =>
    //   !selected
    //     ? '2px solid rgba(45, 199, 109, 1)'
    //     : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '0px 0px 16px 16px',
    cursor: 'pointer',
  },
  reflectionsA: {
    position: 'relative',
    padding: '20px',
    // border: ({ selected }: { selected?: boolean }) =>
    //   !selected
    //     ? '2px solid rgba(45, 199, 109, 1)'
    //     : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '0px 0px 0px 0px',
    cursor: 'pointer',
  },
  guidedTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    color: 'rgba(66, 83, 100, 1)',
  },
  guidedText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: 'rgba(128, 151, 177, 1)',
  },
  backText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 700,
    color: 'rgba(45, 199, 109, 1)',
    marginLeft: '8px',
  },
  nextText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 700,
    color: 'rgba(86, 127, 189, 1)',
    marginInline: '8px',
  },
  scrollBar: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    paddingInline: '2rem',
  },
  scrollBarForm: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  PreviewScrollBar: {
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '2rem',
    height: '100vh',
  },
  labelText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#101828',
    zIndex: 2,
    //marginLeft: '8px',
  },
  Desc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
  },
  inputDate: {
    width: '100%',
  },
  subLabel: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: theme.typography.pxToRem(18),
    whiteSpace: 'nowrap',
  },
  input: {
    minWidth: '95px',
    width: 'fit-content',
    marginLeft: '2rem',
  },
  numberInput: {
    maxWidth: '80px',
  },
  previewBox: {
    boxSizing: 'border-box',
    borderRadius: '20px',
    boxShadow: '0px 0px 13px 0px rgba(161, 176, 196, 0.35)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingInline: '2rem',
    marginTop: '2rem',
    marginBottom: theme.typography.pxToRem(50),
  },
  previewIllustration: {
    marginTop: theme.typography.pxToRem(-130),
  },
  previewQueston: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    color: 'rgba(66, 83, 100, 1)',
    marginLeft: '12px',
  },
  previewInput: {
    width: '100%',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: '20px',
    border: '1px solid rgba(161, 176, 196, 1)',
    '&:focus': {
      border: '1px solid #3f50b5',
    },
  },
  notchedOutline: {
    border: 'none !important',
  },
  previewButtons: {
    marginTop: '2rem',
    gap: '1rem',
  },
  CancelPreview: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    border: '1px solid rgba(215, 227, 241, 1)',
    color: 'rgba(31, 97, 220, 1)',
    textTransform: 'capitalize',
    fontSize: '18px',
    lineHeight: '21px',
    width: '100%',
    fontWeight: 400,
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(215, 227, 241, 1)',
    },
  },
  SavePreview: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 600,
    width: '100%',
    border: '1px solid #1F61DC',
    borderRadius: '25px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  moodBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    paddingBlock: theme.typography.pxToRem(10),
    borderRadius: '10px',
    border: '0.5px solid rgba(164, 164, 164, 1)',
    flex: '30%',
    flexGrow: 1,
    maxWidth: '30%',
    height: theme.typography.pxToRem(80),
    marginTop: '1rem',
  },
  moodText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(18),
    color: 'rgba(66, 83, 100, 1)',
  },
  uploadIcon: {
    fontSize: '30px',
    marginRight: theme.spacing(2),
    backgroundColor: '#E4ECF7',
    padding: theme.spacing(0.5),
    borderRadius: '8px',
  },
  sleepBox: {
    borderRadius: '100%',
    border: 'none',
    zIndex: 10,
  },
  line: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  InputLabel: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: theme.typography.pxToRem(21),
  },
  TextFiled: {
    width: '100%',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: '5px',
    backgroundColor: '#f8fafb',
    '& .MuiOutlinedInput-multiline': {
      border: '0.5px solid #a1b0c4',
    },
    '& .Mui-focused': {
      border: '0.5px solid #082CAF',
    },
    '& .Mui-error': {
      border: '0.5px solid red',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  errorQuestionIcon: {
    fontSize: '1rem',
    marginRight: '5px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  errorBox: {
    position: 'absolute',
    bottom: '-1.5rem',
  },
  done: {
    backgroundColor: '#BEFFD7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#0AAB4D',
  },
  pending: {
    backgroundColor: '#FFD8AD',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#AE6209',
  },
  overdue: {
    backgroundColor: '#FFC6C5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#D71300',
  },
  tableButton: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    border: '0.5px solid rgba(66, 83, 100, 1)',
  },
  viewButton: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    padding: '6px 8px',
    height: '100%',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(66, 83, 100, 1)',
    borderRadius: '5px 0px 0px 5px',
    borderRight: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  downloadButton: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    maxWidth: '27px',
    minWidth: '27px',
    height: '100%',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(66, 83, 100, 1)',
    borderRadius: '0px 5px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewButtonDisabled: {
    backgroundColor: 'rgba(215, 227, 241, 0.5)',
    padding: '6px 8px',
    height: '100%',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(66, 83, 100, 0.5)',
    borderRadius: '5px 0px 0px 5px',
    borderRight: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadButtonDisabled: {
    backgroundColor: 'rgba(215, 227, 241, 0.5)',
    maxWidth: '27px',
    minWidth: '27px',
    height: '100%',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(66, 83, 100, 0.5)',
    borderRadius: '0px 5px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: 'rgba(66, 83, 100, 1)',
    textTransform: 'none',
    marginLeft: theme.typography.pxToRem(5),
    userSelect: 'none',
  },
  buttonTextDisabled: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: 'rgba(66, 83, 100, 0.7)',
    textTransform: 'none',
    marginLeft: theme.typography.pxToRem(5),
    userSelect: 'none',
  },
  menu: {
    width: '10rem',
    borderRadius: '8px',
    padding: '0',
  },
  AssignModal: {
    [theme.breakpoints.only('xl')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '50vw',
    },
  },
  assignText: {
    color: 'var(--2sd-Blue, #425364)',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '162.687%' /* 29.284px */,
    letterSpacing: ' 0.3px',
    textAlign: 'center',
    marginBottom: '25px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '10px',
  },
  cancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '10px 14px',
    boxSizing: 'border-box',
    border: '1px solid #D0D5DD',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#344054',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  assignButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    padding: '10px 14px',
    minWidth: '82px',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
  },
  cancelButton: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    textTransform: 'capitalize',
    border: '1px solid #D0D5DD',
    color: '#344054',
    backgroundColor: 'transparent',
    paddingBlock: '8px',
    minWidth: '80px',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  },
  previewButton: {
    marginRight: theme.spacing(2),
    borderRadius: '5px',
    backgroundColor: 'rgba(239, 246, 255, 1)',
    border: '1px solid rgba(57, 195, 141, 1)',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(57, 195, 141, 1)',
    textTransform: 'capitalize',
    marginLeft: '5px',
    minWidth: '80px',
    '&:hover': {
      backgroundColor: 'rgba(200, 216, 215, 0.8)',
    },
  },
  saveButton: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    border: '1px solid ',
    color: '#FFFFFF',
    backgroundColor: '#0265DC',
    padding: '8px 16px',
    boxShadow: 'unset',
    minWidth: '80px',
    '&:hover': {
      backgroundColor: '#082CAF',
      boxShadow: 'unset',
    },
  },
  paymentTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '0.300349px',
    color: '#425364',
    marginBlock: theme.spacing(1),
  },
  paymentText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '0.300349px',
    color: '#425364',
  },
  btnC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '20px',
    color: '#425364',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  btnSC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '30px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: '#1F61DC',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  resultModalTitle: {
    color: '#1F61DC',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    marginTop: '-1rem',
  },
  resultModalBox: {
    width: '35vw',
  },
  responseDate: {
    color: '#1F61DC',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '192.687%',
  },
  responseQuestion: {
    marginTop: '0.5rem',
    color: '#425364',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.234px',
  },
  response: {
    color: '#425364',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.234px',
  },
  responseDescription: {
    marginTop: '0.5rem',
    color: '#425364',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '192.687%',
  },
  responseHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1.25rem',
    margin: '0 2rem',
  },
  responseHeaderTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '20px',
    color: '#101828',
  },
  responseHeaderDate: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
  },
  responseNameInside: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    color: '#147AF3',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  headerBtns: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '12px',
  },
  downloadBtn: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter, sans-serif',
    backgroundColor: '#147AF3',
    color: '#fff',
    borderRadius: '8px',
    textDecoration: 'none',
    padding: '8px 16px',
    lineHeight: 1.75,
    fontSize: '14px',
    fontWeight: 500,

    '& svg': {
      marginRight: '0.5rem',
    },

    '&:hover': {
      backgroundColor: '#147AF3',
      color: '#fff',
    },
  },
  msgBtn: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter, sans-serif',
    borderRadius: '8px',
    textTransform: 'unset',
    padding: '8px 16px',
    color: '#344054',
    border: '1px solid #D0D5DD',

    '& svg': {
      marginRight: '0.5rem',
    },

    '&:hover': {
      color: '#344054',
    },
  },
  divider: {
    margin: '1rem auto',
  },
  question: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#475467',
    marginBottom: '13px',
  },
  textLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '10px',
  },
  textField: {
    width: '100%',
    '& fieldset': {
      border: '1px solid #D0D5DD !important',
      borderRadius: '8px',
    },
  },
  reflectionImg: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '400px',
    objectFit: 'cover',
  },
  emojiResponse: {
    border: '1px solid #EAECF0',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '13px',
    padding: '4px 10px',
    width: 'fit-content',
  },
  emojiResponseText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  sleepData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
  sleepBedTime: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    backgroundColor: '#F4F3FF',
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '3px 10px',
  },
  sleepWakeupTime: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    backgroundColor: '#FEF6EE',
    border: '1px solid #FEEE95',
    color: '#CA8504',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '3px 10px',
  },
  sleepTotal: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    backgroundColor: '#F8F9FC',
    border: '1px solid #D5D9EB',
    color: '#363F72',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '3px 10px',
  },
  sleepBar: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '20px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  sleepEmoji: {
    opacity: 0.5,
  },
  sleepEmojiSelected: {
    scale: 1.5,

    '& svg': {
      borderRadius: '50%',
      border: '3px solid #98A2B324',
    },
  },
  frequencyResult: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    padding: '16px',
  },
  freqSummary: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
  },
  frequencyContainer: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '12px 15px',
  },

  freqDesc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    color: '#475467',
  },
  freqTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  freqRep: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  freqLabel: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
    marginBottom: '6px',
  },
  cardRoot: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    // height: 'calc(100% - 36px)',
    // maxHeight: '200px',
    minHeight: '240px',
  },
  cardTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  cardTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '16px',
    color: '#101828',
  },
  cardDate: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
  },
  cardQuestion: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '15px',
    color: '#475467',
  },
  cardAnswer: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
    marginTop: '5px',
  },
  moodResultBox: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '20px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgFileBox: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  fileName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
  },
  fileSize: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
  },
  arrows: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
    },
  },
  arrowDisabled: {
    display: 'none',
  },
  pagination: {
    display: 'none',
  },
  tabHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  unreadRed: {
    backgroundColor: '#EB5757',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#fff',
    borderRadius: '3px',
    padding: '0 4px',
  },
  guidedOrFree: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
    border: '1px solid #EAECF0',
    backgroundColor: '#F9FAFB',
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    padding: '2px 8px',
    borderRadius: '16px',
  },
  required: {
    color: 'red',
  },
  buttons: {
    height: '76px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTop: '1px solid #EAECF0',
  },
  buttonsControlled: {
    height: '76px',
  },
  boxHeight: {
    height: 'calc(100vh - 76px)',
  },
  fullHeight: {
    height: 'calc(100vh - 58px)',
  },
  viewBtn: {
    background: 'transparent',
    minWidth: '20px',
    maxWidth: '20px',
    padding: 0,
    position: 'absolute',
    top: '30px',
    right: '20px',

    '&:hover': {
      background: 'transparent',
    },
  },
  tagInside: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    border: '1px solid #D0D5DD',
    borderRadius: '44px',
    padding: '3px 8px',
  },
  customTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#101828',
      color: '#FFFFFF',
      fontFamily: 'Inter, sans-serif',
      fontSize: '12px',
      fontWeight: 400,
      padding: '5px 8px',
      lineHeight: 1.8,
    },
  },
  activeIcon: {
    '& path': {
      stroke: '#0265DC',
    },
  },
  tabForm: {
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
  },
  dots: {
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#101828',
    marginTop: '10px',
    textAlign: 'center',
  },
}));
