import { Box, Divider, Typography, Button } from '@material-ui/core';
import { IQuestion } from 'pages/FormsPage/FormsPage';
import React from 'react';
import useStyles from '../styles';
import QuestionPreview from 'pages/FormsPage/QuestionPreview';
import { QuestionTypeEnum } from 'pages/FormsPage/Constants';
import DropdownIcon from 'assets/patientActivities/dropdown';
import { ReactComponent as ManageIcon } from 'assets/session/file-search-02.svg';
import Menu from 'components/ui/Menu';
import AddNotesModal from './AddNotesModal';
import BasicModal from 'components/ui/Modal';
import AssignModal from 'pages/PatientActivities/Questionaires/AssignModal';
import useEditNotes from '@eggmed/graphql-client/operations/appointmentsOperations/useEditNotes';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import useDeleteDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeleteDoctorTemplate';
import { useTranslation } from 'react-i18next';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import { CustomToolbar } from 'components/Editorjs/Toolbar';
import './editor.css';
import { ReactComponent as SaveIcon } from 'assets/form/toolbar/save.svg';
import clsx from 'clsx';
import { ReactComponent as Tickedicon } from 'assets/TickedIcon.svg';
import SignatureResponse from 'components/Editorjs/Responses/Signature';
import EditButtons from 'components/EditButtons';
import { useSnackbar } from 'hooks/useSnackbar';
import { ReactComponent as SignBtn } from 'assets/SignBtn.svg';
import { ReactComponent as EditNoteicon } from 'assets/EditNoteicon.svg';
import { ReactComponent as UnlockBtn } from 'assets/UnlockBtn.svg';
import ProgressLoader from 'components/ui/ProgressLoader';
import Skeleton from 'components/ui/Skeleton';
import FormPreview from 'pages/FormsPage/FormPreview';

export interface Itemplate {
  _id?: string;
  title: string;
  questions: IQuestion[];
  isDefault?: boolean;
  blocks: string;
  isSigned: boolean;
  isSaved: boolean;
}

function NotesTab({
  patientId,
  appointmentId,
  doctorTemplates,
  notes,
  loadingQuery,
}: {
  patientId: string;
  appointmentId: string;
  doctorTemplates: Itemplate[];
  notes: Itemplate;
  loadingQuery?: boolean;
}) {
  const blockstype = [
    'Address',
    'Phone',
    'Email',
    'Long_answer',
    'Short_answer',
    'DateRange',
    'Date',
    'DropDown',
    'LineaireScale',
    'Single_choice',
    'Multiple_choice',
    'Color',
    'table',
    'list',
    'Orderedlist',
    'checklist',
    'paragraph',
    'header',
    'Image',
    'Link',
    'HorizantaleLine',
  ];
  const classes = useStyles({});
  const { toggleSignature } = useEditorContext();
  const [currentTemplate, setCurrentTemplate] = React.useState<Itemplate>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef(null);
  const [dropdownWidth, setDropdownWidth] = React.useState<number>();
  const [editMode, setEditMode] = React.useState(false);
  React.useEffect(() => {
    if (anchorRef.current) {
      setDropdownWidth(anchorRef.current.offsetWidth + 100);
    }
  }, [anchorEl]);
  const [open, setOpen] = React.useState(false);
  const [templates, setTemplates] = React.useState<Itemplate[]>([]);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [formToAssign, setFormToAssign] = React.useState<Itemplate>(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { t } = useTranslation();
  const [notesTitle, setNotesTitle] = React.useState<string>(t('Notes'));
  const [handleEditNotes, { loading: loadingMutation }] = useEditNotes(
    patientId,
    appointmentId
  );
  React.useEffect(() => {
    if (notes && notes._id && notes?.title !== currentTemplate?.title) {
      const questions = currentTemplate?.questions?.map((question) => {
        const { _id, __typename, ...rest } = question || {};
        return rest;
      });
      handleEditNotes({
        variables: {
          notesId: notes?._id,
          notesInput: {
            title: currentTemplate?.title,
            questions,
            blocks: currentTemplate?.blocks,
          },
        },
      });
    }
  }, [currentTemplate]);
  React.useEffect(() => {
    setTemplates(doctorTemplates);
  }, [doctorTemplates]);
  const menuElems =
    templates &&
    templates.map((template) => {
      return {
        name: template.title,
        onClick: () => {
          setFormToAssign(template);
          setAnchorEl(null);
          setOpenAssign(true);
        },
      };
    });
  React.useEffect(() => {
    if (notes) {
      setCurrentTemplate(notes);
      const parseBlocks = JSON.parse(notes?.blocks) || [];
      const filteredBlocks = parseBlocks?.filter((el) => {
        return blockstype.includes(el?.type);
      });
      const stringifiedBlocks = JSON.stringify(filteredBlocks);
      setBlocks(stringifiedBlocks, false, null, true);
    }
  }, [notes]);

  const handleInputChange = (questionIndex: number, value: string) => {
    setCurrentTemplate((prevQuestions) => {
      const updatedQuestions = [...prevQuestions.questions];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        responses: [value],
      };
      return { ...prevQuestions, questions: updatedQuestions };
    });
  };

  const handleCheckboxChange = (questionIndex, option) => {
    setCurrentTemplate((prevQuestions) => {
      const updatedQuestions = [...prevQuestions.questions];
      const currentResponses = updatedQuestions[questionIndex].responses || [];

      const updatedQuestion = {
        ...updatedQuestions[questionIndex],
        responses: currentResponses.includes(option)
          ? currentResponses.filter((item) => item !== option)
          : [...currentResponses, option],
      };

      updatedQuestions[questionIndex] = updatedQuestion;

      return { ...prevQuestions, questions: updatedQuestions };
    });
  };
  const handleAddress = (
    questionIndex: number,
    value: string,
    field: string
  ) => {
    setCurrentTemplate((prevQuestions) => {
      const updatedQuestions = [...prevQuestions.questions];
      const currentResponses = updatedQuestions[questionIndex].responses || [];

      const updatedResponses = {
        ...currentResponses,
        address: {
          ...currentResponses.address,
          [field]: value,
        },
      };
      const updatedQuestion = {
        ...updatedQuestions[questionIndex],

        responses: updatedResponses,
      };
      updatedQuestions[questionIndex] = updatedQuestion;
      return { ...prevQuestions, blocks: JSON.stringify(updatedQuestions) };
    });
  };
  const [handleDeleteDoctorTemplate] = useDeleteDoctorTemplate();
  const handleDelete = async () => {
    await handleDeleteDoctorTemplate({
      variables: { id: formToAssign._id },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setOpenDeleteModal(false);
  };
  const handleDeleteModal = (form: Itemplate) => {
    setOpenDeleteModal(true);
    setFormToAssign(form);
  };

  const { initEditorSession, blocks, editorRef, clearBlock, setBlocks } =
    useEditorContext();
  const ref = React.useRef<any>();
  React.useEffect(() => {
    if (!ref.current) {
      initEditorSession();
    }

    return () => {
      if (ref.current) {
        ref.current.destroy();
        ref.current = null;
      }
    };
  }, [blocks, editMode]);
  const [block, setCurrentBlock] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const { triggerSnack } = useSnackbar();
  async function handleSign() {
    await handleEditNotes({
      variables: {
        notesId: notes?._id,
        notesInput: {
          isSigned: true,
        },
      },
    });
    setOpenModal(false);
    triggerSnack('Notes signed successfully');
  }
  async function handleSave() {
    const { blocks } = await editorRef.current.save();

    await handleEditNotes({
      variables: {
        notesId: notes?._id,
        notesInput: {
          blocks: JSON.stringify(blocks),
          isSaved: true,
        },
      },
    });
    setCurrentTemplate((prev) => {
      return { ...prev, blocks: JSON.stringify(blocks) };
    });
    triggerSnack('Notes saved successfully');
    clearBlock();
    console.log('from handle save', blocks);
  }
  const questions =
    currentTemplate?.blocks &&
    JSON.parse(currentTemplate?.blocks)?.map((el) => {
      if (el?.data?.questions?.length > 0) {
        return { ...el?.data?.questions[0] };
      } else {
        if (el.type === 'paragraph') {
          return {
            question: el?.data?.text,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el.type === 'header') {
          return {
            question: el?.data?.text,
            range: [1, 2],
            type: el?.type,
            level: el?.data?.level,
          };
        }
        if (el?.type === 'table') {
          return {
            question: el?.data?.content,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'list') {
          return {
            question: el?.data?.items,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Image') {
          return {
            question: el?.data?.imageUrl || el?.data?.imageFile,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Orderedlist') {
          return {
            question: el?.data?.items,
            range: [1, 2],
            type: el?.type,
          };
        }
        if (el?.type === 'Link') {
          return {
            question: el?.data?.link,
            range: [1, 2],
            type: el?.type,
          };
        }
      }
    });
  return (
    <Box width="100%" mb="2rem">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Skeleton loading={loadingQuery}>
          <div ref={anchorRef}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ cursor: 'pointer', opacity: notes?.isSigned ? 0.5 : 1 }}
              onClick={(e) => {
                if (!notes?.isSigned) {
                  setAnchorEl(e.currentTarget);
                }
              }}
              className={classes.dropdown}
            >
              <Typography className={classes.notesTitle}>
                {currentTemplate?.title}
              </Typography>
              <DropdownIcon width="17" height="9" />
            </Box>
          </div>
        </Skeleton>
        {editMode ? (
          <Skeleton loading={loadingQuery}>
            <Button
              className={clsx(classes.button, classes.saveBtn)}
              data-cy="submit"
              type="submit"
              onClick={async () => {
                await handleSave();
                setEditMode(false);
              }}
            >
              {loadingMutation ? (
                <ProgressLoader width={20} height={20} />
              ) : (
                <>
                  <SaveIcon className={classes.btnIcon} />
                  Save
                </>
              )}
            </Button>
          </Skeleton>
        ) : (
          <Skeleton loading={loadingQuery}>
            {!notes?.isSaved && !notes?.isSigned && (
              <Button
                className={clsx(classes.button, classes.saveBtn)}
                data-cy="submit"
                type="submit"
                onClick={handleSave}
              >
                {loadingMutation ? (
                  <ProgressLoader width={20} height={20} />
                ) : (
                  <>
                    <SaveIcon className={classes.btnIcon} />
                    Save
                  </>
                )}
              </Button>
            )}
            {notes?.isSaved && !notes?.isSigned && (
              <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
                <Button
                  className={clsx(classes.button, classes.editBtn)}
                  data-cy="submit"
                  type="submit"
                  onClick={() => setEditMode(true)}
                >
                  <EditNoteicon className={classes.btnIcon} />
                  Edit
                </Button>
                <Button
                  className={clsx(classes.button, classes.saveBtn)}
                  data-cy="submit"
                  type="submit"
                  onClick={() => setOpenModal(true)}
                >
                  <SignBtn className={classes.btnIcon} />
                  Sign
                </Button>
              </Box>
            )}
            {notes?.isSaved && notes?.isSigned && (
              <Button
                className={clsx(classes.button, classes.editBtn)}
                data-cy="submit"
                type="submit"
                onClick={() => setEditMode(true)}
              >
                <UnlockBtn className={classes.btnIcon} />
                Unlock
              </Button>
            )}
          </Skeleton>
        )}
        {/* <Button className={classes.manageButton} onClick={() => setOpen(true)}>
          <ManageIcon style={{ marginRight: '5px' }} />
          <Typography>Templates</Typography>
        </Button> */}
      </Box>
      <Box mt={2}>
        <Skeleton loading={loadingQuery} width="100%">
          {!notes?.isSaved || (!notes?.isSigned && <CustomToolbar isSession />)}
        </Skeleton>
        <Skeleton loading={loadingQuery} width={'100%'}>
          {notes?.isSaved && !editMode ? (
            <FormPreview
              questions={questions}
              handleBack={() => {}}
              title={currentTemplate?.title}
              source={null}
              isSigned={null}
              patientId={patientId}
              description={null}
              viewMode={false}
              editMode={false}
              isView={true}
            />
          ) : (
            <div
              id="editorSession"
              style={{
                flex: 8,
                backgroundColor: 'white',
                borderRadius: '16px',
                marginRight: '1rem',
                marginBottom: '1rem',
                paddingBlock: '2rem',
                minHeight: '400px',
              }}
            />
          )}
        </Skeleton>
      </Box>
      {/* {currentTemplate?.questions?.map((question, index) => (
        <Box
          mt="1rem"
          style={{
            background: 'white',
            border: '1px solid #D0D5DD',
            borderRadius: '16px',
          }}
        >
          <QuestionPreview
            question={question}
            isNotes
            ignoreRequired
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            handleAddress={handleAddress}
            index={index}
            editMode={true}
            noMargin
          />
        </Box>
      ))} */}
      <BasicModal
        isRegularModal
        isSlide
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        title={notesTitle}
        divider
      >
        <AddNotesModal
          setNotesTitle={setNotesTitle}
          handleClose={() => setOpen(false)}
          openDeleteModal={handleDeleteModal}
          templates={templates}
          patientId={patientId}
          appointmentId={appointmentId}
          setFormToAssign={setFormToAssign}
          setOpenAssign={setOpenAssign}
        />
      </BasicModal>
      <ModalDelete
        text="Template"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
      <BasicModal
        isRegularModal
        open={openAssign}
        onClose={() => setOpenAssign(false)}
        handleClose={() => setOpenAssign(false)}
      >
        <AssignModal
          text="Are you sure you want to change template? all progress will be lost"
          handleSubmit={() => {
            setCurrentTemplate(formToAssign);
            if (!notes?.isSaved) {
              clearBlock();
            }
            setOpenAssign(false);
          }}
          handleClose={() => setOpenAssign(false)}
        />
      </BasicModal>
      <BasicModal
        isRegularModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          toggleSignature(false);
        }}
        handleClose={() => {
          setOpenModal(false);
          toggleSignature(false);
        }}
      >
        <Box px={2} pb={4}>
          <Box display="flex">
            <Tickedicon />
            <Box ml={2}>
              <Typography className={classes.signTitle}>
                Sign and lock your notes
              </Typography>
              <Typography className={classes.signDesc}>
                Sign your notes after completing your updates for this session.
              </Typography>
              <Typography className={classes.signDesc}>
                {' '}
                Once signed, the notes will be locked and will require unlocking
                to
              </Typography>
              <Typography className={classes.signDesc}>edit again.</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            <SignatureResponse />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <EditButtons
              editable
              submitText="Sign notes"
              handleOpen={() => {
                setOpenModal(false);
              }}
              loading={loadingMutation}
              handleEdit={handleSign}
              px="0rem"
            />
          </Box>
        </Box>
      </BasicModal>
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        width={dropdownWidth}
        style={{ borderRadius: '50px' }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={
          menuElems?.length > 0
            ? menuElems
            : [{ name: 'No notes', isDisabled: true }]
        }
      />
    </Box>
  );
}

export default NotesTab;
