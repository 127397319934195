import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';
import { ReactComponent as RadioCheckedIcon } from 'assets/Schedule/new/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/Schedule/new/radio-unchecked.svg';
import Inputs from 'components/ui/Inputs';
import { FormInput } from 'components/ui/Inputs/FormInput';
import DatePicker from 'react-datepicker';
import { IconButton } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import { Select } from 'components/ui/Inputs/CustomDate';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { useTranslation } from 'react-i18next';
import range from 'lodash.range';

interface IFormRadio {
  register: any;
  control: any;
  question: string;
  name: string;
  handleInputChange: any;
  questionIndex: number;
  props?: any;
  responses?: string;
}

export const DateForm = ({
  name,
  register,
  control,
  question,
  handleInputChange,
  questionIndex,
  responses,
  ...props
}: IFormRadio) => {
  const classes = useStyles();
  const handleChange = (value) => {
    handleInputChange(questionIndex, value);
  };

  const years = range(1890, getYear(new Date()) + 2, 1);
  const { t } = useTranslation();
  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];
  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <Controller
        defaultValue={responses ? new Date(responses) : new Date()}
        control={control}
        name={name}
        render={({ onChange, onBlur, value }) => (
          <div className={classes.datePicker}>
            <DatePicker
              data-cy="date-input"
              // showTimeSelect
              customInput={
                <FormInput
                  fullWidth
                  data-cy="date-input"
                  style={{ width: '100%' }}
                  variant="filled"
                />
              }
              popperProps={{
                positionFixed: true,
              }}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
              name={name}
              onBlur={onBlur}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }: any) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    className={classes.nextButton}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {'<'}
                  </IconButton>

                  <Select
                    options={years}
                    handleChange={({ target: { value } }: any) =>
                      changeYear(value)
                    }
                    value={getYear(date)}
                  />
                  <Select
                    options={months}
                    handleChange={({ target: { value } }: any) =>
                      changeMonth(months.indexOf(value))
                    }
                    value={months[getMonth(date)]}
                  />
                  <IconButton
                    className={classes.nextButton}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {'>'}
                  </IconButton>
                </div>
              )}
              selected={value ? new Date(value) : null}
              onChange={(date) => {
                onChange(date);
                handleChange(date);
              }}
              {...props}
            />
          </div>
        )}
      />
    </Box>
  );
};
