import React, { forwardRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';
import Switch from 'components/ui/Switch';
import { useStyles } from './styles';
import { ReactComponent as CopyIcon } from 'assets/patientActivities/copy.svg';
import TrashIcon from 'assets/TrashIcon';
import Input from 'components/ui/Inputs';
import Options from './Options';
import { IQuestion } from './FormsPage';
import { NotesTypes, Types } from './Constants';
import { QuestionTypeEnum } from './Constants';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import IconSelect from './inputs/IconSelect';
import { useTranslation } from 'react-i18next';
import Email from 'assets/form/blocks/Email';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';

import Address from './Address';

import { ReactComponent as Signature } from 'assets/Signature.svg';
import clsx from 'clsx';
interface IQuestionCardProps {
  question: IQuestion;
  index: number;
  updateQuestion: (index: number, question: IQuestion) => void;
  removeQuestion: (index: number) => void;
  setFocusedQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  isNotes?: boolean;
  changeQuestion?: any;
  deleteBlock: (index: number) => void;
  getBlockIndex: () => number;
  addNewQuestion?: (type: string, question: string) => void;
  handleClick?: () => void;
  setOpenDP?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
}

const QuestionCard = forwardRef<HTMLDivElement, IQuestionCardProps>(
  (
    {
      question,
      index,
      updateQuestion,
      removeQuestion,
      setFocusedQuestionIndex,
      isNotes,
      changeQuestion,
      deleteBlock,
      getBlockIndex,
      handleClick,
      addNewQuestion,
      setOpenDP,
      handleClose,
    },
    ref
  ): React.ReactElement => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const [selectedValue, setSelectedValue] = React.useState<string>(
      question?.type || ''
    );

    const handleType = (type) => {
      updateQuestion(index, { ...question, type });
    };
    const handleQuestion = (newQuestion) => {
      updateQuestion(index, { ...question, question: newQuestion });
      setOpenDP(true);
    };
    const handleDescription = (newDescription) => {
      updateQuestion(index, { ...question, description: newDescription });
      setOpenDP(true);
    };
    const handleOptions = (updatedOptions) => {
      updateQuestion(index, { ...question, options: updatedOptions });
      setOpenDP(true);
    };
    const handleAddOption = () => {
      if (question.options.length >= 50) return;
      const updatedOptions = [
        ...question.options,
        `Option ${question.options.length + 1}`,
      ];
      handleOptions(updatedOptions);
      setOpenDP(true);
    };
    const handleRemoveOption = (optionIndex) => {
      const updatedOptions = question.options.filter(
        (_, index) => index !== optionIndex
      );
      if (updatedOptions.length > 0) {
        handleOptions(updatedOptions);
      }
      setOpenDP(true);
    };
    const handleMinRange = (min) => {
      updateQuestion(index, { ...question, range: [min, question.range[1]] });
      setOpenDP(true);
    };
    const handleMaxRange = (max) => {
      updateQuestion(index, { ...question, range: [question.range[0], max] });
    };
    const handleUpdateOption = (updatedOption, optionIndex) => {
      if (updatedOption.length > 0) {
        const updatedOptions = [...question.options];
        updatedOptions[optionIndex] = updatedOption;
        handleOptions(updatedOptions);
      }
      setOpenDP(true);
    };
    const handleRequired = (isRequired) => {
      updateQuestion(index, { ...question, required: isRequired });
      setOpenDP(true);
    };
    const handleDelete = () => {
      removeQuestion(index);
      setOpen(false);
      setOpenDP(true);
    };
    useEffect(() => {
      handleType(selectedValue);
    }, [selectedValue]);

    const { t } = useTranslation();
    return (
      <div ref={ref} style={{ marginBottom: '1rem' }}>
        <Box
          width="100%"
          data-cy={`question-box-${index}`}
          onClick={() => setFocusedQuestionIndex(index)}
        >
          <Box width="100%" className={classes.CardBox}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={12} md={8} lg={9} xl={9}> */}
              {question.type != QuestionTypeEnum.Orderedlist &&
                question.type != QuestionTypeEnum.List && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        className={classes.question}
                        mb="9px"
                      >
                        <Input
                          variant="filled"
                          type="text"
                          // value={question.question}
                          onChange={(e: any) => {
                            handleQuestion(e?.target?.value);
                            changeQuestion(e?.target?.value);
                          }}
                          placeholder={question.question}
                          defaultValue={question.question}
                          data-cy={`question-${index}`}
                          style={{ height: '40px', marginBottom: '9px' }}
                          inputProps={{
                            style: {
                              height: '40px',
                              padding: '0 14px',
                              fontSize: '16px',
                            },
                          }}
                          onFocus={(e) => e.target.select()}
                        />
                        <Input
                          variant="filled"
                          type="text"
                          onChange={(e: any) => {
                            handleDescription(e?.target?.value);
                          }}
                          placeholder={'Description'}
                          defaultValue={question.description}
                          style={{ height: '40px' }}
                          inputProps={{
                            style: {
                              height: '40px',
                              padding: '0 14px',
                              fontSize: '16px',
                              fontStyle: 'italic',
                            },
                          }}
                          onFocus={(e) => e.target.select()}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <IconSelect
                        setOpenDP={setOpenDP}
                        handleClose={handleClose}
                        ref={ref}
                        handleClick={handleClick}
                        values={isNotes ? NotesTypes : Types}
                        selectedValue={question?.type}
                        setSelectedValue={setSelectedValue}
                        data-cy="emoji-select"
                      />
                    </Grid>
                  </Grid>
                )}
              <Grid container item>
                <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="flex-start"
                  >
                    {(question.type === QuestionTypeEnum.MultipleChoice ||
                      question.type === QuestionTypeEnum.Dropdown ||
                      question.type === QuestionTypeEnum.SingleChoice ||
                      question.type === QuestionTypeEnum.LinearScale ||
                      question.type === QuestionTypeEnum.List ||
                      question.type === QuestionTypeEnum.Orderedlist) && (
                      <Options
                        setOpenDP={setOpenDP}
                        range={question.range}
                        type={question.type}
                        options={question.options}
                        handleOptions={handleUpdateOption}
                        handleAddOption={handleAddOption}
                        handleRemoveOption={handleRemoveOption}
                        handleMinRange={handleMinRange}
                        handleMaxRange={handleMaxRange}
                      />
                    )}
                    {question.type === QuestionTypeEnum.ShortAnswer && (
                      <Input
                        variant="filled"
                        type="text"
                        style={{
                          height: '40px',
                          maxWidth: '81.5%',
                          marginBottom: '9px',
                        }}
                        className={classes.placeholder}
                        placeholder={'Short answer'}
                        inputProps={{
                          style: {
                            height: '40px',
                            padding: '0 14px',
                            fontSize: '14px',
                          },
                        }}
                        disabled
                      />
                    )}
                    {question.type === QuestionTypeEnum.LongAnswer && (
                      <Input
                        variant="outlined"
                        type="text"
                        className={classes.questionAnswer}
                        multiline
                        style={{ fontStyle: 'italic' }}
                        rows={4}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        value={t('Long answer')}
                        placeholder={'Long answer'}
                        disabled
                      />
                    )}
                    {question.type === QuestionTypeEnum.TextBox && (
                      <Input
                        variant="filled"
                        type="text"
                        style={{
                          height: '40px',
                          maxWidth: '80%',
                          marginBottom: '9px',
                          fontStyle: 'italic',
                        }}
                        value={t('Long answer text')}
                        inputProps={{
                          style: {
                            height: '40px',
                            padding: '0 14px',
                            fontSize: '14px',
                          },
                        }}
                        disabled
                      />
                    )}
                    {question.type === QuestionTypeEnum.Date && (
                      <Input
                        variant="filled"
                        type="text"
                        value="Date"
                        style={{
                          height: '40px',
                          maxWidth: '40%',
                          marginBottom: '9px',
                          fontStyle: 'italic',
                        }}
                        inputProps={{
                          style: {
                            height: '40px',
                            padding: '0 14px',
                            fontSize: '14px',
                            fontStyle: 'italic',
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="email">
                                <DateIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        disabled
                      />
                    )}
                    {question.type === QuestionTypeEnum.DateRange && (
                      <Box style={{ width: '100%' }}>
                        <Input
                          variant="filled"
                          className={classes.placeholder}
                          type="text"
                          placeholder="Start date"
                          style={{
                            height: '40px',
                            maxWidth: '40%',
                            marginBottom: '9px',
                            marginRight: '0.6rem',
                            fontStyle: 'italic',
                          }}
                          inputProps={{
                            style: {
                              height: '40px',
                              padding: '0 14px',
                              fontSize: '14px',
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="email">
                                  <DateIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled
                        />
                        <Input
                          variant="filled"
                          type="text"
                          placeholder="End date"
                          style={{
                            height: '40px',
                            maxWidth: '40%',
                            marginBottom: '9px',
                            fontStyle: 'italic',
                          }}
                          inputProps={{
                            style: {
                              height: '40px',
                              padding: '0 14px',
                              fontSize: '14px',
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="date">
                                  <DateIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled
                        />
                      </Box>
                    )}
                    {question.type === QuestionTypeEnum.Email && (
                      <Input
                        variant="filled"
                        type="text"
                        value="e.g., name@example.com"
                        style={{
                          maxWidth: '80%',
                          marginBottom: '9px',
                          fontStyle: 'italic',
                          fontSize: '16px',
                          lineHeight: '24px',
                          fontWeight: 400,
                        }}
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            paddingLeft: '0px',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="email"
                                className={classes.icon}
                              >
                                <Email />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        disabled
                      />
                    )}
                    {question.type === QuestionTypeEnum.Phone && (
                      <PhoneInput
                        inputStyle={{
                          height: '40px',
                          maxWidth: '80%',
                          marginBottom: '9px',
                          fontStyle: 'italic',
                        }}
                        inputProps={{
                          style: {
                            height: '40px',
                            fontSize: '14px',
                            width: '80%',
                            fontStyle: 'italic',
                          },
                        }}
                        disabled
                      />
                    )}
                    {question.type === QuestionTypeEnum.Address && <Address />}
                    {question.type === QuestionTypeEnum.Signature && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        style={{
                          backgroundColor: '#F2F4F7',
                          borderRadius: '16px',
                        }}
                        width="80%"
                        py="4rem"
                      >
                        <Signature
                          style={{ marginBottom: '5px', color: '#667085' }}
                        />
                        <Typography className={classes.signText}>
                          Sign here
                        </Typography>
                        <Box mt={1}>
                          <Typography className={classes.signDesc}>
                            By Inserting your signature
                          </Typography>
                          <Typography className={classes.signDesc}>
                            you are e-signing this form
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                {question.type != QuestionTypeEnum.Orderedlist &&
                  question.type != QuestionTypeEnum.List && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        marginTop: '1rem',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        borderTop: '1px solid #D0D5DD',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Switch
                          name="required"
                          data-cy={`required-${index}`}
                          checked={question.required}
                          onChange={(e) => handleRequired(e.target.checked)}
                        />
                        <Typography className={classes.requiredText}>
                          {isNotes ? 'Exclude' : 'Required'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <ModalDelete
              open={open}
              onClose={() => setOpen(false)}
              onDelete={handleDelete}
              text="question"
            />
          </Box>
        </Box>
      </div>
    );
  }
);

export default QuestionCard;
