import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as CloseIcon } from 'assets/patientActivities/close.svg';
import { ReactComponent as CircleIcon } from 'assets/patientActivities/circle.svg';
import { ReactComponent as SquareIcon } from 'assets/patientActivities/square.svg';
import { ReactComponent as Bullet } from 'assets/patientActivities/bullet-icon.svg';
import { ReactComponent as BluePlusIcon } from 'assets/patientActivities/plus-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/patientActivities/plus-icon.svg';
import { ReactComponent as AddOptionText } from 'assets/patientActivities/addOption.svg';
import Input from 'components/ui/Inputs';
import { QuestionTypeEnum } from './Constants';
import { useForm } from 'react-hook-form';

interface IOptionsProps {
  range: number[];
  type: QuestionTypeEnum | string;
  options: string[];
  handleOptions: (value: string, index: number) => void;
  handleAddOption: () => void;
  handleRemoveOption: (index: number) => void;
  handleMinRange: (value: string) => void;
  handleMaxRange: (value: string) => void;
  setOpenDP?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Options({
  range,
  type,
  options,
  handleOptions,
  handleAddOption,
  handleRemoveOption,
  handleMinRange,
  handleMaxRange,
  setOpenDP,
}: IOptionsProps): React.ReactElement {
  const classes = useStyles();
  const [oldOption, setOldOption] = React.useState(options);
  const [editMode, setEditMode] = React.useState(false);
  const { control, register } = useForm();
  const handleEditMode = (value: string, index: number) => {
    setOldOption((e) => {
      const newOptions = [...oldOption];
      newOptions[index] = value;
      return newOptions;
    });
    setEditMode(true);
    setOpenDP(true);
  };
  const removeOption = (index: number) => {
    handleRemoveOption(index);
    setOpenDP(true);
  };
  const handleUpdateOptionValue = (index: number, value: string) => {
    if (value.length > 0) {
      handleOptions(value, index);
    }
    setEditMode(false);
    setOpenDP(true);
  };
  const handleEditOldValue = (value: string, index: number) => {
    setOldOption((e) => {
      const newOptions = [...oldOption];
      newOptions[index] = value;
      return newOptions;
    });
    setOpenDP(true);
  };
  const handleRange = (min, max) => {
    setOpenDP(true);
    return Array.from({ length: max - min + 1 }, (_, i) =>
      (min + i).toString()
    );
  };
  return (
    <Box width="100%" mb="9px">
      {type === QuestionTypeEnum.LinearScale ? (
        <Grid item sm={12} lg={12} md={12} xl={11}>
          <Box
            className={classes.selectWidth}
            display="flex"
            alignItems="center"
          >
            <Input
              type="select"
              name="min"
              control={control}
              inputRef={register}
              options={handleRange(0, 1)}
              onChange={(e) => handleMinRange(e.target.value)}
              value={range[0]}
              variant="filled"
              className={classes.selectInput}
            />
            <Typography style={{ margin: '0 1rem' }}>to</Typography>
            <Input
              type="select"
              name="max"
              control={control}
              inputRef={register}
              options={handleRange(2, 9)}
              onChange={(e) => handleMaxRange(e.target.value)}
              value={range[1]}
              variant="filled"
              className={classes.selectInput}
            />
          </Box>
          {range.map((e, index) => (
            <Grid sm={12} lg={10} md={9} xl={10}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.options}
                mt="9px"
              >
                <Typography
                  style={{
                    // marginRight: '1rem',
                    marginTop: '3px',
                    color: '#4F4F4F',
                    fontSize: '18px',
                    fontWeight: 700,
                  }}
                >
                  {e}.
                </Typography>
                <Input
                  variant="filled"
                  type="text"
                  placeholder={`Option ${index + 1} ...`}
                  value={options[index]}
                  onChange={(e: any) => handleOptions(e?.target?.value, index)}
                  style={{ height: '40px' }}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                    },
                  }}
                  onFocus={(e) => e.target.select()}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          width={
            type === QuestionTypeEnum.List ||
            type === QuestionTypeEnum.Orderedlist
              ? '100%'
              : '70%'
          }
        >
          {options?.map((option, index) => {
            return (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                mt={index > 0 && '9px'}
                boxSizing="border-box"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  boxSizing="border-box"
                  width="100%"
                >
                  <Box
                    maxWidth="35px"
                    minWidth="35px"
                    display="flex"
                    alignItems="center"
                  >
                    {type === QuestionTypeEnum.MultipleChoice && (
                      <SquareIcon
                        style={{
                          marginRight: '1rem',
                        }}
                      />
                    )}
                    {type === QuestionTypeEnum.SingleChoice && (
                      <CircleIcon style={{ marginRight: '1rem' }} />
                    )}
                    {type === QuestionTypeEnum.List && (
                      <Bullet style={{ marginRight: '1rem' }} />
                    )}
                    {type === QuestionTypeEnum.Dropdown && (
                      <Typography
                        style={{
                          color: '#4F4F4F',
                          fontSize: '18px',
                          fontWeight: 700,
                          marginTop: '5px',
                        }}
                      >
                        {index + 1}.
                      </Typography>
                    )}
                    {type === QuestionTypeEnum.Orderedlist && (
                      <Typography
                        style={{
                          color: '#4F4F4F',
                          fontSize: '18px',
                          fontWeight: 700,
                          marginTop: '5px',
                        }}
                      >
                        {index + 1}.
                      </Typography>
                    )}
                  </Box>

                  <Input
                    data-cy={`option-${index}`}
                    variant="filled"
                    type="text"
                    placeholder={`Option ${index + 1} ...`}
                    defaultValue={editMode ? oldOption[index] : option}
                    onClick={() => handleEditMode(option, index)}
                    onChange={(e: any) =>
                      handleEditOldValue(e?.target?.value, index)
                    }
                    onBlur={(e) =>
                      handleUpdateOptionValue(index, e.target.value)
                    }
                    style={{ height: '40px' }}
                    inputProps={{
                      style: {
                        height: '40px',
                        padding: '0 14px',
                        fontSize: '14px',
                      },
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </Box>
                <Box
                  onClick={() => removeOption(index)}
                  className={classes.pointer}
                  data-cy={`remove-option-${index}`}
                >
                  <CloseIcon
                    style={{
                      marginLeft: '1rem',
                      marginRight: '0.6rem',
                    }}
                  />
                </Box>
              </Box>
            );
          })}
          <Box
            display="flex"
            alignItems="center"
            className={classes.question}
            mt="20px"
            // style={{ gap: '0.5rem' }}
          >
            <Box
              style={{ cursor: 'pointer' }}
              maxWidth="35px"
              minWidth="35px"
              display="flex"
              // justifyContent="center"
              alignItems="center"
              onClick={handleAddOption}
            >
              <PlusIcon style={{ stroke: 'rgb(20, 122, 243)' }} />
            </Box>

            <Box
              style={{ cursor: 'pointer' }}
              display="flex"
              // justifyContent="center"
              alignItems="center"
              onClick={handleAddOption}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'left',
                  color: '#147AF3',
                  textDecoration: 'underline',
                }}
              >
                Insert list item
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Options;
