import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  locationTitle: {
    textTransform: 'capitalize',
    color: ' #1F61DC',
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '2px',
    right: '4px',
    color: 'red',
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
  },
  inputLabel: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '23.44px',
    marginBottom: theme.spacing(2),
    color: '#707090',
  },
  root: {
    height: 'auto',
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : 'white',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    overflowY: 'hidden',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
  },
  scrollbar: {
    maxHeight: '70vh',
    overflowX: 'hidden',
  },
  margin: {
    marginTop: theme.spacing(4),
  },
  draft: {
    height: theme.typography.pxToRem(160),
    maxHeight: theme.typography.pxToRem(160),
    [theme.breakpoints.only('xs')]: {
      height: theme.typography.pxToRem(210),
      maxHeight: theme.typography.pxToRem(210),
    },
  },
  draftNotes: {
    height: theme.typography.pxToRem(210),
    maxHeight: theme.typography.pxToRem(210),
  },
  memberPreview: {
    padding: '10px',
    width: '97%',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14);',
      borderRadius: '10px',
    },
  },
  betweenLeftSpacing: {
    marginLeft: theme.spacing(2),
  },
  secondaryText: {
    color: '#969aa0',
    fontSize: '0.9rem',
    lineHeight: '1.25rem',
    marginLeft: theme.spacing(1),
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  text: {
    flexGrow: 1,
    fontSize: '12px',
    marginLeft: -7,
  },
  image:{
    borderRadius:"50%",
    marginRight: '8px'
  },
  removeMemberIcon: {
    borderRadius: '20px',
    padding: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(196, 196, 196, 0.55);',
    },
  },
  avatar: {
    width: '2.25rem',
    height: '2.25rem',
  },
  btnBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  FormLabel: {
    color: '#707090',
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  iconButton: {
    position: 'absolute',
    top: '3vh',
    right: '2vw',
  },
  closeIcon: {
    fontSize: '44px',
    color: '#425364',
  },
}));

export default useStyles;
