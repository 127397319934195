import { useQuery, gql } from '@apollo/client';

export default function useGetAutoSendTemplates() {
  const { data, loading } = useQuery(AUTO_SEND_TEMPLATES);
  return { data, loading };
}

export const AUTO_SEND_TEMPLATES = gql`
  query doctorAutoSendTemplates {
    doctorAutoSendTemplates {
      _id
      title
      description
      lastOpenedAt
      isDefault
      doctor {
        _id
      }
      formType
      blocks
      isAutoSent
    }
    doctorSearchedTemplates {
      _id
      title
      description
      lastOpenedAt
      isDefault
      questions {
        _id
        question
        type
        options
        description
        isEdited
        isDescription
        required
        range
      }
      doctor {
        _id
      }
      formType
      blocks
      isAutoSent
    }
  }
`;
