import React, { useEffect, useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { CustomTooltip } from './DoctorSteps/CustomTooltip';
import { Dashboard } from './DoctorSteps/Dashboard';
import { Schedule } from './DoctorSteps/Schedule';
import { Tasks } from './DoctorSteps/Tasks';
import { Clients } from './DoctorSteps/Clients';
import { Finance } from './DoctorSteps/Finance';
import { Messages } from './DoctorSteps/Messages';
import { pages, Roles } from './contants';
import { End } from './DoctorSteps/End';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER } from 'pages/AdminPage';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useEdituser from 'graphql/operations/userOperations/useUpdateUser';
import { Home as PatientHome } from './PatientSteps/Home';
import { Appointment as PatientAppointment } from './PatientSteps/Appointment';
import { Activities as PatientActivities } from './PatientSteps/Activities';
import { Finance as PatientFinance } from './PatientSteps/Finance';
import { Messages as PatientInbox } from './PatientSteps/Messages';
import { End as PatientEnd } from './PatientSteps/End';
import { CustomTooltipPatient } from './PatientSteps/CustomTooltip';
import { patientPages } from './contants';
import SkipTour from './SkipTour';

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

const drSteps = [
  {
    target: '#root',
    content: <></>,
    data: {
      next: pages.DASHBOARD,
    },
    placement: 'center',
  },
  {
    target: '#root',
    content: <></>,
    data: {
      next: pages.DASHBOARD,
    },
    placement: 'center',
  },
  {
    target: '#joyride',
    content: <Dashboard />,
    data: {
      next: pages.SCHEDULE,
    },
  },
  {
    target: '#joyride',
    content: <Schedule />,
    data: {
      next: pages.TASKS,
    },
  },
  {
    target: '#joyride',
    content: <Tasks />,
    data: {
      next: pages.INBOX,
    },
  },
  {
    target: '#joyride',
    content: <Messages />,
    data: {
      next: pages.CLIENTS,
    },
  },
  {
    target: '#joyride',
    content: <Clients />,
    data: {
      next: pages.FINANCE,
    },
  },
  {
    target: '#joyride',
    content: <Finance />,
    data: {
      next: pages.DASHBOARD,
    },
  },
  {
    target: '#joyride',
    content: <End />,
    data: {
      next: pages.DASHBOARD,
    },
  },
];

const patientSteps = [
  {
    target: '#root',
    content: <></>,
    data: {
      next: patientPages.HOME,
    },
    placement: 'center',
  },
  {
    target: '#root',
    content: <></>,
    data: {
      next: patientPages.HOME,
    },
    placement: 'center',
  },
  {
    target: '#joyride',
    content: <PatientHome />,
    data: {
      next: patientPages.APPOINTMENT,
    },
  },
  {
    target: '#joyride',
    content: <PatientAppointment />,
    data: {
      next: patientPages.INBOX,
    },
  },
  {
    target: '#joyride',
    content: <PatientInbox />,
    data: {
      next: patientPages.ACTIVITIES,
    },
  },
  {
    target: '#joyride',
    content: <PatientActivities />,
    data: {
      next: patientPages.FINANCE,
    },
  },
  {
    target: '#joyride',
    content: <PatientFinance />,
    data: {
      next: patientPages.HOME,
    },
  },
  {
    target: '#joyride',
    content: <PatientEnd />,
    data: {
      next: patientPages.HOME,
    },
  },
];

export const TourGuide = ({ history }) => {
  const [{ run, stepIndex, steps }, setState] = useState(appState);
  const { user } = useAuth();

  const [init, setInit] = useState(true);
  const { updateUser } = useEdituser();

  const isPatient = user?.role === Roles.PATIENT;

  const MAX_STEPS = isPatient ? 7 : 8;
  const [isSkipOpen, setIsSkipOpen] = useState<boolean>(false);

  useEffect(() => {
    if (init) {
      setState((prevState) => ({
        ...prevState,
        run: user ? user?.onboardingStep < MAX_STEPS : false,
        steps: isPatient ? patientSteps : drSteps,
        stepIndex:
          user?.onboardingStep > 0 && user?.onboardingStep < MAX_STEPS ? 1 : 0,
      }));
    }
  }, [user]);

  const updateOnboarding = async (isOnboarded: boolean, step: number) => {
    await updateUser({
      variables: {
        userId: user._id || user?.user?._id,
        updateUser: { isOnboarded, onboardingStep: step },
      },
    });
  };

  const handleCallback = async (data: CallBackProps) => {
    const {
      action,
      index,
      step: {
        data: { next },
      },
      type,
    } = data;

    if (action === 'close') {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
      setIsSkipOpen(true);
      return;
    }

    if (type === 'tour:end') {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
      await updateOnboarding(true, MAX_STEPS);
      return;
    }

    if (type === 'step:after') {
      setInit(false);
      if (index == 0) {
        setState((prevState) => ({
          ...prevState,
          stepIndex: 2,
        }));
        await updateOnboarding(true, 2);
        return;
      }

      if (index == 1) {
        setState((prevState) => ({
          ...prevState,
          stepIndex:
            user?.onboardingStep == 1
              ? user?.onboardingStep + 1
              : user?.onboardingStep,
        }));
        return;
      }

      if (index <= MAX_STEPS) {
        setState((prevState) => ({ ...prevState, stepIndex: stepIndex + 1 }));
        await updateOnboarding(true, stepIndex + 1);
        history.push(next);
      }

      if (index === MAX_STEPS) {
        setState((prevState) => ({
          ...prevState,
          run: false,
        }));
        await updateOnboarding(true, stepIndex);
      }
    }
  };

  // if (!isPatient && user?.role !== Roles.DOCTOR) return <></>;

  return (
    <>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        tooltipComponent={isPatient ? CustomTooltipPatient : CustomTooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
          },
          overlay: { height: '100%' },
          spotlight: { backgroundColor: 'transparent' },
        }}
        disableOverlay={stepIndex > 1}
        floaterProps={{
          styles: {
            floater: {
              filter:
                stepIndex > 1 &&
                'drop-shadow(0px 32px 64px rgba(7, 45, 125, 0.4))',
            },
          },
        }}
      />
      <SkipTour
        open={isSkipOpen}
        onClose={() => {
          setIsSkipOpen(false);
          setState((prevState) => ({
            ...prevState,
            run: true,
          }));
        }}
        onDelete={async () => {
          setState((prevState) => ({
            ...prevState,
            run: false,
          }));
          await updateOnboarding(true, MAX_STEPS);
          setIsSkipOpen(false);
        }}
      />
    </>
  );
};
