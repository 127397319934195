import { Box, Typography, Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import Switch from 'components/ui/Switch';
import Input from 'components/ui/Inputs';
import { useForm } from 'react-hook-form';
import { useQuery, gql } from '@apollo/client';
import useToggleRminder from '@eggmed/graphql-client/operations/reminderOperations/useToggleReminder';
import Skeleton from 'components/ui/Skeleton';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import useCheckPermission from 'contexts/PermissionContext';

const SettingTab = ({ patientId }: { patientId: string }) => {
  const { t } = useTranslation();
  const { toggleReminder } = useToggleRminder();
  const classes = useStyles();
  const { isEnabled, loading: loadingAccess } = useCheckPermission(
    'Clients',
    'edit',
    patientId
  );
  const [emailToggle, setEmailToggle] = React.useState(false);
  const [textToggle, setTextToggle] = React.useState(false);
  async function handleToggleEmail(e: any) {
    setEmailToggle((t) => !t);
    await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          isEmail: e.target.checked ? true : false,
          emailInt: e.target.checked ? 24 : null,
          emailPeriod: e.target.checked ? t('hour(s)') : null,
        },
      },
    });
  }

  const [canceledEmail, setCaneledEmail] = React.useState(false);
  const [canceledText, setCaneledText] = React.useState(false);
  async function handleToggleCanceledEmail(e) {
    setCaneledEmail((cm) => !cm);
    await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          canceledEmail: e.target.checked ? true : false,
        },
      },
    });
  }
  async function handleToggleCanceledText(e) {
    setCaneledText((cm) => !cm);
    await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          canceledText: e.target.checked ? true : false,
        },
      },
    });
  }
  async function handleToggleText(e) {
    setTextToggle((tx) => !tx);

    await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          isText: e.target.checked ? true : false,
          textInt: e.target.checked ? 24 : null,
          textPeriod: e.target.checked ? t('hour(s)') : null,
        },
      },
    });
  }
  const { data, loading } = useQuery(PATIENT_REMINDER, {
    variables: {
      patient: patientId,
    },
  });
  const { register, control, errors, watch, reset, getValues } = useForm({
    defaultValues: {
      emailInt: data?.patientReminder?.emailInt || 24,
      emailPeriod: data?.patientReminder?.emailPeriod || t('hour(s)'),
      textInt: data?.patientReminder?.textInt || 24,
      textPeriod: data?.patientReminder?.textPeriod || t('hour(s)'),
    },
  });
  const commonProps = { register, control, errors };

  React.useEffect(() => {
    if (data) {
      setEmailToggle(data?.patientReminder?.isEmail);
      setTextToggle(data?.patientReminder?.isText);
      setCaneledEmail(data?.patientReminder?.canceledEmail);
      setCaneledText(data?.patientReminder?.canceledText);
    }
  }, [data]);
  React.useEffect(() => {
    if (data) {
      reset({
        emailInt: data?.patientReminder?.emailInt || 24,
        emailPeriod: data?.patientReminder?.emailPeriod || t('hour(s)'),
        textInt: data?.patientReminder?.textInt || 24,
        textPeriod: data?.patientReminder?.textPeriod || t('hour(s)'),
      });
    }
  }, [data, reset]);

  async function handleChangeEmailInt() {
    const data = getValues();
    return await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          emailInt: Number(data?.emailInt),
        },
      },
    });
  }
  async function handleChangeEmailPeriod() {
    const data = getValues();
    return await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          emailPeriod: data?.emailPeriod,
        },
      },
    });
  }
  async function handleChangeTextPeriod() {
    const data = getValues();
    return await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          textPeriod: data?.textPeriod,
        },
      },
    });
  }
  async function handleChangeTextInt() {
    const data = getValues();
    return await toggleReminder({
      variables: {
        reminderInput: {
          patient: patientId,
          textInt: Number(data?.textInt),
        },
      },
    });
  }
  return (
    <Box>
      <Text i18nKey="sessionReminders" className={classes.reminderText}>
        Session reminders
      </Text>
      <Grid container>
        <Grid item xl={6} lg={6}>
          <Box my={2}>
            <Text
              i18nKey="scheduledSessions"
              className={classes.sectionReminder}
            >
              Scheduled sessions
            </Text>
          </Box>
          <Box mb={1}>
            <Grid item container>
              <Grid
                item
                xl={8}
                lg={8}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Skeleton loading={loading}>
                  <Text i18nKey="email" className={classes.titleReminder}>
                    Email
                  </Text>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Text
                    i18nKey="Send upcoming session reminder via email"
                    className={classes.descReminder}
                  >
                    Send upcoming session reminder via email
                  </Text>
                </Skeleton>
              </Grid>
              <Grid item xl={4} lg={4}>
                <Skeleton loading={loading}>
                  <Switch
                    defaultChecked={emailToggle}
                    checked={emailToggle}
                    onChange={handleToggleEmail}
                    disabled={!isEnabled}
                  />
                </Skeleton>
              </Grid>
            </Grid>
            {emailToggle && (
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: '15px' }}
                  mt={1}
                >
                  <Input
                    inputRef={register}
                    type="number"
                    variant="filled"
                    name="emailInt"
                    defaultValue={24}
                    className={classes.inputOcc}
                    onBlur={handleChangeEmailInt}
                    {...commonProps}
                    disabled={!isEnabled}
                  />
                  <Input
                    inputRef={register}
                    type="select"
                    variant="filled"
                    name="emailPeriod"
                    className={classes.select}
                    options={['Hour(s)', 'Day(s)']}
                    defaultValue="Hour(s)"
                    onBlur={handleChangeEmailPeriod}
                    {...commonProps}
                    disabled={!isEnabled}
                  />
                  <Text
                    i18nKey="Before start time"
                    className={classes.beforetext}
                  >
                    Before start time
                  </Text>
                </Box>
              </Box>
            )}
            <Grid container style={{ marginTop: '20px' }}>
              <Grid
                item
                xl={8}
                lg={8}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Skeleton loading={loading}>
                  <Text i18nKey="textMessage" className={classes.titleReminder}>
                    Text message
                  </Text>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Text
                    i18nKey="Send upcoming session reminder via text"
                    className={classes.descReminder}
                  >
                    Send upcoming session reminder via text
                  </Text>
                </Skeleton>
              </Grid>
              <Grid item xl={4} lg={4}>
                <Skeleton loading={loading}>
                  <Switch
                    defaultChecked={textToggle}
                    checked={textToggle}
                    onChange={handleToggleText}
                    disabled={!isEnabled}
                  />
                </Skeleton>
              </Grid>
            </Grid>
            {textToggle && (
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  mt={1}
                  display="flex"
                  alignItems="center"
                  style={{ gap: '15px' }}
                >
                  <Input
                    inputRef={register}
                    type="number"
                    variant="filled"
                    name="textInt"
                    defaultValue={24}
                    className={classes.inputOcc}
                    onBlur={handleChangeTextInt}
                    {...commonProps}
                    disabled={!isEnabled}
                  />
                  <Input
                    inputRef={register}
                    type="select"
                    variant="filled"
                    name="textPeriod"
                    className={classes.select}
                    options={['Hour(s)', 'Day(s)']}
                    defaultValue="Hour(s)"
                    onBlur={handleChangeTextPeriod}
                    {...commonProps}
                    disabled={!isEnabled}
                  />
                  <Typography className={classes.beforetext}>
                    {t('Before start time')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xl={6} lg={6}>
          <Box my={2}>
            <Text
              i18nKey="canceledSessions"
              className={classes.sectionReminder}
            >
              Canceled sessions
            </Text>
          </Box>
          <Grid item container>
            <Grid
              item
              xl={8}
              lg={8}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Skeleton loading={loading}>
                <Text i18nKey="email" className={classes.titleReminder}>
                  Email
                </Text>
              </Skeleton>
              <Skeleton loading={loading}>
                <Text
                  i18nKey="Send cancelation notification immediately via email"
                  className={classes.descReminder}
                >
                  Send cancelation notification immediately via email
                </Text>
              </Skeleton>
            </Grid>
            <Grid item xl={4} lg={4}>
              <Skeleton loading={loading}>
                <Switch
                  defaultChecked={canceledEmail}
                  checked={canceledEmail}
                  onChange={handleToggleCanceledEmail}
                  disabled={!isEnabled}
                />
              </Skeleton>
            </Grid>
          </Grid>
          <Grid item container style={{ marginTop: 20 }}>
            <Grid
              item
              xl={8}
              lg={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Skeleton loading={loading}>
                <Text i18nKey="sms" className={classes.titleReminder}>
                  Text
                </Text>
              </Skeleton>
              <Skeleton loading={loading}>
                <Text
                  i18nKey="Send cancelation notification immediately via text"
                  className={classes.descReminder}
                >
                  Send cancelation notification immediately via text
                </Text>
              </Skeleton>
            </Grid>
            <Grid item xl={4} lg={4}>
              <Skeleton loading={loading}>
                <Switch
                  defaultChecked={canceledText}
                  checked={canceledText}
                  onChange={handleToggleCanceledText}
                  disabled={!isEnabled}
                />
              </Skeleton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingTab;

const PATIENT_REMINDER = gql`
  query patientReminder($patient: ID) {
    patientReminder(patient: $patient) {
      isEmail
      isText
      emailInt
      emailPeriod
      textInt
      textPeriod
      canceledEmail
      canceledText
    }
  }
`;
