import React, { useState } from 'react';
import { Avatar, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { config } from 'config';
type Assignee = {
  id: number;
  name: string;
  avatar: string;
};
const useStyles = makeStyles((theme) => ({
  assigneeContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },

  assigneeLabel: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  assignees: {
    display: 'flex',
    alignItems: 'center',
  },
  assignee: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginRight: '4px',
  },
  moreOptions: {
    position: 'relative',
    cursor: 'pointer',
  },

  dropdown: {
    position: 'absolute',
    top: '24px',
    right: 0,
    zIndex: 1,
    background: 'white',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '8px 0',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    cursor: 'pointer',
  },
  dropdownItemHover: {
    backgroundColor: '#f0f0f0',
  },
  authorName: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeignt: '18px',
    color: '#374151',
    textAlign: 'center',
    marginLeft: theme.spacing(1),
  },

  fontBold: {
    fontWeight: 'bold',
  },
  more: {
    border: '1px solid #D0D5DD',
    borderRadius: '5px',
    fontSize: '18px',
    padding: '0px 2px 3px 2px',
  },
}));
type AssigneeListProps = {
  visibleAssignees: any[];
  hiddenAssignees: any[];
};

const TagList: React.FC<AssigneeListProps> = ({
  visibleAssignees,
  hiddenAssignees,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.assigneeContainer}>
      <div className={classes.assignees}>
        {visibleAssignees?.map((assignee) => (
          <div key={assignee.id} className={classes.assignee}>
            <Avatar
              style={{ width: '20px', height: '20px' }}
              src={`${config.S3URL}${assignee.picture}`}
            >
              {assignee.firstname[0].toUpperCase()}
            </Avatar>
            <Typography className={classes.authorName}>
              {`${assignee.firstname}\u00A0${assignee.lastname}`}
            </Typography>
          </div>
        ))}
        {visibleAssignees?.length + hiddenAssignees?.length > 2 && (
          <div
            className={classes.moreOptions}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <span className={classes.more}>...</span>
            {showDropdown && (
              <div className={classes.dropdown}>
                {hiddenAssignees?.map((assignee) => (
                  <div key={assignee.id} className={classes.dropdownItem}>
                    <Avatar
                      style={{ width: '20px', height: '20px' }}
                      src={`${assignee.picture}`}
                    >
                      {assignee.firstname[0].toUpperCase()}
                    </Avatar>
                    <Typography className={classes.authorName}>
                      {`${assignee.firstname}\u00A0${assignee.lastname}`}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagList;
