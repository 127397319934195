import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useCallback,
} from 'react';

import EditorJS from '@editorjs/editorjs';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Header from 'editorjs-header-with-alignment';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import Alert from 'editorjs-alert';
import ToggleBlock from 'editorjs-toggle-block';
import NestedList from '@editorjs/nested-list';
import Checklist from '@editorjs/checklist';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import Underline from '@editorjs/underline';
import Strikethrough from '@sotaproject/strikethrough';
import ColorPlugin from 'editorjs-text-color-plugin';
import DragDrop from 'editorjs-drag-drop';
import ImageTool from '@editorjs/image';
import Undo from 'editorjs-undo';
import HorizantaleLine from './nodes/HorizantaleLine';
import MultipleChoice from './nodes/MultiChoice';
import SingleChoice from './nodes/SingleChoice';
import ShortAnswer from './nodes/ShortText';
import LongAnswer from './nodes/LongText';
import DropDown from './nodes/DropDown';
import LineaireScale from './nodes/LineaireScale';
import Date from './nodes/Date';
import Table from '@editorjs/table';
import Email from './nodes/Email';
import Phone from './nodes/Phone';
import Address from './nodes/Address';
import DateRange from './nodes/DateRange';
import Signature from './nodes/Signature';
import Orderedlist from './nodes/OrderedList';
import UnorderedList from './nodes/UnorderedList';
import MultiResponse from './nodes/Responses/MultipleChoice';
import SingleResponse from './nodes/Responses/SingleChoice';
import LineaireResponse from './nodes/Responses/LineaireResponse';
import DropDownResponse from './nodes/Responses/DropDown';
import DateResponse from './nodes/Responses/DateResponse';
import DateRangeResponse from './nodes/Responses/DateRangeResponse';
import ShortResponse from './nodes/Responses/ShortResponse';
import LongResponse from './nodes/Responses/LongResponse';
import EmailResponse from './nodes/Responses/EmailRespomse';
import PhoneResponse from './nodes/Responses/PhoneResponse';
import AdressResponse from './nodes/Responses/Adress';
import SignatureResponse from './nodes/Responses/Signature';
import Image from './nodes/Image';
import UnorderedListResponse from './nodes/Responses/UnorderedListResponse';
import OrderedListResponse from './nodes/Responses/OrderedListResponse';
import TableResponse from './nodes/Responses/TableResponse';
import { reducer } from './ContextReducer';
import {
  SET_BLOCKS,
  TOGGLE_SIGNATURE,
  SET_TITLE,
  EDIT_MODE,
  RESET_BLOCK,
  SELECTED_TEMPLATE,
  SET_TEMPLATE_ID,
  TOGGLE_PREVIEW,
  SET_ACTIVE_STYLE,
  SET_SELECTED_ELEMENT,
  SET_BLOCK_ID,
  SET_BLOCK_NUMBER,
} from './actions';
import { selectionEvent } from './Toolbar/Utils';
import Link from './nodes/Link';

export const EditorContext = createContext<any>(null);

export enum templateEnum {
  noteTaking = 'note-taking',
  assessmentForm = 'assessment-form',
  intakeForm = 'intake-form',
  other = 'other',
}

export default function EditorContextProvider({ children }) {
  const editorRef = useRef<any>();
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);
  const undoRef = useRef(null);
  const [state, dispatch] = React.useReducer(reducer, {
    blocks: null,
    open: false,
    formTitle: 'Untitled template',
    editMode: false,
    selectedTemplate: templateEnum.assessmentForm,
    templateId: null,
    isPreview: false,
    selectedElement: null,
    blockId: null,
    blockNumber: 0,
    activeStyle: {
      bold: false,
      italic: false,
      underline: false,
      fontSize: 16,
    },
  });
  function togglePreview(payload, blocks) {
    dispatch({ type: SET_BLOCKS, payload: blocks });
    dispatch({ type: TOGGLE_PREVIEW, payload });
    if (payload) {
      setEdit(true);
    }
  }

  const setSelectedElement = (payload) => {
    dispatch({ type: SET_SELECTED_ELEMENT, payload });
  };

  const setActiveStyle = (payload) => {
    dispatch({ type: SET_ACTIVE_STYLE, payload });
  };
  function setSelectedTemplate(payload) {
    dispatch({ type: SELECTED_TEMPLATE, payload });
  }
  const handleSelectedTemplate = useCallback((templateValue: string) => {
    setSelectedTemplate(templateValue);
  }, []);
  const handleCloseTemplate = useCallback(() => {
    setSelectedTemplate(null);
  }, []);
  const setBlockId = (payload) => {
    dispatch({ type: SET_BLOCK_ID, payload });
  };
  const setBlockNumber = (payload) => {
    dispatch({ type: SET_BLOCK_NUMBER, payload });
  };
  const addBlock = (blockName: string, level?: number) => {
    const editor = editorRef.current;
    if (editor) {
      let totalBlocks = 0;

      if (state.editMode && state.blockNumber === 0) {
        totalBlocks = 1;
      } else if (state.blockNumber !== 0) {
        totalBlocks = state.blockNumber + 1;
      } else {
        totalBlocks = editor.blocks.getBlocksCount();
      }

      if (!state.editMode || (state.editMode && state.blockNumber === 0)) {
        setBlockNumber(editor.blocks.getBlocksCount());
      }
      editor.blocks.insert(blockName, level ? { level } : {}, {}, totalBlocks);
    }
    setBlockNumber(editor.blocks.getBlocksCount());
    setEdit(false);
  };

  const clearBlock = () => {
    const editor = editorRef.current;
    if (editor) {
      editor.blocks.clear();
    }
  };

  function setBlocks(blocks, payload, id, editable = false) {
    dispatch({ type: EDIT_MODE, payload });
    dispatch({ type: SET_BLOCKS, payload: blocks });
    dispatch({ type: SET_TEMPLATE_ID, payload: id });
    setEdit(editable);
  }
  function toggleSignature(payload) {
    dispatch({ type: TOGGLE_SIGNATURE, payload });
  }
  function handleChangeText(payload) {
    dispatch({ type: SET_TITLE, payload });
  }
  function toggleEditMode(payload) {
    dispatch({ type: EDIT_MODE, payload });
  }
  function resetBlock() {
    dispatch({ type: RESET_BLOCK });
  }
  const [edit, setEdit] = React.useState(false);
  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editorjs',
      placeholder: 'Type here',
      autofocus: true,
      onReady: () => {
        try {
          new DragDrop(editor, '4px solid #96CEFD');
          const editorContainer = document.getElementById('editorjs');
          if (!editorContainer) {
            return;
          }
          if (editorContainer) {
            editorContainer.addEventListener('mouseup', (event) => {
              selectionEvent(
                editorRef,
                event,
                setSelectedElement,
                setStartPosition,
                setEndPosition,
                setActiveStyle,
                setBlockId,
                setBlockNumber
              );
            });
          }

          const undo = new Undo({ editor });
          undoRef.current = undo;
        } catch (error) {
          console.error('Error during editor initialization:', error);
        }
      },
      data: {
        time: 1724934371391,
        version: '2.30.5',
        blocks: JSON.parse(state?.blocks),
      },
      tools: {
        HorizantaleLine: HorizantaleLine,
        Multiple_choice: {
          class: MultipleChoice,
          config: {
            edit,
          },
        },

        Single_choice: {
          class: SingleChoice,
          config: {
            edit,
          },
        },
        Short_answer: {
          class: ShortAnswer,
          config: {
            edit,
          },
        },
        Long_answer: {
          class: LongAnswer,
          config: {
            edit,
          },
        },
        DropDown: {
          class: DropDown,
          config: {
            edit,
          },
        },
        LineaireScale: {
          class: LineaireScale,
          config: {
            edit,
          },
        },
        Email: {
          class: Email,
          config: {
            edit,
          },
        },
        Phone: {
          class: Phone,
          config: {
            edit,
          },
        },
        Address: {
          class: Address,
          config: {
            edit,
          },
        },
        Date: {
          class: Date,
          config: {
            edit,
          },
        },
        DateRange: {
          class: DateRange,
          config: {
            edit,
          },
        },
        Signature: {
          class: Signature,
          config: {
            edit,
          },
        },
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            type: 'text',
          },
        },
        table: {
          class: Table as any,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        list: {
          class: UnorderedList,
          config: {
            edit,
          },
        },
        Orderedlist: {
          class: Orderedlist,
          config: {
            edit,
          },
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            // Add a custom rendering function if necessary
            onRender: (block) => {
              // Retrieve and apply fontSize if it exists
              if (block.data.fontSize) {
                const blockElement = document.querySelector(
                  `.ce-block[data-id="${block.id}"] .ce-paragraph`
                );
                if (blockElement) {
                  (blockElement as HTMLElement).style.fontSize =
                    block.data.fontSize;
                }
              }
            },
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 3,
            defaultAlignment: 'left',
          },
        },
        Image: {
          class: Image,
          config: {
            edit,
          },
        },
        Link: {
          class: Link,
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        underline: Underline,
        strikethrough: Strikethrough,
      },
    });
    editorRef.current = editor;
  };

  const initEditorSession = () => {
    const editor = new EditorJS({
      holder: 'editorSession',
      placeholder: '',
      autofocus: false,
      onReady: () => {
        try {
          new DragDrop(editor, '4px solid #96CEFD');
          const editorContainer = document.getElementById('editorSession');
          if (!editorContainer) {
            return;
          }
          if (editorContainer) {
            editorContainer.addEventListener('mouseup', (event) => {
              selectionEvent(
                editorRef,
                event,
                setSelectedElement,
                setStartPosition,
                setEndPosition,
                setActiveStyle,
                setBlockId,
                setBlockNumber
              );
            });
          }

          const undo = new Undo({ editor });
          undoRef.current = undo;
        } catch (error) {
          console.error('Error during editor initialization:', error);
        }
      },
      data: {
        time: 1724934371391,
        version: '2.30.5',
        blocks: JSON.parse(state?.blocks),
      },
      tools: {
        Multiple_choice: MultiResponse,
        Single_choice: SingleResponse,
        LineaireScale: LineaireResponse,
        DropDown: DropDownResponse,
        Date: DateResponse,
        DateRange: DateRangeResponse,
        Short_answer: ShortResponse,
        Long_answer: LongResponse,
        Email: EmailResponse,
        Phone: PhoneResponse,
        Address: AdressResponse,
        Signature: SignatureResponse,
        HorizantaleLine: HorizantaleLine,
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            type: 'text',
          },
        },
        table: TableResponse,
        list: UnorderedListResponse,
        Orderedlist: OrderedListResponse,
        checklist: Checklist,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            // Add a custom rendering function if necessary
            onRender: (block) => {
              // Retrieve and apply fontSize if it exists
              if (block.data.fontSize) {
                const blockElement = document.querySelector(
                  `.ce-block[data-id="${block.id}"] .ce-paragraph`
                );
                if (blockElement) {
                  (blockElement as HTMLElement).style.fontSize =
                    block.data.fontSize;
                }
              }
            },
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 3,
            defaultAlignment: 'left',
          },
        },
        Image: Image,
        Link: Link,
      },
    });
    editorRef.current = editor;
  };
  return (
    <EditorContext.Provider
      value={{
        initEditor,
        editorRef,
        selectedTemplate: state.selectedTemplate,
        handleSelectedTemplate,
        handleCloseTemplate,
        addBlock,
        setBlocks,
        undoRef,
        setActiveStyle,
        ...state,
        toggleSignature,
        handleChangeText,
        toggleEditMode,
        resetBlock,
        startPosition,
        endPosition,
        initEditorSession,
        clearBlock,
        togglePreview,
        setBlockId,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditorContext() {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditorContext must be used within a editorProvider');
  }
  return context;
}
