import { gql, useMutation } from '@apollo/client';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

const REMOVE_STAFF_MEMBER = gql`
  mutation removeStaff($id: ID) {
    removeStaff(id: $id)
  }
`;

const useDeleteStaffMember = () => {
  const [removeStaff, { loading }] = useMutation(REMOVE_STAFF_MEMBER);
  async function handleRemoveStaff(userId: string) {
    try {
      return await removeStaff({
        variables: { id: userId },
        refetchQueries: [
          { query: TEAM_MEMBERS, variables: { role: '', status: '' } },
        ],
      });
    } catch (e) {
      return e;
    }
  }
  return { handleRemoveStaff, loading };
};

export { REMOVE_STAFF_MEMBER };
export default useDeleteStaffMember;
