import React, { FC, forwardRef, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Chip,
  IconButton,
  Avatar,
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import DeleteMUIIcon from '@material-ui/icons/Delete';
import {
  editorStateFromStringifiedRaw,
  limitTextFromState,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { formatToFullDate } from 'utils';
import { Editor } from 'draft-js';
import DeleteHandler from 'components/DeleteHandler';
import { ReactComponent as FilesAttachedInclinedIcon } from 'assets/tasks/filesAttachedInclined.svg';
import { ReactComponent as DeleteIcon } from 'assets/deletePatient.svg';
import PriorityChip from './components/PriorityChip';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import Menu from 'components/ui/Menu';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { config } from 'config';
import TrashIcon from 'assets/TrashIcon';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
// import CardEditModal from './CardEditModal';
import { ReactComponent as AttatchmentIcon } from 'assets/icons/attachment-01.svg';
export const priorityCards = {
  low: '#7D89B0',
  high: '#F04438',
  medium: '#F79009',
};
const useStyles = makeStyles((theme) => ({
  root: {
    outline: 'none',
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
  },
  assignees: {
    outline: 'none',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(0),
    },
  },

  chipBox: {
    backgroundColor: '#F0F9FF',
    border: '1px solid #B9E6FE',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  chipText: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#026AA2',
  },
  title: {
    color: '#425364',
    fontWeight: 'bold',
    fontSize: '18px',
    maxWidth: '100%',
  },
  card: {
    width: '100%',
    height: '100%',
    borderLeft: ({ priority }: { priority: string }) =>
      `8px solid ${priorityCards[priority]}`,
    backgroundColor: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)',
    '&:hover': {
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)',
    },
    borderRadius: '8px',
  },
  dragging: {
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)',
  },
  cover: {
    height: 200,
  },
  badge: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  descriptionWrapper: {
    height: '100%',
    overflow: 'hidden',
    '& .public-DraftStyleDefault-block': {
      margin: '0px',
      lineHeight: '24px',
    },
  },
  cardSpacing: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(-1),
    },
  },
  avatarCard: {
    position: 'relative',
    left: '-10px',
    width: '25px',
    height: '25px',
    fontSize: '14px',
    [theme.breakpoints.only('lg')]: {
      width: '23px',
      height: '23px',
    },
  },
  priorityChip: {
    height: '28px',
    fontSize: '12px',
  },
  chip: {
    // color: '#AF77E0',
    marginLeft: '5px',
    fontSize: '13px',
    height: '28px',
    fontWeight: 500,
    // backgroundColor: 'rgba(249, 105, 153, 0.2)',
    [theme.breakpoints.only('lg')]: {
      height: '28px',
      fontSize: '12px',
      width: '70%',
    },
  },
  boxAlignement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  attachment: {},
  menu: {
    width: '10rem',
    borderRadius: '8px',
    padding: '0',
  },
}));

const Card: FC<any> = forwardRef(
  (
    {
      cardId,
      onClick,
      handleDelete,
      className,
      dragging,
      index,
      list,
      style,
      data,
      isDashboard,
      ...rest
    },
    ref
  ) => {
    const card = data;
    const [stylehover, setStylehover] = useState({ display: 'none' });
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const isMenuOpen = Boolean(anchor);
    const MenuElementsData = [
      {
        cy: 'delete-task',
        avatar: <TrashIcon width="20px" height="20px" />,
        name: 'Delete task',
        onClick: () => {
          handleClose();
          handleOpenDeleteModal();
        },
      },
    ];
    function handleOpenMenu(e: React.MouseEvent<HTMLButtonElement>) {
      e.stopPropagation();
      setAnchor(e.currentTarget);
    }
    const handleClose = () => {
      setAnchor(null);
    };
    const handleCloseDeleteModal = () => {
      setOpenDeleteModal(false);
    };
    const handleOpenDeleteModal = () => {
      setOpenDeleteModal(true);
    };
    const classes = useStyles({ priority: card?.priority });
    const { doctor } = useAuth();
    return (
      <div
        className={clsx(classes.root, className)}
        index={index}
        ref={ref}
        style={style}
        {...rest}
      >
        <MuiCard
          className={clsx(classes.card, { [classes.dragging]: dragging })}
          raised={dragging}
          variant={dragging ? 'elevation' : 'outlined'}
          style={{ position: 'relative', padding: '10px !important' }}
        >
          <CardContent style={{ padding: '7px ' }} onClick={onClick}>
            <Box display="flex" flexDirection="row">
              <span className={classes.title}>{card?.title}</span>
              {doctor?.isDemo &&
                card?.patients?.some(
                  (el) => el._id === doctor?.demoClientId
                ) && (
                  <Box ml={1} className={classes.chipBox} px={1} mr={3}>
                    <Typography className={classes.chipText}>
                      Demo task
                    </Typography>
                  </Box>
                )}
            </Box>

            {/* <Box onClick={handleOpenMenu} data-cy="delete-handler">
                  <MoreIcon />
                </Box> */}

            <Box mt={1} className={classes.descriptionWrapper}>
              {!tryParseJSON(card?.description!) ? (
                <Typography>
                  {card?.description ? card?.description?.substr(0, 50) : '_'}
                </Typography>
              ) : (
                <Editor
                  onChange={() => {}}
                  editorState={limitTextFromState(
                    editorStateFromStringifiedRaw(card?.description!, false),
                    100
                  )}
                  blockRendererFn={(content) => {
                    return {
                      ...content,
                      text: 'Baha',
                    };
                  }}
                  readOnly
                />
              )}
            </Box>
            <hr style={{ border: '1px solid #F4F2EE', height: '0.1px' }} />
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.assignees}
              style={{ paddingLeft: '16px' }}
            >
              <Box display="flex" alignItems="center">
                {card.assignedTo &&
                  [...card.assignedTo]?.slice(0, 2).map((assigned: any) => {
                    return (
                      <Box className={classes.cardSpacing} key={assigned._id}>
                        <Avatar
                          className={classes.avatarCard}
                          src={
                            assigned?.picture &&
                            !assigned?.picture.startsWith(config.S3URL)
                              ? config.S3URL + assigned?.picture
                              : assigned?.picture
                          }
                          alt={
                            assigned?.firstname[0]?.toUpperCase() +
                            assigned?.lastname[0]?.toUpperCase()
                          }
                        />
                      </Box>
                    );
                  })}
              </Box>
              <Box display="flex" alignItems="center">
                <PriorityChip
                  style={{ marginRight: '20px' }}
                  data-cy="select-priority"
                  className={classes.chip}
                  priority={card.priority}
                />
                <AttatchmentIcon style={{ marginRight: '1.5px' }} />
                <Typography color="textSecondary">
                  {card?.attachments?.length}
                </Typography>
              </Box>
            </Box>
          </CardContent>

          <Menu<any>
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            handleMenuClose={handleClose}
            MenuElements={MenuElementsData}
            className={classes.menu}
          />
          <ModalDelete
            text="task"
            open={openDeleteModal}
            onClose={() => handleCloseDeleteModal()}
            onDelete={() => {
              handleDelete(card._id, handleCloseDeleteModal());
            }}
          />
        </MuiCard>
      </div>
    );
  }
);

Card.defaultProps = {
  dragging: false,
  style: {},
};

export default Card;
